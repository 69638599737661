<template>
  <div>
    <p class="has-text-centered is-size-1">Dispatch Center</p>
    <b-tabs type="is-toggle" expanded>
      <b-tab-item label="Fabrication" icon-pack="fas" icon="fad fa-hard-hat">
        <unit-list />
      </b-tab-item>
      <b-tab-item label="QC" icon-pack="fas" icon="fad fa-clipboard">
        <QCTable />
      </b-tab-item>
      <b-tab-item label="Final Assembly" icon-pack="fas" icon="fas fa-brush">
        <GlazingTable />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import UnitList from './UnitList.vue';
import QCTable from './QCTable.vue';
import GlazingTable from './GlazingTable.vue';
export default {
  components: {
    UnitList,
    QCTable,
    GlazingTable,
  },
  data() {
    return {};
  },
  created() {},
};
</script>

<style lang="scss" scoped></style>
