<template>
  <div class="mt-4">
    <div class="columns has-text-centered">
      <div class="column">
        <div class="buttons ml-4">
          <b-button
            class="button is-success is-medium"
            icon-left="content-save"
            :loading="isLoading"
            :disabled="isLoading"
            @click="saveChanges()"
          >
            Save
          </b-button>
          <b-button
            class="button is-warning is-medium"
            icon-left="undo-variant"
            :loading="isLoading"
            :disabled="isLoading"
            @click="getData()"
          >
            Undo
          </b-button>
          <b-button
            class="button is-info is-medium"
            @click="getForecast()"
            icon-left="file-chart"
          >
            Forecast
          </b-button>
        </div>
      </div>
    </div>

    <div>
      <section class="section">
        <p class="is-size-3 has-text-centered is-uppercase">Unassigned</p>
        <article v-if="unassignedUnits.sources.length">
          <Container
            drag-class="unit-ghost"
            drop-class="unit-ghost-drop"
            :drop-placeholder="dropPlaceholderOptions"
            :get-child-payload="
              (itemIndex) => getUnassignedChildPayload(itemIndex)
            "
            group-name="1"
            orientation="horizontal"
            @drop="onUnassignedDrop($event)"
          >
            <Draggable
              v-for="(item, $index) in unassignedUnits.sources"
              :key="$index"
            >
              <Unit :item="item" />
            </Draggable>
          </Container>
        </article>
        <article class="message is-info" v-else>
          <div class="message-body">
            There's no unassigned items at the moment.
          </div>
        </article>
      </section>
    </div>
    <div class="columns">
      <div
        class="column flex"
        v-for="(supervisor, index) in supervisors"
        :key="index"
      >
        <section class="section p-1">
          <p class="has-text-centered has-text-weight-bold">
            {{ supervisor.name.split('-')[0] || 'UNKNOWN' }}
          </p>
          <p class="">
            Total FSF:
            <strong>{{ calculateRawSqFt(supervisor.sources) }}</strong>
          </p>
          <p class="">
            FSF Left:
            <strong>{{
              calculateFactoredFeet(supervisor.sources).toFixed(2)
            }}</strong>
          </p>
          <p class="">
            # Of Units: <strong> {{ supervisor.sources.length }} </strong>
          </p>
          <p class="has-text-left">Percent Left in Bucket:</p>
          <b-progress
            :value="calculateActualPercentLeft(supervisor.sources)"
            size="is-medium"
            show-value
            :type="
              calculateActualPercentLeft(supervisor.sources) >= 100
                ? 'is-success'
                : calculateActualPercentLeft(supervisor.sources) >= 50
                ? 'is-warning'
                : 'is-danger'
            "
          >
          </b-progress>
        </section>
        <div class="unit-list-container">
          <Container
            :data-index="index"
            drag-class="unit-ghost"
            drop-class="unit-ghost-drop"
            :drop-placeholder="dropPlaceholderOptions"
            :get-child-payload="
              (itemIndex) => getChildPayload(index, itemIndex)
            "
            group-name="1"
            @drop="onDrop(index, $event)"
          >
            <Draggable
              v-for="(item, $index) in supervisor.sources"
              :key="$index"
            >
              <Unit
                :item="item"
                :supervisorIndex="index"
                @secondConfirm="confirmMove"
              />
            </Draggable>
          </Container>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Container, Draggable } from 'vue-smooth-dnd';
import { applyDrag } from '../utils/applyDrag';
import Unit from './Unit';
import { rbUserService } from '../services/RbUserService';
import { sourceService } from '../services/SourceService';

export default {
  name: 'UnitList',
  components: {
    Unit,
    Container,
    Draggable,
  },
  data() {
    return {
      dropPlaceholderOptions: {
        className: 'drop-preview',
        animationDuration: '150',
        showOnTop: false,
      },
      isLoading: false,
      unassignedUnits: {
        sources: [],
      },
      supervisors: [],
    };
  },
  methods: {
    getChildPayload(groupIndex, itemIndex) {
      // console.log('get-child-payload', groupIndex, itemIndex)
      // console.log( this.supervisors[groupIndex].sources[itemIndex])
      return this.supervisors[groupIndex].sources[itemIndex];
    },
    getUnassignedChildPayload(itemIndex) {
      console.log('get-child-payload', itemIndex);
      return this.unassignedUnits.sources[itemIndex];
    },
    getShouldAcceptDrop(index, sourceContainerOptions, payload) {
      //   console.log('should-accept-drop', sourceContainerOptions, payload)
      return this.flags[index].drop;
    },
    getShouldAnimateDrop(index, sourceContainerOptions, payload) {
      //   console.log('should-animate-drop', sourceContainerOptions, payload)
      return this.flags[index].animate;
    },

    // -----------------------------------------------------------------------------------------------------------------
    // events
    onDragStart(...args) {
      console.log('drag-start', ...args);
    },
    onDragEnd(...args) {
      console.log('drag-end', ...args);
    },
    onDragEnter(...args) {
      console.log('drag-enter', ...args);
    },
    onDragLeave(...args) {
      console.log('drag-leave', ...args);
    },
    onDrop(groupIndex, dropResult) {
      let result = applyDrag(this.supervisors[groupIndex].sources, dropResult);
      this.supervisors[groupIndex].sources = result;
      // this.$set(this.supervisors[groupIndex], groupIndex, result)
    },
    onUnassignedDrop(dropResult) {
      let result = applyDrag(this.unassignedUnits.sources, dropResult);
      this.unassignedUnits.sources = result;
      // this.$set(this.supervisors[groupIndex], groupIndex, result
    },

    async getData() {
      this.supervisors = await this.listTigerTeamLeads();
      this.unassignedUnits.sources = await this.getUnassigned();
    },

    async listTigerTeamLeads() {
      try {
        const data = rbUserService.listTigerTeamLeads();
        console.log('listTigerTeamLeads()', { data });
        return data;
      } catch (error) {
        console.error('Failed to load list-tiger-team-leads', error);
      }
    },
    async getUnassigned() {
      try {
        const data = sourceService.getUnassigned();
        console.log('getUnassigned()', { data });
        return data;
      } catch (error) {
        console.error('Failed to load unassigned', error);
      }
    },

    saveChanges() {
      this.isLoading = true;

      rbUserService
        .assignUnitToSupervisor({
          supervisors: this.supervisors,
        })
        .then((data) => {
          // console.log(data);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    calculateTotalPercent(sources) {
      //A helper function to get the production factor for a unit
      const getPValue = function (sqFt, unitCost, profile) {
        if ((unitCost / sqFt / profile).toFixed(2) < 1) {
          return 1;
        } else {
          return (unitCost / sqFt / profile).toFixed(2);
        }
      };
      const returnPFeet = function (array) {
        let total = 0;
        for (let i = 0; i < array.length; i++) {
          let profile = 310;
          total =
            total +
            array[i].sqFt *
              getPValue(array[i].sqFt, array[i].unitCost, profile);
        }
        return total;
      };

      const productionFootage = returnPFeet(sources);
      const total_possible =
        ((productionFootage * 0.36) / 400) * 100 +
        ((productionFootage * 0.06) / 225) * 100;
      return total_possible;
    },
    calculateActualPercentLeft(sources) {
      //A helper function to get the production factor for a unit
      const getPValue = function (sqFt, unitCost, profile) {
        if ((unitCost / sqFt / profile).toFixed(2) < 1) {
          return 1;
        } else {
          return (unitCost / sqFt / profile).toFixed(2);
        }
      };
      const returnPFeet = function (array) {
        // let total = 0
        let fabTotal = 0;
        let hangTotal = 0;
        //Come back to this. All this logic is business logic based on existing production credit percentages. This could change with organizational changes.
        const remainingCreditSwitch = function (item) {
          switch (true) {
            case item < 6:
              return 0.42;
            case item === 6.0:
              return 0.32;
            case item === 7.0:
              return 0.28;
            case item === 8.0:
              return 0.26;
            case item === 9.0:
              return 0.16;
            case item === 10.0:
              return 0.11;
            case item === 11.0:
              return 0.6;
            case item === 12.0:
              return 0.0;
            case item > 12:
              return 0.0;
            default:
              return 0.0;
          }
        };

        for (let i = 0; i < array.length; i++) {
          let profile = 310;

          //hacky fix to not count units on hold
          if (array[i].code) {
            continue;
          }
          // total = total + (array[i].sqFt * getPValue(array[i].sqFt, array[i].unitCost, profile))
          const maximumStep = Math.max.apply(
            Math,
            array[i].scans.map(function (o) {
              return o.step.order;
            })
          );
          if (maximumStep < 11) {
            fabTotal =
              fabTotal +
              array[i].sqFt *
                getPValue(array[i].sqFt, array[i].unitCost, profile) *
                (remainingCreditSwitch(maximumStep) - 0.06);
            hangTotal =
              hangTotal +
              array[i].sqFt *
                getPValue(array[i].sqFt, array[i].unitCost, profile) *
                0.06;
          } else if (maximumStep === 11) {
            fabTotal = fabTotal + 0;
            hangTotal =
              hangTotal +
              array[i].sqFt *
                getPValue(array[i].sqFt, array[i].unitCost, profile) *
                0.06;
          } else {
            fabTotal = fabTotal + 0;
            hangTotal = hangTotal + 0;
          }
        }
        return { fabTotal, hangTotal };
      };

      const productionFootage = returnPFeet(sources);
      let fabFeet = productionFootage.fabTotal;
      let hangFeet = productionFootage.hangTotal;
      let fabPercent = (fabFeet / 400) * 100;
      let hangPercent = (hangFeet / 225) * 100;
      const total = fabPercent + hangPercent;
      return total;
    },
    calculateFactoredFeet(sources) {
      //A helper function to get the production factor for a unit
      const getPValue = function (sqFt, unitCost, profile) {
        if ((unitCost / sqFt / profile).toFixed(2) < 1) {
          return 1;
        } else {
          return (unitCost / sqFt / profile).toFixed(2);
        }
      };

      const returnPFeet = function (array) {
        let fabTotal = 0;
        let hangTotal = 0;

        //Come back to this. All this logic is business logic based on existing production credit percentages. This could change with organizational changes.
        const remainingCreditSwitch = function (item) {
          switch (true) {
            case item < 6:
              return 0.42;
            case item === 6.0:
              return 0.32;
            case item === 7.0:
              return 0.28;
            case item === 8.0:
              return 0.26;
            case item === 9.0:
              return 0.16;
            case item === 10.0:
              return 0.11;
            case item === 11.0:
              return 0.6;
            case item === 12.0:
              return 0.0;
            case item > 12:
              return 0.0;
            default:
              return 0.0;
          }
        };

        for (let i = 0; i < array.length; i++) {
          //Need to grab actual profile per unit
          let profile = 310;

          //hacky fix to not count units on hold
          if (array[i].code) {
            continue;
          }
          // total = total + (array[i].sqFt * getPValue(array[i].sqFt, array[i].unitCost, profile))
          const maximumStep = Math.max.apply(
            Math,
            array[i].scans.map(function (o) {
              return o.step.order;
            })
          );
          if (maximumStep < 11) {
            fabTotal =
              fabTotal +
              array[i].sqFt *
                getPValue(array[i].sqFt, array[i].unitCost, profile) *
                (remainingCreditSwitch(maximumStep) - 0.06);
            hangTotal =
              hangTotal +
              array[i].sqFt *
                getPValue(array[i].sqFt, array[i].unitCost, profile) *
                0.06;
          } else if (maximumStep === 11) {
            fabTotal = fabTotal + 0;
            hangTotal =
              hangTotal +
              array[i].sqFt *
                getPValue(array[i].sqFt, array[i].unitCost, profile) *
                0.06;
          } else {
            fabTotal = fabTotal + 0;
            hangTotal = hangTotal + 0;
          }
        }
        return { fabTotal, hangTotal };
      };

      const productionFootage = returnPFeet(sources);
      let fabFeet = productionFootage.fabTotal;
      let hangFeet = productionFootage.hangTotal;
      const total = fabFeet + hangFeet;
      return total;
    },
    calculateRawSqFt(sources) {
      //A simple reducer function to get the total square footage
      const reducer = function (acc, obj) {
        return acc + obj.sqFt;
      };
      const totalSqFt = sources.reduce(reducer, 0);
      return totalSqFt;
    },
    async confirmMove(barcode, supervisorIndex) {
      //NOTE: This explicitly assigns it to David Macias. Also this route should be changed to use a route parameter instead of
      try {
        rbUserService
          .unassignUnit({
            unitBarcode: barcode,
            supervisorId: 'U0055',
          })
          .then(() => {
            for (
              let index = 0;
              index < this.supervisors[supervisorIndex].sources.length;
              index++
            ) {
              if (
                this.supervisors[supervisorIndex].sources[index].barcode ===
                barcode
              ) {
                // console.log(index);
                this.supervisors[supervisorIndex].sources.splice(index, 1);
                break;
              }
            }
          });
      } catch (error) {
        console.error(error);
      }
    },
    getForecast() {
      rbUserService.getForecast().then((data) => {
        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        let yourDate = new Date();
        const offset = yourDate.getTimezoneOffset();
        yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
        const retdate = yourDate.toISOString().split('T')[0];
        link.setAttribute('download', `Forecast-${retdate}.xlsx`);
        document.body.appendChild(link);
        link.click();
      });
    },
  },
  async beforeMount() {
    await this.getData();
  },
};
</script>

<style scoped>
.smooth-dnd-container {
  height: 100%;
  border: 1px solid #b7b9bb;
  background: #fcfbfb;
  border-radius: 6px;
  padding: 0.5rem 0.5rem 0 0.5rem;
  margin-top: 2rem;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}

.smooth-dnd-container.horizontal {
  display: flex;
  flex-wrap: wrap;
}

.unit-ghost {
  transition: transform 0.18s ease;
  transform: rotateZ(5deg);
}

.unit-ghost-drop {
  transition: transform 0.18s ease-in-out;
  transform: rotateZ(0deg);
}
</style>
