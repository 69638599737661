<template>
  <div class="container">
    <b-table
      :data="users"
      :paginated="true"
      :per-page="20"
      current-page.sync="1"
      striped
    >
      <b-table-column
        field="name"
        label="Name"
        v-slot="props"
        sortable
        searchable
        :bordered="true"
      >
        {{ props.row.name }}
      </b-table-column>
      <b-table-column field="address" label="Address" v-slot="props">
        {{ props.row.address }}
      </b-table-column>
      <b-table-column field="email" label="Email" v-slot="props">
        {{ props.row.email }}
      </b-table-column>
      <b-table-column field="phone_number" label="Phone Number" v-slot="props">
        {{ props.row.phone_number }}
      </b-table-column>
      <b-table-column field="role" label="Role" v-slot="props">
        {{ props.row.role }}
      </b-table-column>
      <b-table-column field="" label="Update" v-slot="props">
        <b-button class="is-primary" @click="openUserUpdateModal(props.row)">
          <span
            class="iconify"
            data-icon="mdi:circle-edit-outline"
            data-inline="false"
          ></span>
        </b-button>
      </b-table-column>
      <b-table-column field="" label="Delete" v-slot="props">
        <b-button class="is-danger" @click="openUserDeleteModal(props.row)">
          <span
            class="iconify"
            data-icon="mdi:delete"
            data-inline="false"
          ></span>
        </b-button>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import axios from 'axios';
import UpdateUserModal from '../components/UpdateUserModal.vue';
import DeleteUserModal from '../components/DeleteUserModal.vue';

export default {
  data() {
    return {
      users: [],
      isLoading: false,
    };
  },
  async beforeCreate() {
    this.isLoading = true;
    const returnData = await axios.get(
      'https://rb-portal-backend.herokuapp.com/user'
    );
    this.users = returnData.data;
    this.isLoading = false;
  },
  methods: {
    openUserUpdateModal(userObj) {
      this.$buefy.modal.open({
        parent: this,
        component: UpdateUserModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          userObj: userObj,
        },
        events: {
          'success-event': () => {
            this.$buefy.notification.open({
              message: 'User updated succesfully',
              type: 'is-success',
              position: 'is-bottom',
              duration: 2000,
            });
          },
          'failure-event': (error) => {
            this.$buefy.notification.open({
              message: error,
              type: 'is-danger',
              position: 'is-bottom',
              duration: 2000,
            });
          },
        },
      });
    },
    openUserDeleteModal(userObj) {
      this.$buefy.modal.open({
        parent: this,
        component: DeleteUserModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          userObj: userObj,
        },
        events: {
          'success-event': () => {
            this.$buefy.notification.open({
              message: 'User deactivated succesfully',
              type: 'is-success',
              position: 'is-bottom',
              duration: 2000,
            });
          },
          'failure-event': (error) => {
            this.$buefy.notification.open({
              message: error,
              type: 'is-danger',
              position: 'is-bottom',
              duration: 2000,
            });
          },
        },
      });
    },
  },
};
</script>

<style></style>
