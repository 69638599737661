<template>
  <div>
    <div class="columns has-text-centered">
      <div class="column">
        <div class="buttons ml-4">
          <b-button
            class="button is-info is-medium"
            @click="getQCForecast()"
            icon-left="file-chart"
          >
            QC Forecast
          </b-button>
        </div>
      </div>
    </div>
    <div v-if="supervisors" class="columns">
      <div class="column">
        <div class="container">
          <section class="section p-1">
            <p class="has-text-centered has-text-weight-bold">
              {{ supervisors.name }}
            </p>
            <p class="">
              Total FSF:
              <strong>{{ calculateRawSqFt(supervisors.sources) }}</strong>
            </p>
            <p class="">
              FSF Left:
              <strong>{{
                calculateFactoredFeet(supervisors.sources).toFixed(2)
              }}</strong>
            </p>
            <p class="">
              # Of Units:
              <strong>
                {{ supervisors.sources ? supervisors.sources.length : 0 }}
              </strong>
            </p>
            <p class="has-text-left">Percent Left in Bucket:</p>
            <b-progress
              :value="calculateActualPercentLeft(supervisors.sources)"
              size="is-medium"
              show-value
              :type="
                calculateActualPercentLeft(supervisors.sources) >= 100
                  ? 'is-success'
                  : calculateActualPercentLeft(supervisors.sources) >= 50
                  ? 'is-warning'
                  : 'is-danger'
              "
            >
            </b-progress>
          </section>
          <!-- {{ supervisors }} -->
          <b-table
            :data="supervisors.sources"
            :bordered="isBordered"
            :striped="isStriped"
            :narrowed="isNarrowed"
            :hoverable="isHoverable"
            :loading="isLoading"
            :focusable="isFocusable"
            :mobile-cards="hasMobileCards"
            :row-class="rowClassFunct"
          >
            <b-table-column
              field="barcode"
              label="Barcode"
              width="40"
              v-slot="props"
            >
              {{ props.row.barcode }}
            </b-table-column>
            <b-table-column field="unit" label="Unit #" v-slot="props">
              {{ props.row.unit }}
            </b-table-column>
            <b-table-column field="location" label="Location" v-slot="props">
              {{ props.row.location }}
            </b-table-column>
            <b-table-column
              field="description"
              label="Description"
              v-slot="props"
            >
              {{ props.row.description }}
            </b-table-column>
            <b-table-column field="type" label="Unit Type" v-slot="props">
              {{ props.row.type }}
            </b-table-column>
            <b-table-column field="swing" label="Swing" v-slot="props">
              {{ props.row.swing }}
            </b-table-column>
            <b-table-column field="swing" label="Swing" v-slot="props">
              <button class="button" @click="confirmMove(props.row.barcode)">
                To FA
              </button>
            </b-table-column>

            <template #empty>
              <div class="has-text-centered">No records</div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { rbUserService } from '../services/RbUserService';

export default {
  data() {
    return {
      isEmpty: false,
      isBordered: false,
      isStriped: false,
      isNarrowed: false,
      isHoverable: false,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
      supervisors: undefined,
    };
  },
  methods: {
    rowClassFunct(obj, index) {
      const maximumStep = Math.max.apply(
        Math,
        obj.scans.map(function (o) {
          return o.step.order;
        })
      );
      if (maximumStep >= 14) {
        return 'is-info';
      }
    },
    dateThAttrs(column) {
      return column.label === 'Date'
        ? {
            title: 'This title is sponsored by "th-attrs" prop',
            class: 'has-text-success',
          }
        : null;
    },
    columnTdAttrs(row, column) {
      if (row.id === 'Total') {
        if (column.label === 'ID') {
          return {
            colspan: 4,
            class: 'has-text-weight-bold',
            style: {
              'text-align': 'left !important',
            },
          };
        } else if (column.label === 'Gender') {
          return {
            class: 'has-text-weight-semibold',
          };
        } else {
          return {
            style: { display: 'none' },
          };
        }
      }
      return null;
    },
    calculateActualPercentLeft(sources) {
      // console.log('calculateActualPercentLeft', typeof sources)
      if (!sources) return 0;
      //A helper function to get the production factor for a unit
      const getPValue = function (sqFt, unitCost, profile) {
        if (unitCost / sqFt / profile < 1) {
          return 1;
        } else {
          return unitCost / sqFt / profile;
        }
      };

      const returnPFeet = function (array) {
        // console.log('returnPFeet: array', typeof array)
        // let total = 0
        let fabTotal = 0;
        //Come back to this. All this logic is business logic based on existing production credit percentages. This could change with organizational changes.
        const remainingCreditSwitch = function (item) {
          switch (true) {
            case item < 12:
              return 0.09;
            case item === 12.0:
              return 0.03;
            case item === 13.0:
              return 0.0;
            case item > 13:
              return 0.0;
            default:
              return 0.0;
          }
        };

        for (let i = 0; i < array.length; i++) {
          let profile = 310;
          //hacky fix to not count units on hold
          if (array[i].code) {
            continue;
          }
          // total = total + (array[i].sqFt * getPValue(array[i].sqFt, array[i].unitCost, profile))
          const maximumStep = Math.max.apply(
            Math,
            array[i].scans.map(function (o) {
              return o.step.order;
            })
          );

          if (maximumStep < 13) {
            fabTotal =
              fabTotal +
              array[i].sqFt *
                getPValue(array[i].sqFt, array[i].unitCost, profile) *
                remainingCreditSwitch(maximumStep);
          } else {
            fabTotal = fabTotal + 0;
          }
        }
        return fabTotal;
      };

      const productionFootage = returnPFeet(sources);
      let fabFeet = productionFootage;
      let total = (fabFeet / 150) * 100;
      return total;
    },

    calculateFactoredFeet(sources) {
      // console.log('calculateFactoredFeet', { sources });
      if (!sources) return 0;

      //A helper function to get the production factor for a unit
      const getPValue = function (sqFt, unitCost, profile) {
        if (unitCost / sqFt / profile < 1) {
          return 1;
        } else {
          return unitCost / sqFt / profile;
        }
      };

      const returnPFeet = function (array) {
        // console.log('resturnPFeet', { array });

        let fabTotal = 0;

        //Come back to this. All this logic is business logic based on existing production credit percentages. This could change with organizational changes.
        const remainingCreditSwitch = function (item) {
          switch (true) {
            case item < 12:
              return 0.09;
            case item === 12.0:
              return 0.03;
            case item === 13.0:
              return 0.0;
            case item > 13:
              return 0.0;
            default:
              return 0.0;
          }
        };

        for (let i = 0; i < array.length; i++) {
          //Need to grab actual profile per unit
          let profile = 310;

          //hacky fix to not count units on hold
          if (array[i].code) {
            continue;
          }

          // total = total + (array[i].sqFt * getPValue(array[i].sqFt, array[i].unitCost, profile))
          const maximumStep = Math.max.apply(
            Math,
            array[i].scans.map(function (o) {
              return o.step.order;
            })
          );
          if (maximumStep < 13) {
            fabTotal =
              fabTotal +
              array[i].sqFt *
                getPValue(array[i].sqFt, array[i].unitCost, profile) *
                remainingCreditSwitch(maximumStep);
          } else {
            fabTotal = fabTotal + 0;
          }
        }
        return fabTotal;
      };

      const productionFootage = returnPFeet(sources);
      let fabFeet = productionFootage;
      const total = fabFeet;
      return total;
    },

    calculateRawSqFt(sources) {
      // console.log('calculateRawSqFt: ', typeof sources);
      //A simple reducer function to get the total square footage
      if (!sources) return 0;

      const reducer = function (acc, obj) {
        return acc + obj.sqFt;
      };

      const totalSqFt = sources.reduce(reducer, 0);
      return totalSqFt;
    },
    async confirmMove(barcode) {
      //NOTE: This explicitly assigns it to David Macias. Also this route should be changed to use a route parameter instead of
      try {
        rbUserService
          .unassignUnit({
            unitBarcode: barcode,
            supervisorId: 'U0041',
          })
          .then(() => {
            for (
              let index = 0;
              index < this.supervisors.sources.length;
              index++
            ) {
              if (this.supervisors.sources[index].barcode === barcode) {
                // console.log(index);
                this.supervisors.sources.splice(index, 1);
                break;
              }
            }
          });
      } catch (error) {
        console.error(error);
      }
    },
    getQCForecast() {
      rbUserService.getQCForecast().then((data) => {
        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        let yourDate = new Date();
        const offset = yourDate.getTimezoneOffset();
        yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
        const retdate = yourDate.toISOString().split('T')[0];
        link.setAttribute('download', `QC-Forecast-${retdate}.xlsx`);
        document.body.appendChild(link);
        link.click();
      });
    },
  },
  created() {
    rbUserService
      .listQC()
      .then((data) => {
        this.supervisors = data[0];
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>

<style>
tr.is-info {
  background: #93999e;
  color: #fff;
}
</style>
