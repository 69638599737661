<template>
  <div class="container">
    <section class="section">
      <b-table
        :data="projects"
        :paginated="true"
        :per-page="20"
        current-page.sync="1"
        striped
        :bordered="true"
      >
        <b-table-column
          field="name"
          label="Project Name"
          v-slot="props"
          sortable
          searchable
        >
          {{ props.row.name }}
        </b-table-column>
        <b-table-column field="dueDate" label="Due Date" v-slot="props">
          <p>
            {{ props.row.key }}
          </p>
        </b-table-column>
        <b-table-column
          field="dueDate"
          label="Department"
          sortable
          v-slot="props"
        >
          <b-field>
            <b-radio-button
              @click.native="updateDept('D00003', props.row.name)"
              v-model="props.row.department.key"
              :native-value="'D00003'"
              type="is-danger is-light is-outlined"
            >
              <!-- <b-icon icon="close"></b-icon> -->
              <span>CUT</span>
            </b-radio-button>

            <b-radio-button
              @click.native="updateDept('D00011', props.row.name)"
              v-model="props.row.department.key"
              :native-value="'D00011'"
              type="is-primary is-light is-outlined"
            >
              CAD
            </b-radio-button>

            <b-radio-button
              @click.native="updateDept(null, props.row.name)"
              v-model="props.row.department.key"
              :native-value="null"
              type="is-success is-light is-outlined"
            >
              <span>OUT</span>
            </b-radio-button>
          </b-field>
        </b-table-column>
      </b-table>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import AddProjectModal from './AddProjectModal.vue';
import UpdateProjectModal from './UpdateProjectModal.vue';

export default {
  data() {
    return {
      projects: [],
      isLoading: false,
      radioButton: null,
    };
  },
  methods: {
    updateDept(param, key) {
      console.log(param + '\n' + key);
      axios.post(
        'https://rb-portal-backend.herokuapp.com/rb2project/assignToDept',
        {
          department: param,
          key: key,
        }
      );
    },
    openAddProjectModal() {
      this.$buefy.modal.open({
        parent: this,
        component: AddProjectModal,
        hasModalCard: true,
        trapFocus: true,
        width: 3000,
        props: {},
        events: {
          'success-event': () => {
            this.$buefy.notification.open({
              message: 'Succesfully added new project',
              type: 'is-success',
              position: 'is-bottom',
              duration: 2000,
            });
          },
          'failure-event': (error) => {
            this.$buefy.notification.open({
              message: error,
              type: 'is-danger',
              position: 'is-bottom',
              duration: 2000,
            });
          },
        },
      });
    },
    openProjectUpdateModal(projectObj) {
      this.$buefy.modal.open({
        parent: this,
        component: UpdateProjectModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          projectObj: projectObj,
        },
        events: {
          'success-event': async () => {
            console.log('sucess');
            this.$buefy.notification.open({
              message: 'Succesfully updated',
              type: 'is-success',
              position: 'is-bottom',
              duration: 2000,
            });
            this.isLoading = true;
            const returnData = await axios.get(
              'https://rb-portal-backend.herokuapp.com/rb2project'
            );
            this.projects = returnData.data;
            this.isLoading = false;
          },
          'failure-event': (error) => {
            this.$buefy.notification.open({
              message: error,
              type: 'is-danger',
              position: 'is-bottom',
              duration: 2000,
            });
          },
        },
      });
    },
    openProjectDeleteModal(projectObj) {
      this.$buefy.modal.open({
        parent: this,
        component: AddProjectModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          projectObj: projectObj,
        },
        events: {},
      });
    },
  },
  async beforeCreate() {
    this.isLoading = true;
    const temp = await axios.get(
      'https://rb-portal-backend.herokuapp.com/rb2project'
    );
    const beforeData = temp.data;

    for (let i = 0; i < beforeData.length; i++) {
      if (beforeData[i].department === null) {
        beforeData[i].department = {
          key: null,
          department: null,
        };
        // beforeData[i].department['key'] = null
        // beforeData[i].department['department'] = null
      }
    }
    this.projects = beforeData;
    this.isLoading = false;
  },
};
</script>

<style></style>
