<template>
  <section class="section">
    <div class="container">
      <p class="title has-text-centered">Work In Progress By Step</p>
      <p class="subtitle has-text-centered has-text-weight-light">
        Please allow five seconds for processing
      </p>
      <div class="columns">
        <div class="column">
          <apexchart
            type="bar"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      temp: {},
      chartOptions: {},
      series: [],
    };
  },
  beforeCreate() {
    axios
      .get('https://rb-portal-backend.herokuapp.com/scanner/wip-report/')
      .then((res) => {
        this.chartOptions = {
          chart: {
            id: 'vuechart-example',
          },
          xaxis: {
            categories: Object.keys(res.data),
          },
          yaxis: {
            show: true,
            title: {
              text: 'Number of Units at Each Step',
              style: {
                color: '#000000',
                fontSize: '14px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                cssClass: 'apexcharts-yaxis-title',
              },
            },
          },
        };

        this.series = [
          {
            name: 'Units',
            data: Object.values(res.data),
          },
        ];
      });
  },
};
</script>

<style></style>
