import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { authService } from '../services/AuthService';
import { NotificationProgrammatic as Notification } from 'buefy';
import { _axios } from '../plugins/axios';
import router from '../router';
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: null,
    token: null,
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      // console.log(userData);
      state.user = userData.user;
      localStorage.setItem('user', userData.token);
      _axios.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${userData.token}`;
      _axios.defaults.headers.common['auth'] = userData.token;
    },
    CLEAR_USER_DATA() {
      localStorage.removeItem('user');
      delete _axios.defaults.headers.common['Authorization'];
      delete _axios.defaults.headers.common['auth'];
      // location.reload()
    },
  },
  actions: {
    register({ commit }, credentials) {
      return _axios
        .post('https://rb-portal-backend.herokuapp.com/user', credentials)
        .then(({ data }) => {
          commit('SET_USER_DATA', data);
        });
    },
    login({ commit }, credentials) {
      authService.login(credentials).then(({ data }) => {
        // console.log(data);
        commit('SET_USER_DATA', data.data);
      });
    },

    rblogin({ commit }, credentials) {
      console.log('RBLOGIN');
      return authService.rblogin(credentials).then(({ data }) => {
        console.log(data);
        commit('SET_USER_DATA', data);
      });
    },
    logout({ commit }) {
      commit('CLEAR_USER_DATA');
    },
  },
  getters: {
    loggedIn(state) {
      return !!state.user;
    },
    userId(state) {
      return state.user;
    },
    isCAD(state) {
      if (state.user.role === 'CAD') {
        return true;
      } else {
        return false;
      }
    },
    isAdmin(state) {
      if (state.user.role === 'ADMIN') {
        return true;
      } else {
        return false;
      }
    },
  },
  plugins: [createPersistedState()],
});

_axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log({ ...error });

    //catch for routes requesting a singular resource where the resources is not found
    if (error.response && error.response.data.name === 'EntityNotFound') {
      router.push({ name: 'NotFound' });
    }

    if (error.response.status === 401) {
      store.dispatch('logout');
      if (router.currentRoute.fullPath !== '/') router.push('/');
      Notification.open({
        duration: 5000,
        message: error.response.data.error || `Invalid credentials`,
        position: 'is-bottom',
        type: 'is-danger',
        hasIcon: true,
      });
    }
    return Promise.reject(error);
  }
);

export default store;
