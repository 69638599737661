<template>
  <div class="sidebar-page">
    <div class="columns">
      <div class="column has-background-white pl-0">
        <div class="columns mt-2 mb-4 is-centered">
          <div class="column is-8 has-text-centered">
            <h1 class="title">{{ project.name }}</h1>
            <p class="is-size-5">Promise Date: {{ formatDate }}</p>
            <p class="is-size-5" v-if="approveSwitch">
              # Of Units (Approved): {{ approvedData.length }}
            </p>
            <p class="is-size-5" v-else>
              # Of Units (All): {{ finalData.length }}
            </p>
          </div>
          <div class="column is-1">
            <b-switch
              size="is-medium"
              type="is-success"
              v-model="approveSwitch"
            >
              {{ approveSwitch ? 'Approved Units' : 'All Units' }}
            </b-switch>
            <b-switch
              size="is-medium"
              type="is-success"
              v-model="showTypeSwing"
            >
              {{ approveSwitch ? 'Show Type/Swing' : 'Hide Type/Swing' }}
            </b-switch>
          </div>
        </div>
        <div class="is-tall container">
          <button
            class="button is-success my-2 is-medium"
            @click="addUnitModal(project.key)"
          >
            Add New Unit
          </button>
          <b-table
            :data="approveSwitch ? approvedData : finalData"
            :paginated="true"
            :per-page="20"
            current-page.sync="1"
            striped
            :mobile-cards="false"
            :bordered="true"
          >
            <b-table-column
              field="barcode"
              label="Barcode"
              v-slot="props"
              sortable
              searchable
            >
              <router-link
                :to="{
                  name: 'UnitView',
                  params: {
                    key: $route.params.key,
                    barcode: props.row.barcode,
                  },
                }"
                class="nav-link"
              >
                <p>{{ props.row.barcode }}</p></router-link
              >
            </b-table-column>
            <b-table-column
              field="description"
              label="Description"
              v-slot="props"
              searchable
            >
              {{ props.row.description }}
            </b-table-column>
            <b-table-column
              field="sqFt"
              label="Square Feet"
              v-slot="props"
              sortable
            >
              {{ props.row.sqFt }}
            </b-table-column>
            <b-table-column
              field="type"
              label="Type"
              v-slot="props"
              :visible="showTypeSwing"
              sortable
            >
              {{ props.row.type }}
            </b-table-column>
            <b-table-column
              field="sqFt"
              label="Swing"
              v-slot="props"
              :visible="showTypeSwing"
              sortable
            >
              {{ props.row.swing }}
            </b-table-column>
            <b-table-column field="lastStep" label="Last Step" v-slot="props">
              <p v-if="props.row.lastStep != null">{{ props.row.lastStep }}</p>
              <p v-else>No Scans Found</p>
            </b-table-column>
            <b-table-column field="Barcode" label="Current Step" v-slot="props">
              <p v-if="props.row.Current != null">{{ props.row.Current }}</p>
              <p v-else>No Scans Found: Prelim</p>
            </b-table-column>
            <b-table-column field="Estimated" label="Estimated" v-slot="props">
              <p v-if="props.row.Estimated != null">
                {{ props.row.Estimated }}
              </p>
              <p v-else>ERROR: NO SCANS</p>
            </b-table-column>
            <b-table-column
              field="Activity_Stop"
              label="Last Action"
              v-slot="props"
              sortable
            >
              <p v-if="props.row.Activity_Stop != null">
                {{ new Date(props.row.Activity_Stop).toDateString() }}
              </p>
              <p v-else>N/A</p>
            </b-table-column>
            <b-table-column
              field="Name"
              label="Last Responsible"
              v-slot="props"
            >
              <p v-if="props.row.Name != null">{{ props.row.Name }}</p>
              <p v-else>N/A</p>
            </b-table-column>
            <b-table-column field="id" label="Edit" v-slot="props">
              <b-button class="is-warning" @click="editUnit(props.row)">
                <span
                  class="iconify"
                  data-icon="mdi:circle-edit-outline"
                  data-inline="false"
                ></span>
              </b-button>
            </b-table-column>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';
import { projectService } from '../services/ProjectService';
import AddUnit from '../components/modals/AddUnit.vue';
import EditUnit from '../components/modals/EditUnit.vue';

export default {
  data() {
    return {
      project: {},
      units: [],
      unitExp: [],
      finalData: [],
      approvedData: [],
      approveSwitch: true,
      showTypeSwing: false,
    };
  },
  computed: {
    formatDate: function () {
      const date = new Date(this.project.dueDate);
      if (!isNaN(date.getTime())) {
        const day = date.getDate().toString();
        const month = (date.getMonth() + 1).toString();
        return (
          (month[1] ? month : '0' + month[0]) +
          '/' +
          (day[1] ? day : '0' + day[0]) +
          '/' +
          date.getFullYear()
        );
      } else {
        return '00/00/0000';
      }
    },
  },
  methods: {
    findCurrentStep(lastStep) {
      const stepArr = [
        'Prelim',
        'Revision',
        'Production',
        'Glass',
        'Cut',
        'Machining',
        'Sash Fab',
        'Sash Grind',
        'Sash Straight',
        'Jamb Fab',
        'Jamb Grind',
        'Jamb Straight',
        'HANG',
        'QC',
        'FINISHING',
        'Receive_at_RB',
        'STR8TOOL',
        'PATINAOILWAX',
        'GLAZE',
        'LOADTOINSTALL',
        'AT_JOBSITE',
        'INSTALL',
        'COMPLETE',
      ];
      if (lastStep === 'COMPLETE') {
        return 'COMPLETE';
      } else {
        const isMatch = (step) => step === lastStep;
        const arrIndex = stepArr.findIndex(isMatch);
        let currentStep;
        if (arrIndex === stepArr.lastIndexOf('COMPLETE')) {
          currentStep = arrIndex;
          return currentStep;
        } else {
          currentStep = stepArr[arrIndex + 1];
          return currentStep;
        }
      }
    },
    getEstimatedComplete(lastStep, lastActivity, type, swing) {
      let retVal;
      switch (true) {
        case type === 'W' && swing === 'F':
          switch (true) {
            case lastStep === null:
              retVal = 'N/A NO SCANS';
              break;
            case lastStep === 'Prelim':
              retVal = 'IN PRELIM';
              break;
            case lastStep === 'Revision':
              retVal = 'IN REV';
              break;
            case lastStep === 'Production':
              retVal = this.addDays(lastActivity, 89).toDateString();
              break;
            case lastStep === 'Glass':
              retVal = this.addDays(lastActivity, 89).toDateString();
              break;
            case lastStep === 'Cut':
              retVal = this.addDays(lastActivity, 82).toDateString();
              break;
            case lastStep === 'Machining':
              retVal = this.addDays(lastActivity, 80).toDateString();
              break;
            case lastStep === 'Sash Fab':
              retVal = this.addDays(lastActivity, 80).toDateString();
              break;
            case lastStep === 'Sash Grind':
              retVal = this.addDays(lastActivity, 78).toDateString();
              break;
            case lastStep === 'Sash Straight':
              retVal = this.addDays(lastActivity, 76).toDateString();
              break;
            case lastStep === 'Jamb Fab':
              retVal = this.addDays(lastActivity, 74).toDateString();
              break;
            case lastStep === 'Jamb Grind':
              retVal = this.addDays(lastActivity, 72).toDateString();
              break;
            case lastStep === 'Jamb Straight':
              retVal = this.addDays(lastActivity, 70).toDateString();
              break;
            case lastStep === 'HANG' || 'hang':
              retVal = this.addDays(lastActivity, 70).toDateString();
              break;
            case lastStep === 'QC':
              retVal = this.addDays(lastActivity, 69).toDateString();
              break;
            case lastStep === 'FINISHING':
              retVal = this.addDays(lastActivity, 66).toDateString();
              break;
            case lastStep === 'Receive_at_RB':
              retVal = this.addDays(lastActivity, 45).toDateString();
              break;
            case lastStep === 'STR8TOOL':
              retVal = this.addDays(lastActivity, 38).toDateString();
              break;
            case lastStep === 'PATINAOILWAX':
              retVal = this.addDays(lastActivity, 24).toDateString();
              break;
            case lastStep === 'GLAZE':
              retVal = this.addDays(lastActivity, 3).toDateString();
              break;
            case lastStep === 'LOADTOINSTALL':
              retVal = 'AT SITE';
              break;
            case lastStep === 'COMPLETE':
              retVal = 'COMPLETE';
          }
        case type === 'W' && swing === 'B':
          switch (true) {
            case lastStep === null:
              retVal = 'N/A NO SCANS';
              break;
            case lastStep === 'Prelim':
              retVal = 'IN PRELIM';
              break;
            case lastStep === 'Revision':
              retVal = 'IN REV';
              break;
            case lastStep === 'Production':
              retVal = this.addDays(lastActivity, 104).toDateString();
              break;
            case lastStep === 'Glass':
              retVal = this.addDays(lastActivity, 104).toDateString();
              break;
            case lastStep === 'Cut':
              retVal = this.addDays(lastActivity, 97).toDateString();
              break;
            case lastStep === 'Machining':
              retVal = this.addDays(lastActivity, 90).toDateString();
              break;
            case lastStep === 'Sash Fab':
              retVal = this.addDays(lastActivity, 87).toDateString();
              break;
            case lastStep === 'Sash Grind':
              retVal = this.addDays(lastActivity, 84).toDateString();
              break;
            case lastStep === 'Sash Straight':
              retVal = this.addDays(lastActivity, 81).toDateString();
              break;
            case lastStep === 'Jamb Fab':
              retVal = this.addDays(lastActivity, 78).toDateString();
              break;
            case lastStep === 'Jamb Grind':
              retVal = this.addDays(lastActivity, 75).toDateString();
              break;
            case lastStep === 'Jamb Straight':
              retVal = this.addDays(lastActivity, 73).toDateString();
              break;
            case lastStep === 'HANG' || 'hang':
              retVal = this.addDays(lastActivity, 70).toDateString();
              break;
            case lastStep === 'QC':
              retVal = this.addDays(lastActivity, 69).toDateString();
              break;
            case lastStep === 'FINISHING':
              retVal = this.addDays(lastActivity, 66).toDateString();
              break;
            case lastStep === 'Receive_at_RB':
              retVal = this.addDays(lastActivity, 45).toDateString();
              break;
            case lastStep === 'STR8TOOL':
              retVal = this.addDays(lastActivity, 38).toDateString();
              break;
            case lastStep === 'PATINAOILWAX':
              retVal = this.addDays(lastActivity, 24).toDateString();
              break;
            case lastStep === 'GLAZE':
              retVal = this.addDays(lastActivity, 3).toDateString();
              break;
            case lastStep === 'LOADTOINSTALL':
              retVal = 'AT SITE';
              break;
            case lastStep === 'COMPLETE':
              retVal = 'COMPLETE';
          }
        case type === 'W' && swing === 'S':
          switch (true) {
            case lastStep === null:
              retVal = 'N/A NO SCANS';
              break;
            case lastStep === 'Prelim':
              retVal = 'IN PRELIM';
              break;
            case lastStep === 'Revision':
              retVal = 'IN REV';
              break;
            case lastStep === 'Production':
              retVal = this.addDays(lastActivity, 101).toDateString();
              break;
            case lastStep === 'Glass':
              retVal = this.addDays(lastActivity, 101).toDateString();
              break;
            case lastStep === 'Cut':
              retVal = this.addDays(lastActivity, 94).toDateString();
              break;
            case lastStep === 'Machining':
              retVal = this.addDays(lastActivity, 91).toDateString();
              break;
            case lastStep === 'Sash Fab':
              retVal = this.addDays(lastActivity, 88).toDateString();
              break;
            case lastStep === 'Sash Grind':
              retVal = this.addDays(lastActivity, 85).toDateString();
              break;
            case lastStep === 'Sash Straight':
              retVal = this.addDays(lastActivity, 82).toDateString();
              break;
            case lastStep === 'Jamb Fab':
              retVal = this.addDays(lastActivity, 79).toDateString();
              break;
            case lastStep === 'Jamb Grind':
              retVal = this.addDays(lastActivity, 76).toDateString();
              break;
            case lastStep === 'Jamb Straight':
              retVal = this.addDays(lastActivity, 73).toDateString();
              break;
            case lastStep === 'HANG' || 'hang':
              retVal = this.addDays(lastActivity, 70).toDateString();
              break;
            case lastStep === 'QC':
              retVal = this.addDays(lastActivity, 69).toDateString();
              break;
            case lastStep === 'FINISHING':
              retVal = this.addDays(lastActivity, 66).toDateString();
              break;
            case lastStep === 'Receive_at_RB':
              retVal = this.addDays(lastActivity, 45).toDateString();
              break;
            case lastStep === 'STR8TOOL':
              retVal = this.addDays(lastActivity, 38).toDateString();
              break;
            case lastStep === 'PATINAOILWAX':
              retVal = this.addDays(lastActivity, 24).toDateString();
              break;
            case lastStep === 'GLAZE':
              retVal = this.addDays(lastActivity, 3).toDateString();
              break;
            case lastStep === 'LOADTOINSTALL':
              retVal = 'AT SITE';
              break;
            case lastStep === 'COMPLETE':
              retVal = 'COMPLETE';
          }
        case type === 'W' && (swing === 'I' || swing === 'O'):
          switch (true) {
            case lastStep === null:
              retVal = 'N/A NO SCANS';
              break;
            case lastStep === 'Prelim':
              retVal = 'IN PRELIM';
              break;
            case lastStep === 'Revision':
              retVal = 'IN REV';
              break;
            case lastStep === 'Production':
              retVal = this.addDays(lastActivity, 98).toDateString();
              break;
            case lastStep === 'Glass':
              retVal = this.addDays(lastActivity, 98).toDateString();
              break;
            case lastStep === 'Cut':
              retVal = this.addDays(lastActivity, 91).toDateString();
              break;
            case lastStep === 'Machining':
              retVal = this.addDays(lastActivity, 84).toDateString();
              break;
            case lastStep === 'Sash Fab':
              retVal = this.addDays(lastActivity, 81).toDateString();
              break;
            case lastStep === 'Sash Grind':
              retVal = this.addDays(lastActivity, 79).toDateString();
              break;
            case lastStep === 'Sash Straight':
              retVal = this.addDays(lastActivity, 77).toDateString();
              break;
            case lastStep === 'Jamb Fab':
              retVal = this.addDays(lastActivity, 74).toDateString();
              break;
            case lastStep === 'Jamb Grind':
              retVal = this.addDays(lastActivity, 72).toDateString();
              break;
            case lastStep === 'Jamb Straight':
              retVal = this.addDays(lastActivity, 70).toDateString();
              break;
            case lastStep === 'HANG' || 'hang':
              retVal = this.addDays(lastActivity, 67).toDateString();
              break;
            case lastStep === 'QC':
              retVal = this.addDays(lastActivity, 69).toDateString();
              break;
            case lastStep === 'FINISHING':
              retVal = this.addDays(lastActivity, 66).toDateString();
              break;
            case lastStep === 'Receive_at_RB':
              retVal = this.addDays(lastActivity, 45).toDateString();
              break;
            case lastStep === 'STR8TOOL':
              retVal = this.addDays(lastActivity, 38).toDateString();
              break;
            case lastStep === 'PATINAOILWAX':
              retVal = this.addDays(lastActivity, 24).toDateString();
              break;
            case lastStep === 'GLAZE':
              retVal = this.addDays(lastActivity, 3).toDateString();
              break;
            case lastStep === 'LOADTOINSTALL':
              retVal = 'AT SITE';
              break;
            case lastStep === 'COMPLETE':
              retVal = 'COMPLETE';
          }
        case type === 'D' && (swing === 'I' || swing === 'O'):
          switch (true) {
            case lastStep === null:
              retVal = 'N/A NO SCANS';
              break;
            case lastStep === null:
              retVal = 'N/A NO SCANS';
              break;
            case lastStep === 'Prelim':
              retVal = 'IN PRELIM';
              break;
            case lastStep === 'Revision':
              retVal = 'IN REV';
              break;
            case lastStep === 'Production':
              retVal = this.addDays(lastActivity, 99).toDateString();
              break;
            case lastStep === 'Glass':
              retVal = this.addDays(lastActivity, 99).toDateString();
              break;
            case lastStep === 'Cut':
              retVal = this.addDays(lastActivity, 92).toDateString();
              break;
            case lastStep === 'Machining':
              retVal = this.addDays(lastActivity, 85).toDateString();
              break;
            case lastStep === 'Sash Fab':
              retVal = this.addDays(lastActivity, 83).toDateString();
              break;
            case lastStep === 'Sash Grind':
              retVal = this.addDays(lastActivity, 81).toDateString();
              break;
            case lastStep === 'Sash Straight':
              retVal = this.addDays(lastActivity, 79).toDateString();
              break;
            case lastStep === 'Jamb Fab':
              retVal = this.addDays(lastActivity, 77).toDateString();
              break;
            case lastStep === 'Jamb Grind':
              retVal = this.addDays(lastActivity, 75).toDateString();
              break;
            case lastStep === 'Jamb Straight':
              retVal = this.addDays(lastActivity, 73).toDateString();
              break;
            case lastStep === 'HANG' || 'hang':
              retVal = this.addDays(lastActivity, 70).toDateString();
              break;
            case lastStep === 'QC':
              retVal = this.addDays(lastActivity, 69).toDateString();
              break;
            case lastStep === 'FINISHING':
              retVal = this.addDays(lastActivity, 66).toDateString();
              break;
            case lastStep === 'Receive_at_RB':
              retVal = this.addDays(lastActivity, 45).toDateString();
              break;
            case lastStep === 'STR8TOOL':
              retVal = this.addDays(lastActivity, 38).toDateString();
              break;
            case lastStep === 'PATINAOILWAX':
              retVal = this.addDays(lastActivity, 24).toDateString();
              break;
            case lastStep === 'GLAZE':
              retVal = this.addDays(lastActivity, 3).toDateString();
              break;
            case lastStep === 'LOADTOINSTALL':
              retVal = 'AT SITE';
              break;
            case lastStep === 'COMPLETE':
              retVal = 'COMPLETE';
          }
        case type === 'D' && swing === 'S':
          switch (true) {
            case lastStep === null:
              retVal = 'N/A NO SCANS';
              break;
            case lastStep === 'Prelim':
              retVal = 'IN PRELIM';
              break;
            case lastStep === 'Revision':
              retVal = 'IN REV';
              break;
            case lastStep === 'Production':
              retVal = this.addDays(lastActivity, 101).toDateString();
              break;
            case lastStep === 'Glass':
              retVal = this.addDays(lastActivity, 101).toDateString();
              break;
            case lastStep === 'Cut':
              retVal = this.addDays(lastActivity, 94).toDateString();
              break;
            case lastStep === 'Machining':
              retVal = this.addDays(lastActivity, 91).toDateString();
              break;
            case lastStep === 'Sash Fab':
              retVal = this.addDays(lastActivity, 88).toDateString();
              break;
            case lastStep === 'Sash Grind':
              retVal = this.addDays(lastActivity, 85).toDateString();
              break;
            case lastStep === 'Sash Straight':
              retVal = this.addDays(lastActivity, 82).toDateString();
              break;
            case lastStep === 'Jamb Fab':
              retVal = this.addDays(lastActivity, 79).toDateString();
              break;
            case lastStep === 'Jamb Grind':
              retVal = this.addDays(lastActivity, 76).toDateString();
              break;
            case lastStep === 'Jamb Straight':
              retVal = this.addDays(lastActivity, 73).toDateString();
              break;
            case lastStep === 'HANG' || 'hang':
              retVal = this.addDays(lastActivity, 70).toDateString();
              break;
            case lastStep === 'QC':
              retVal = this.addDays(lastActivity, 69).toDateString();
              break;
            case lastStep === 'FINISHING':
              retVal = this.addDays(lastActivity, 66).toDateString();
              break;
            case lastStep === 'Receive_at_RB':
              retVal = this.addDays(lastActivity, 45).toDateString();
              break;
            case lastStep === 'STR8TOOL':
              retVal = this.addDays(lastActivity, 38).toDateString();
              break;
            case lastStep === 'PATINAOILWAX':
              retVal = this.addDays(lastActivity, 24).toDateString();
              break;
            case lastStep === 'GLAZE':
              retVal = this.addDays(lastActivity, 3).toDateString();
              break;
            case lastStep === 'LOADTOINSTALL':
              retVal = 'AT SITE';
              break;
            case lastStep === 'COMPLETE':
              retVal = 'COMPLETE';
          }
        case type === 'D' && swing === 'P':
          switch (true) {
            case lastStep === null:
              retVal = 'N/A NO SCANS';
              break;
            case lastStep === 'Prelim':
              retVal = 'IN PRELIM';
              break;
            case lastStep === 'Revision':
              retVal = 'IN REV';
              break;
            case lastStep === 'Production':
              retVal = this.addDays(lastActivity, 118).toDateString();
              break;
            case lastStep === 'Glass':
              retVal = this.addDays(lastActivity, 118).toDateString();
              break;
            case lastStep === 'Cut':
              retVal = this.addDays(lastActivity, 111).toDateString();
              break;
            case lastStep === 'Machining':
              retVal = this.addDays(lastActivity, 104).toDateString();
              break;
            case lastStep === 'Sash Fab':
              retVal = this.addDays(lastActivity, 99).toDateString();
              break;
            case lastStep === 'Sash Grind':
              retVal = this.addDays(lastActivity, 94).toDateString();
              break;
            case lastStep === 'Sash Straight':
              retVal = this.addDays(lastActivity, 89).toDateString();
              break;
            case lastStep === 'Jamb Fab':
              retVal = this.addDays(lastActivity, 84).toDateString();
              break;
            case lastStep === 'Jamb Grind':
              retVal = this.addDays(lastActivity, 79).toDateString();
              break;
            case lastStep === 'Jamb Straight':
              retVal = this.addDays(lastActivity, 75).toDateString();
              break;
            case lastStep === 'HANG' || 'hang':
              retVal = this.addDays(lastActivity, 70).toDateString();
              break;
            case lastStep === 'QC':
              retVal = this.addDays(lastActivity, 69).toDateString();
              break;
            case lastStep === 'FINISHING':
              retVal = this.addDays(lastActivity, 66).toDateString();
              break;
            case lastStep === 'Receive_at_RB':
              retVal = this.addDays(lastActivity, 45).toDateString();
              break;
            case lastStep === 'STR8TOOL':
              retVal = this.addDays(lastActivity, 38).toDateString();
              break;
            case lastStep === 'PATINAOILWAX':
              retVal = this.addDays(lastActivity, 24).toDateString();
              break;
            case lastStep === 'GLAZE':
              retVal = this.addDays(lastActivity, 3).toDateString();
              break;
            case lastStep === 'LOADTOINSTALL':
              retVal = 'AT SITE';
              break;
            case lastStep === 'COMPLETE':
              retVal = 'COMPLETE';
          }
        case type === 'D2' && (swing === 'I' || swing === 'O'):
          switch (true) {
            case lastStep === null:
              retVal = 'N/A NO SCANS';
              break;
            case lastStep === 'Prelim':
              retVal = 'IN PRELIM';
              break;
            case lastStep === 'Revision':
              retVal = 'IN REV';
              break;
            case lastStep === 'Production':
              retVal = this.addDays(lastActivity, 99).toDateString();
              break;
            case lastStep === 'Glass':
              retVal = this.addDays(lastActivity, 99).toDateString();
              break;
            case lastStep === 'Cut':
              retVal = this.addDays(lastActivity, 92).toDateString();
              break;
            case lastStep === 'Machining':
              retVal = this.addDays(lastActivity, 85).toDateString();
              break;
            case lastStep === 'Sash Fab':
              retVal = this.addDays(lastActivity, 83).toDateString();
              break;
            case lastStep === 'Sash Grind':
              retVal = this.addDays(lastActivity, 81).toDateString();
              break;
            case lastStep === 'Sash Straight':
              retVal = this.addDays(lastActivity, 79).toDateString();
              break;
            case lastStep === 'Jamb Fab':
              retVal = this.addDays(lastActivity, 77).toDateString();
              break;
            case lastStep === 'Jamb Grind':
              retVal = this.addDays(lastActivity, 75).toDateString();
              break;
            case lastStep === 'Jamb Straight':
              retVal = this.addDays(lastActivity, 73).toDateString();
              break;
            case lastStep === 'HANG' || 'hang':
              retVal = this.addDays(lastActivity, 70).toDateString();
              break;
            case lastStep === 'QC':
              retVal = this.addDays(lastActivity, 69).toDateString();
              break;
            case lastStep === 'FINISHING':
              retVal = this.addDays(lastActivity, 66).toDateString();
              break;
            case lastStep === 'Receive_at_RB':
              retVal = this.addDays(lastActivity, 45).toDateString();
              break;
            case lastStep === 'STR8TOOL':
              retVal = this.addDays(lastActivity, 38).toDateString();
              break;
            case lastStep === 'PATINAOILWAX':
              retVal = this.addDays(lastActivity, 24).toDateString();
              break;
            case lastStep === 'GLAZE':
              retVal = this.addDays(lastActivity, 3).toDateString();
              break;
            case lastStep === 'LOADTOINSTALL':
              retVal = 'AT SITE';
              break;
            case lastStep === 'COMPLETE':
              retVal = 'COMPLETE';
          }
        case type === 'D2' && swing === 'B':
          switch (true) {
            case lastStep === null:
              retVal = 'N/A NO SCANS';
              break;
            case lastStep === 'Prelim':
              retVal = 'IN PRELIM';
              break;
            case lastStep === 'Revision':
              retVal = 'IN REV';
              break;
            case lastStep === 'Production':
              retVal = this.addDays(lastActivity, 104).toDateString();
              break;
            case lastStep === 'Glass':
              retVal = this.addDays(lastActivity, 104).toDateString();
              break;
            case lastStep === 'Cut':
              retVal = this.addDays(lastActivity, 97).toDateString();
              break;
            case lastStep === 'Machining':
              retVal = this.addDays(lastActivity, 90).toDateString();
              break;
            case lastStep === 'Sash Fab':
              retVal = this.addDays(lastActivity, 87).toDateString();
              break;
            case lastStep === 'Sash Grind':
              retVal = this.addDays(lastActivity, 84).toDateString();
              break;
            case lastStep === 'Sash Straight':
              retVal = this.addDays(lastActivity, 81).toDateString();
              break;
            case lastStep === 'Jamb Fab':
              retVal = this.addDays(lastActivity, 78).toDateString();
              break;
            case lastStep === 'Jamb Grind':
              retVal = this.addDays(lastActivity, 75).toDateString();
              break;
            case lastStep === 'Jamb Straight':
              retVal = this.addDays(lastActivity, 73).toDateString();
              break;
            case lastStep === 'HANG' || 'hang':
              retVal = this.addDays(lastActivity, 70).toDateString();
              break;
            case lastStep === 'QC':
              retVal = this.addDays(lastActivity, 69).toDateString();
              break;
            case lastStep === 'FINISHING':
              retVal = this.addDays(lastActivity, 66).toDateString();
              break;
            case lastStep === 'Receive_at_RB':
              retVal = this.addDays(lastActivity, 45).toDateString();
              break;
            case lastStep === 'STR8TOOL':
              retVal = this.addDays(lastActivity, 38).toDateString();
              break;
            case lastStep === 'PATINAOILWAX':
              retVal = this.addDays(lastActivity, 24).toDateString();
              break;
            case lastStep === 'GLAZE':
              retVal = this.addDays(lastActivity, 3).toDateString();
              break;
            case lastStep === 'LOADTOINSTALL':
              retVal = 'AT SITE';
              break;
            case lastStep === 'COMPLETE':
              retVal = 'COMPLETE';
          }
        case type === 'D2' && swing === 'S':
          switch (true) {
            case lastStep === null:
              retVal = 'N/A NO SCANS';
              break;
            case lastStep === 'Prelim':
              retVal = 'IN PRELIM';
              break;
            case lastStep === 'Revision':
              retVal = 'IN REV';
              break;
            case lastStep === 'Production':
              retVal = this.addDays(lastActivity, 101).toDateString();
              break;
            case lastStep === 'Glass':
              retVal = this.addDays(lastActivity, 101).toDateString();
              break;
            case lastStep === 'Cut':
              retVal = this.addDays(lastActivity, 94).toDateString();
              break;
            case lastStep === 'Machining':
              retVal = this.addDays(lastActivity, 91).toDateString();
              break;
            case lastStep === 'Sash Fab':
              retVal = this.addDays(lastActivity, 88).toDateString();
              break;
            case lastStep === 'Sash Grind':
              retVal = this.addDays(lastActivity, 85).toDateString();
              break;
            case lastStep === 'Sash Straight':
              retVal = this.addDays(lastActivity, 82).toDateString();
              break;
            case lastStep === 'Jamb Fab':
              retVal = this.addDays(lastActivity, 79).toDateString();
              break;
            case lastStep === 'Jamb Grind':
              retVal = this.addDays(lastActivity, 76).toDateString();
              break;
            case lastStep === 'Jamb Straight':
              retVal = this.addDays(lastActivity, 73).toDateString();
              break;
            case lastStep === 'HANG' || 'hang':
              retVal = this.addDays(lastActivity, 70).toDateString();
              break;
            case lastStep === 'QC':
              retVal = this.addDays(lastActivity, 69).toDateString();
              break;
            case lastStep === 'FINISHING':
              retVal = this.addDays(lastActivity, 66).toDateString();
              break;
            case lastStep === 'Receive_at_RB':
              retVal = this.addDays(lastActivity, 45).toDateString();
              break;
            case lastStep === 'STR8TOOL':
              retVal = this.addDays(lastActivity, 38).toDateString();
              break;
            case lastStep === 'PATINAOILWAX':
              retVal = this.addDays(lastActivity, 24).toDateString();
              break;
            case lastStep === 'GLAZE':
              retVal = this.addDays(lastActivity, 3).toDateString();
              break;
            case lastStep === 'LOADTOINSTALL':
              retVal = 'AT SITE';
              break;
            case lastStep === 'COMPLETE':
              retVal = 'COMPLETE';
          }
        case type === 'D2' && swing === 'P':
          switch (true) {
            case lastStep === null:
              retVal = 'N/A NO SCANS';
              break;
            case lastStep === 'Prelim':
              retVal = 'IN PRELIM';
              break;
            case lastStep === 'Revision':
              retVal = 'IN REV';
              break;
            case lastStep === 'Production':
              retVal = this.addDays(lastActivity, 118).toDateString();
              break;
            case lastStep === 'Glass':
              retVal = this.addDays(lastActivity, 118).toDateString();
              break;
            case lastStep === 'Cut':
              retVal = this.addDays(lastActivity, 111).toDateString();
              break;
            case lastStep === 'Machining':
              retVal = this.addDays(lastActivity, 104).toDateString();
              break;
            case lastStep === 'Sash Fab':
              retVal = this.addDays(lastActivity, 99).toDateString();
              break;
            case lastStep === 'Sash Grind':
              retVal = this.addDays(lastActivity, 94).toDateString();
              break;
            case lastStep === 'Sash Straight':
              retVal = this.addDays(lastActivity, 89).toDateString();
              break;
            case lastStep === 'Jamb Fab':
              retVal = this.addDays(lastActivity, 84).toDateString();
              break;
            case lastStep === 'Jamb Grind':
              retVal = this.addDays(lastActivity, 79).toDateString();
              break;
            case lastStep === 'Jamb Straight':
              retVal = this.addDays(lastActivity, 75).toDateString();
              break;
            case lastStep === 'HANG' || 'hang':
              retVal = this.addDays(lastActivity, 70).toDateString();
              break;
            case lastStep === 'QC':
              retVal = this.addDays(lastActivity, 69).toDateString();
              break;
            case lastStep === 'FINISHING':
              retVal = this.addDays(lastActivity, 66).toDateString();
              break;
            case lastStep === 'Receive_at_RB':
              retVal = this.addDays(lastActivity, 45).toDateString();
              break;
            case lastStep === 'STR8TOOL':
              retVal = this.addDays(lastActivity, 38).toDateString();
              break;
            case lastStep === 'PATINAOILWAX':
              retVal = this.addDays(lastActivity, 24).toDateString();
              break;
            case lastStep === 'GLAZE':
              retVal = this.addDays(lastActivity, 3).toDateString();
              break;
            case lastStep === 'LOADTOINSTALL':
              retVal = 'AT SITE';
              break;
            case lastStep === 'COMPLETE':
              retVal = 'COMPLETE';
          }
      }
      return retVal;
    },
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    addUnitModal(projectId) {
      this.$buefy.modal.open({
        parent: this,
        component: AddUnit,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        props: {
          initProjectId: projectId,
        },
        events: {
          success: async () => {
            this.getData();
            this.$buefy.notification.open({
              duration: 5000,
              message: `Unit Added Successfully`,
              position: 'is-bottom',
              type: 'is-success',
              hasIcon: true,
            });
          },
          failure: async (e) => {
            this.$buefy.notification.open({
              duration: 5000,
              message: `${e}`,
              position: 'is-bottom',
              type: 'is-danger',
              hasIcon: true,
            });
          },
        },
      });
    },
    editUnit(row) {
      this.$buefy.modal.open({
        parent: this,
        component: EditUnit,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        props: {
          initUnitObj: row,
        },
        events: {
          success: async () => {
            this.getData();
            this.$buefy.notification.open({
              duration: 5000,
              message: `Unit Updated Successfully`,
              position: 'is-bottom',
              type: 'is-success',
              hasIcon: true,
            });
          },
          failure: async (e) => {
            this.$buefy.notification.open({
              duration: 5000,
              message: `${e}`,
              position: 'is-bottom',
              type: 'is-danger',
              hasIcon: true,
            });
          },
        },
      });
    },
    getData() {
      projectService.getProject(this.$route.params.key).then((data) => {
        this.project = data;
        this.units = data.sources;

        projectService
          .getProjectLatestScans(this.$route.params.key)
          .then((data) => {
            console.log(data);
            this.unitExp = data;
            for (let i = 0; i < this.units.length; i++) {
              for (let j = 0; j < this.unitExp.length; j++) {
                if (this.units[i].barcode === this.unitExp[j].Barcode) {
                  this.units[i]['Activity_Stop'] =
                    this.unitExp[j].Activity_Stop;
                  this.units[i].lastStep = this.unitExp[j].Step;
                  this.units[i].Name = this.unitExp[j].Name;
                  this.units[i].Current = this.findCurrentStep(
                    this.units[i].lastStep
                  );
                  this.units[i].Estimated = this.getEstimatedComplete(
                    this.units[i].lastStep,
                    this.unitExp[j].Activity_Stop,
                    this.units[i].type,
                    this.units[i].swing
                  );
                }
                if (this.unitExp[j].Step === null) {
                  this.units[i].lastStep = null;
                }
              }
            }
            this.finalData = this.units;
            this.approvedData = this.finalData.filter(
              (obj) => obj.approved === 1
            );
          });
      });
    },
  },
  async beforeMount() {
    this.getData();
  },
};
</script>

<style scoped>
.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, 20rem);
  grid-gap: 1rem;
  justify-content: space-between;
  align-content: flex-start;
}

.card-equal-height {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.card-equal-height .card-footer {
  margin-top: auto;
}

.stretched {
  align-items: stretch;
}
</style>
