<template>
  <div>
    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-2 is-offset-10">
            <b-field>
              <b-switch
                v-model="isSwitchedCustom"
                size="is-large"
                type="is-success"
                @input="refreshData()"
              >
              </b-switch>
              <span
                v-if="isSwitchedCustom === false"
                class="has-text-weight-bold"
                >All</span
              >
              <span v-else class="has-text-weight-bold">Active</span>
            </b-field>
          </div>
        </div>

        <p class="has-text-centered is-size-1">Hold Center</p>

        <div class="columns">
          <div class="column is-2">
            <b-field>
              <b-button type="is-success" @click="openAddHoldModal()"
                >Add New Hold</b-button
              >
            </b-field>
          </div>
        </div>
        <div class="columns is-desktop is-vcentered is-centered">
          <div class="column">
            <b-table :data="returnHoldarray()" :paginated="true" :per-page="20">
              <b-table-column
                field="sources"
                label="Barcodes"
                v-slot="props"
                searchable
              >
                <b-tag
                  v-for="(source, index) in props.row.sources"
                  :key="index"
                  type="is-primary"
                  size="is-medium"
                  class="m-1"
                >
                  {{ source.barcode }}
                </b-tag>
              </b-table-column>
              <b-table-column
                field="createdBy"
                label="Created By"
                v-slot="props"
                searchable
              >
                {{ props.row.createdBy }}
              </b-table-column>
              <b-table-column
                field="createdAt"
                label="Created At"
                v-slot="props"
                sortable
              >
                {{ new Date(props.row.createdAt).toLocaleString() }}
              </b-table-column>
              <b-table-column label="Days Since Held" v-slot="props" sortable>
                {{ calcDaysHeld(props.row.createdAt) }}
              </b-table-column>
              <b-table-column
                field="reason"
                label="Reason"
                v-slot="props"
                sortable
              >
                {{ props.row.reason }}
              </b-table-column>
              <b-table-column
                field="Note"
                label="Authorization Comment"
                v-slot="props"
                sortable
              >
                {{ props.row.note }}
              </b-table-column>
              <b-table-column field="key" label="Clear" v-slot="props">
                <b-button
                  v-if="isSwitchedCustom === true"
                  class="card-footer-item m-2"
                  @click="clearHold(props.row.key)"
                  >Clear Hold</b-button
                >
              </b-table-column>
            </b-table>
            <!-- <b-notification @close="onCloseSuccessHandler()" auto-close :duration="10000" type="is-success" :active.sync="isSuccessActive" aria-close-label="Close notification">
                            {{ successMessage }}
                        </b-notification>
                        <b-notification @close="onCloseErrorHandler()" auto-close :duration="10000" type="is-danger" :active.sync="isErrorActive" aria-close-label="Close notification">
                            {{ errorMessage }}
                        </b-notification> -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import AddHoldModal from '../components/AddHoldModal';

export default {
  data() {
    return {
      currentHolds: [],
      allHolds: [],
      isSwitchedCustom: true,
    };
  },
  methods: {
    calcDaysHeld(startDate) {
      const start = new Date(startDate);
      const today = new Date();
      const difference = today.getTime() - start.getTime();
      const dayDiff = (difference / (1000 * 3600 * 24)).toFixed(0);
      return dayDiff;
    },
    clearHold(key) {
      axios
        .patch(`http://localhost:3000/hold/${key}/clear`, {
          clearedBy: this.$store.state.user.id,
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    returnHoldarray() {
      if (this.isSwitchedCustom === false) {
        return this.allHolds;
      } else {
        return this.currentHolds;
      }
    },
    refreshData() {
      axios
        .get('http://localhost:3000/hold/')
        .then((res) => {
          this.allHolds = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get('http://localhost:3000/hold/?cleared=false')
        .then((res) => {
          this.currentHolds = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openAddHoldModal() {
      this.$buefy.modal.open({
        parent: this,
        component: AddHoldModal,
        hasModalCard: true,
        trapFocus: true,
        props: {},
        events: {
          'success-event': () => {
            this.$buefy.notification.open({
              message: 'Hold added succesfully ',
              type: 'is-success',
              position: 'is-bottom',
              duration: 2000,
            });
          },
          'failure-event': (error) => {
            this.$buefy.notification.open({
              message: error,
              type: 'is-danger',
              position: 'is-bottom',
              duration: 2000,
            });
          },
        },
      });
    },
  },
  beforeCreate() {
    axios
      .get('http://localhost:3000/hold/')
      .then((res) => {
        console.log(res.data);
        this.allHolds = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get('http://localhost:3000/hold/?cleared=false')
      .then((res) => {
        this.allHolds = res.data;
        this.currentHolds = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style scoped>
.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, 20rem);
  justify-content: space-evenly;
  align-content: flex-start;
}

.card-equal-height {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.card-equal-height .card-footer {
  margin-top: auto;
}

.stretched {
  align-items: stretch;
}

.block:last-child {
  margin-bottom: 1.5rem;
}
</style>
