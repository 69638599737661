<template>
  <div>
    <section class="section">
      <div class="columns is-centered">
        <div class="column is-6 is-offset-3">
          <h1 class="is-size-1 has-text-centered">{{ unit.barcode }}</h1>
        </div>
        <div class="column is-3">
          <div class="buttons">
            <button class="button">Travel Tag</button>
            <button class="button">BOM</button>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-4 is-offset-2">
            <div class="has-text-centered">
              <p>Unit Details</p>
            </div>
            <br />
            <div class="has-text-left">
              <p>Description: {{ unit.description }}</p>
              <p>Location: {{ unit.location }}</p>
              <!-- <p>Profile: {{ unit.description }}</p> -->
              <!-- <p>Finish: {{ unit.finish.unitType }}</p> -->
              <p>Size: {{ unit.sqFt }} SF</p>
            </div>
          </div>
          <div class="column is-4">
            <div class="has-text-centered">
              <p>CAD Drawing</p>
            </div>
            <br />
            <nav class="level">
              <div class="level-item has-text-centered">
                <figure class="image is-128x128">
                  <img src="https://bulma.io/images/placeholders/128x128.png" />
                </figure>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column">
            <b-table
              :data="this.unit.scans"
              :bordered="true"
              :striped="true"
              :narrowed="false"
              :hoverable="true"
              :loading="isLoading"
              :focusable="false"
              :mobile-cards="false"
            >
              <b-table-column field="key" label="Step" v-slot="props">
                {{ props.row.step.name }}
              </b-table-column>
              <b-table-column
                field="activityStop"
                label="Date Complete"
                centered
                v-slot="props"
                sortable
              >
                <span class="tag is-success">
                  {{ new Date(props.row.activityStop).toLocaleDateString() }}
                </span>
              </b-table-column>
              <b-table-column
                field="supervisor.name"
                label="Supervisor"
                v-slot="props"
              >
                <span>
                  <p>{{ props.row.supervisor.name }}</p>
                </span>
              </b-table-column>
              <b-table-column
                field="step.order"
                label="Order"
                v-slot="props"
                numeric
                sortable
              >
                <span>
                  <p>{{ props.row.step.order }}</p>
                </span>
              </b-table-column>

              <template #empty>
                <div class="has-text-centered">No records</div>
              </template>
            </b-table>
            <!-- <DisplayStepper>
                    </DisplayStepper> -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { sourceService } from '../services/SourceService';
// import DisplayStepper from '../components/DisplayStepper.vue'
export default {
  data() {
    return {
      unit: {},
      isLoading: false,
    };
  },
  components: {
    // DisplayStepper: DisplayStepper
  },

  created() {
    sourceService
      .getSourceUnit(this.$route.params.barcode)
      .then((data) => {
        this.unit = data;
        this.unit.scans = this.unit.scans.filter(
          (e) => e.status === 'Complete'
        );
        this.unit.scans.forEach((scan) => {
          scan.step.order = Number(scan.step.order);
        });

        //sort by order
        this.unit.scans.sort(function (a, b) {
          var textA = a.step.order;
          var textB = b.step.order;
          return textA - textB;
        });
      })
      .catch((e) => {
        console.log(e);
      });
  },
};
</script>

<style></style>
