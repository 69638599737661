<template>
  <div>
    <section class="section relative-wrapper">
      <div class="columns">
        <div class="column is-3 is-offset-9">
          <b-button
            class="absolute-button"
            tag="router-link"
            type="is-primary"
            :to="{ name: 'GlassOrderForm' }"
          >
            <b-icon icon="plus"></b-icon><span>Create</span></b-button
          >
        </div>
      </div>
    </section>
    <h1 class="is-size-2 has-text-centered">Glass Orders</h1>
    <section class="section">
      <b-collapse
        class="card"
        animation="slide"
        :open="isOpen == 1"
        @open="isOpen = 1"
      >
        <template #trigger="props">
          <div class="card-header has-background-darkish" role="button">
            <p class="card-header-title has-text-light">Open Work Orders</p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
            </a>
          </div>
        </template>
        <div class="card-content">
          <div class="content">
            <b-table
              :data="data"
              :bordered="false"
              :striped="true"
              :narrowed="true"
              :loading="isLoading"
            >
              <b-table-column field="id" label="Status" v-slot="props">
                <b-button
                  tag="router-link"
                  :to="{
                    name: 'GlassOrderDetail',
                    params: { id: props.row.id },
                  }"
                  type="is-link"
                >
                  View Detail
                </b-button>
              </b-table-column>
              <b-table-column
                field="sources"
                label="Project/Unit(s)"
                v-slot="props"
                sortable
              >
                <b-field
                  v-for="(source, index) of props.row.sources"
                  :key="index"
                >
                  <b-tag rounded>{{ source.barcode }}</b-tag>
                </b-field>
              </b-table-column>
              <b-table-column field="status" label="Status" v-slot="props">
                {{ props.row.status }}
              </b-table-column>
              <b-table-column field="priority" label="Priority" v-slot="props">
                {{ props.row.priority }}
              </b-table-column>
              <b-table-column field="type" label="Type" v-slot="props">
                {{ props.row.type }}
              </b-table-column>

              <b-table-column
                field="pane_count"
                label="Panes"
                v-slot="props"
                numeric
                sortable
              >
                {{ props.row.pane_count }}
              </b-table-column>
              <b-table-column field="id" label="Date Initiated" v-slot="props">
                {{ props.row.date_initiated }}
              </b-table-column>
              <b-table-column field="id" label="Date to Action" v-slot="props">
                {{ props.row.date_to_action }}
              </b-table-column>

              <b-table-column
                field="date_ordered"
                label="Date Ordered"
                v-slot="props"
              >
                {{ props.row.date_ordered }}
              </b-table-column>
              <b-table-column field="due_date" label="Date Due" v-slot="props">
                {{ props.row.due_date }}
              </b-table-column>
            </b-table>
          </div>
        </div>
      </b-collapse>
      <b-collapse
        class="card"
        animation="slide"
        :open="isOpen == 2"
        @open="
          isOpen = 2;
          getClosed();
        "
      >
        <template #trigger="props">
          <div class="card-header has-background-darkish" role="button">
            <p class="card-header-title has-text-white">Closed Work Orders</p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
            </a>
          </div>
        </template>
        <div class="card-content">
          <div class="content">
            <b-table
              :data="[]"
              :bordered="false"
              :striped="true"
              :narrowed="true"
              :loading="isLoading"
            >
              <b-table-column field="id" label="Status" v-slot="props">
                <b-button
                  tag="router-link"
                  :to="'a' + props.row.id"
                  type="is-link"
                >
                  <b-icon pack="fas" icon="user" size="is-small" class="mr-1">
                  </b-icon>
                  Detail
                </b-button>
              </b-table-column>
              <b-table-column
                field="sources"
                label="Project/Unit(s)"
                v-slot="props"
              >
                <b-field
                  v-for="(source, index) of props.row.sources"
                  :key="index"
                >
                  <b-tag rounded>{{ source.barcode }}</b-tag>
                </b-field>
              </b-table-column>
              <b-table-column field="status" label="Status" v-slot="props">
                {{ props.row.status }}
              </b-table-column>
              <b-table-column field="priority" label="Priority" v-slot="props">
                {{ props.row.priority }}
              </b-table-column>
              <b-table-column field="type" label="Type" v-slot="props">
                {{ props.row.type }}
              </b-table-column>

              <b-table-column
                field="pane_count"
                label="Panes"
                v-slot="props"
                numeric
                sortable
              >
                {{ props.row.pane_count }}
              </b-table-column>
              <b-table-column field="id" label="Date Initiated" v-slot="props">
                {{ props.row.date_initiated }}
              </b-table-column>
              <b-table-column field="id" label="Date to Action" v-slot="props">
                {{ props.row.date_to_action }}
              </b-table-column>

              <b-table-column
                field="date_ordered"
                label="Date Ordered"
                v-slot="props"
              >
                {{ props.row.date_ordered }}
              </b-table-column>
              <b-table-column field="due_date" label="Date Due" v-slot="props">
                {{ props.row.due_date }}
              </b-table-column>

              <template #empty>
                <div class="has-text-centered">No records</div>
              </template>
            </b-table>
          </div>
        </div>
      </b-collapse>
    </section>
  </div>
</template>

<script>
import { glassOrderService } from '../services/GlassOrderService';

export default {
  data() {
    return {
      isOpen: 1,
      data: [],
      closedData: [],
      isLoading: false,
    };
  },
  methods: {
    getClosed() {},
  },
  beforeCreate() {
    glassOrderService.getAllGlassOrders()
      .then((data) => {
        this.data = data;
        console.log(data);
      });
  },
};
</script>

<style>
.relative-wrapper {
  position: relative;
}

.absolute-button {
  position: absolute;
  margin-right: 2.5rem;
  margin-top: 2.5rem;
  top: 0;
  right: 0;
}
</style>
