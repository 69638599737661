import { _axios } from '../plugins/axios';

class SourceService {
  constructor() {
    this.axios = _axios;
  }

  /**
   * Get all source units
   * @returns
   */
  async getSourceUnits() {
    try {
      const response = await this.axios.get('/source');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Get a source unit by barcode
   * @param {*} barcode
   * @returns
   */
  async getSourceUnit(barcode) {
    try {
      const response = await this.axios.get(`/source/${barcode}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Add approvals array to source
   * @param {*} approvals
   * @returns
   */
  async addSourceApprovals(approvals) {
    try {
      const response = await this.axios.post('/source/approvals', approvals);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async getUnassigned() {
    try {
      const response = await this.axios.get('/source/unassigned');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async getSourceTerm(barcode) {
    try {
      const response = await this.axios.get(`/source/?term=${barcode}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async getMatsonReport(barcode) {
    try {
      const response = await this.axios.get(`/source/matson`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async addSourceUnit(payload) {
    console.debug('addSourceUnit()', { payload });
    try {
      const response = await this.axios.post(`/source`, payload);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async editSourceUnit(barcode, payload) {
    console.debug('editSourceUnit()', { barcode, payload });
    try {
      const response = await this.axios.patch(`/source/${barcode}`, payload);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
}

export const sourceService = new SourceService();
