<template>
  <div>
    <footer class="footer has-background-darkish has-text-light">
      <div class="content has-text-centered">
        <div class="columns is-centered">
          <div class="column is-3">
            <section>
              <div class="widgettitle">Contact</div>
              <div class="widgettitle">Riviera Bronze</div>
              <div>
                1895 N. Ventura Ave.<br />
                Ventura, California 93001<br />
                Phone: 805-653-1943<br />
              </div>
            </section>
          </div>
          <div class="column is-3">
            <div class="widgettitle">Connect</div>
            <section id="social-2" class="widget widget_social_networks">
              <div id="social-6053dc654064a" class="align-left">
                <a
                  href="http://www.houzz.com/pro/rivierabronze1/riviera-bronze-mfg"
                  rel="nofollow"
                  target="_blank"
                  ><img
                    src="https://rivierabronze.com/wp-content/uploads/2016/01/Houzz-icon-v2-01-e1454011046827.png"
                    alt="Follow Us on Houzz"
                    title="Follow Us on Houzz" /></a
                ><a
                  href="https://www.facebook.com/people/Riviera-Bronze/100009939198362"
                  rel="nofollow"
                  target="_blank"
                  ><img
                    src="https://rivierabronze.com/wp-content/uploads/2016/01/fb-icon-v2-01-e1454011025754.png"
                    alt="Follow Us on Facebook"
                    title="Follow Us on Facebook" /></a
                ><a
                  href="https://www.youtube.com/user/RivieraBronze"
                  rel="nofollow"
                  target="_blank"
                  ><img
                    src="https://rivierabronze.com/wp-content/uploads/2016/02/yt-icon-01-e1456332158902.png"
                    alt="Follow Us on YouTube"
                    title="Follow Us on YouTube" /></a
                ><a
                  href="https://www.pinterest.com/RivieraBronze1/"
                  rel="nofollow"
                  target="_blank"
                  ><img
                    src="https://rivierabronze.com/wp-content/uploads/2016/04/pinterest-icon.png"
                    alt="Follow Us on Pinterest"
                    title="Follow Us on Pinterest"
                /></a>
              </div>
            </section>
          </div>
        </div>
        <span class="mk-footer-copyright"
          >Riviera Bronze Copyright All Rights Reserved © 2021</span
        >
      </div>
      <div></div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

li {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
  font-weight: 300;
}

#mk-footer,
#mk-footer p {
  font-size: 14px;
  color: #808080;
  font-weight: normal;
}

.widgettitle {
  text-transform: uppercase;
  font-size: 14px;
  color: #ffffff;
  font-weight: 800;
}

#mk-footer {
  background-color: #3d4045;
}

#mk-footer {
  width: 100%;
  position: relative;
  padding: 20px 0 0;
}

#mk-footer,
.flex-direction-nav a {
  z-index: 10;
}

#sub-footer {
  background-color: #43474d;
}

.mk-footer-copyright {
  padding-left: 1rem;
}
</style>
