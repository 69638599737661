<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Edit partner</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <b-field label="Name" horizontal>
          <b-input v-model="partner.name"></b-input>
        </b-field>
        <b-field label="Address" horizontal>
          <b-input v-model="partner.address"></b-input>
        </b-field>
        <b-field label="Phone" horizontal>
          <b-input v-model="partner.phone_number"></b-input>
        </b-field>
        <b-field label="Type" horizontal>
          <b-select
            placeholder="Select a partner type"
            expanded
            required
            v-model="partner.partner_type"
          >
            <option
              v-for="option in partnerTypes"
              :value="option"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </b-select>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button class="is-primary" @click="updatePartner()">Update</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      partner: this.partnerObj,
      partnerTypes: [],
      isSending: false,
    };
  },
  props: ['partnerObj'],
  methods: {
    async updatePartner() {
      console.log(this.partner);
      axios
        .patch(
          'https://rb-portal-backend.herokuapp.com/partner/' + this.partner.id,
          {
            name: this.partner.name,
            address: this.partner.address,
            date_complete: this.partner.date_complete,
            due_date: this.partner.due_date,
            footage: this.partner.footage,
            signed_for_production: this.partner.signed_for_production,
            notes: this.partner.notes,
            partner_type: this.partner.partner_type,
          }
        )
        .then(() => {
          this.$emit('success-event');
          this.$emit('close');
        })
        .catch((e) => {
          this.$emit('failure-event', e);
        });
    },
  },
  beforeMount() {
    axios
      .get('https://rb-portal-backend.herokuapp.com/partnertype/')
      .then((res) => {
        this.partnerTypes = res.data;
      });
  },
};
</script>
