<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add Hold</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <b-field label="Units" horizontal>
          <b-taginput
            v-model="holdUnits"
            :data="filteredUnits"
            autocomplete
            :allow-new="false"
            :open-on-focus="true"
            field="barcode"
            icon="label"
            placeholder="Add a tag"
            @typing="getFilteredUnits"
          >
          </b-taginput>
        </b-field>
        <b-field label="Reason" horizontal>
          <b-input v-model="reason"></b-input>
        </b-field>
        <b-field label="Note" horizontal>
          <b-input type="textarea" v-model="note"></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button class="is-primary" @click="createHold()">Send</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { sourceService } from '../services/SourceService';

export default {
  name: 'AddHoldMOdal',
  data() {
    return {
      holdUnits: [],
      projects: [],
      filteredUnits: [],
      units: [],
      isSending: false,
      reason: null,
      note: null,
    };
  },
  methods: {
    async createHold() {
      axios
        .post('http://localhost:3000/hold/', {
          createdBy: this.$store.state.user.id,
          reason: this.reason,
          note: this.note,
          sources: this.holdUnits,
        })
        .then(() => {
          this.$emit('success-event');
          this.$emit('close');
        })
        .catch((e) => {
          this.$emit('failure-event', e);
        });
    },
    getFilteredUnits(text) {
      this.filteredUnits = this.units.filter((unit) => {
        return (
          unit.barcode.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0
        );
      });
    },
  },
  beforeMount() {
    sourceService.getSources().then((data) => {
      console.log(data[0]);
      this.units = data;
    });
  },
};
</script>
