import { _axios } from '../plugins/axios';
class ProjectService {
  constructor() {
    this.axios = _axios;
  }

  async createProject(payload) {
    try {
      const response = await this.axios.post(`/rb2project`, payload);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async updateProject(projectId, payload) {
    try {
      const response = await this.axios.patch(
        `/rb2project/${projectId}`,
        payload
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async getProject(projectId) {
    try {
      const response = await this.axios.get(`/rb2project/${projectId}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async getProjectLatestScans(projectId) {
    try {
      const response = await this.axios.get(
        `/rb2project/${projectId}/latest-scans`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async getProjectByDepartment(departmentId) {
    try {
      const response = await this.axios.get(
        `/rb2project/projects-by-dept/${departmentId}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async getAllProjects() {
    try {
      const response = await this.axios.get(`/rb2project`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async listDepartments() {
    try {
      const response = await this.axios.get(`/rb2project/departments`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  //   async updateUnitNote(unitId, note) {
  //     try {
  //       const response = await this.axios.patch(`/unit/${unitId}/edit-note`, note);
  //       return response.data;
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
}

export const projectService = new ProjectService();
