<template>
  <div class="mb-3">
    <div
      class="box no-dropshadow has-text-centered py-1"
      style="position: relative"
    >
      <h2 class="is-size-3 mb-3">
        {{ scanData.barcode }}
      </h2>
      <b-steps
        v-model="activeStep"
        :animated="true"
        :rounded="true"
        :has-navigation="false"
        label-position="bottom"
        @input="handleStepItemClick"
        :mobile-mode="null"
      >
        <b-step-item step="0" value="0" label="Before Fab" :clickable="bfClick">
        </b-step-item>
        <b-step-item
          step="1"
          value="1"
          label="Sash Fabrication"
          :clickable="sfClick"
        >
        </b-step-item>
        <b-step-item step="2" value="2" label="Sash Grind" :clickable="sgClick">
        </b-step-item>
        <b-step-item
          step="3"
          value="3"
          label="Sash Straightening"
          :clickable="ssClick"
        >
        </b-step-item>
        <b-step-item
          step="4"
          value="4"
          label="Jamb Fabrication"
          :clickable="jfClick"
        >
        </b-step-item>
        <b-step-item step="5" value="5" label="Jamb Grind" :clickable="jgClick">
        </b-step-item>
        <b-step-item
          step="6"
          value="6"
          label="Jamb Straightening"
          :clickable="jsClick"
        >
        </b-step-item>
        <b-step-item step="7" value="7" label="Hangwall" :clickable="hangClick">
        </b-step-item>
      </b-steps>
      <b-loading
        :is-full-page="false"
        v-model="isLoading"
        :can-cancel="true"
      ></b-loading>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      activeStep: 0,
      initialStep: null,
      isLoading: false,
      scanList: [],
      bfClick: false,
      sfClick: false,
      sgClick: false,
      ssClick: false,
      jfClick: false,
      jgClick: false,
      jsClick: false,
      hangClick: false,
    };
  },
  props: ['scanData', 'supervisor', 'department'],
  methods: {
    messageBuilder() {
      let messageToReturn = `<div class="has-text-center is-size-3">Are you sure you want to submit the following scans?</div><ul>`;
      for (let i = 0; i < this.scanList.length; i++) {
        messageToReturn =
          messageToReturn +
          `<li>${this.scanList[i].barcode} : ${this.scanList[i].step}</li>`;
      }
      messageToReturn += `</ul>`;
      return messageToReturn;
    },
    async handleStepItemClick(ev) {
      if (ev > this.initialStep && ev - this.initialStep === 1) {
        this.isLoading = true;
        this.initialStep = ev;

        this.evaluateClickable(this.activeStep);

        this.scanList.push({
          supervisor: this.supervisor,
          employee: this.supervisor,
          department: this.department,
          barcode: this.scanData.barcode,
          step: this.orderToStep(ev),
          status: 'Complete',
          stepBegin: new Date(),
          activityStart: new Date(),
          activityStop: new Date(),
          stepComplete: new Date(),
        });

        this.$buefy.dialog.confirm({
          title: 'Scan Confirmation',
          message: this.messageBuilder(),
          confirmText: 'Yes',
          type: 'is-success',
          hasIcon: true,
          onConfirm: async () => {
            await axios
              .post(
                'https://rb-portal-backend.herokuapp.com/scanner/multiscan',
                {
                  scanArray: this.scanList,
                }
              )
              .then((res) => {
                this.$buefy.notification.open({
                  message: 'Succesfully scanned',
                  type: 'is-success',
                  position: 'is-bottom',
                  duration: 2000,
                });
                this.initialStep = ev;
                this.evaluateClickable(this.activeStep);
              })
              .catch((e) => {
                this.$buefy.notification.open({
                  message: `${e}`,
                  type: 'is-danger',
                  position: 'is-bottom',
                  duration: 2000,
                });
              });
            this.scanList = [];
            this.isLoading = false;
          },
          onCancel: async () => {
            await this.getInformation();
            this.activeStep = this.initialStep;
            this.evaluateClickable(this.initialStep);
            this.scanList = [];
            this.isLoading = false;
          },
        });
        this.isLoading = false;
      } else if (ev > this.initialStep && ev - this.initialStep > 1) {
        this.isLoading = true;
        const scanArray = [];
        for (let i = this.initialStep + 1; i < ev + 1; i++) {
          this.scanList.push({
            supervisor: this.supervisor,
            employee: this.supervisor,
            department: this.department,
            barcode: this.scanData.barcode,
            step: this.orderToStep(i),
            status: 'Complete',
            stepBegin: new Date(),
            activityStart: new Date(),
            activityStop: new Date(),
            stepComplete: new Date(),
          });
          scanArray.push({
            supervisor: this.supervisor,
            employee: this.supervisor,
            department: this.department,
            barcode: this.scanData.barcode,
            step: this.orderToStep(i),
            status: 'Complete',
            stepBegin: new Date(),
            activityStart: new Date(),
            activityStop: new Date(),
            stepComplete: new Date(),
          });
        }
        this.$buefy.dialog.confirm({
          title: 'Scan Confirmation',
          message: this.messageBuilder(),
          confirmText: 'Yes',
          type: 'is-success',
          hasIcon: true,
          onConfirm: async () => {
            await axios
              .post(
                'https://rb-portal-backend.herokuapp.com/scanner/multiscan',
                {
                  scanArray: this.scanList,
                }
              )
              .then((res) => {
                this.$buefy.notification.open({
                  message: 'Succesfully scanned',
                  type: 'is-success',
                  position: 'is-bottom',
                  duration: 2000,
                });
                this.initialStep = ev;
                this.evaluateClickable(this.activeStep);
              })
              .catch((e) => {
                this.$buefy.notification.open({
                  message: `${e}`,
                  type: 'is-danger',
                  position: 'is-bottom',
                  duration: 2000,
                });
                this.isLoading = false;
              });
            this.isLoading = false;
            this.scanList = [];
          },
          onCancel: async () => {
            await this.getInformation();
            this.isLoading = false;
            this.activeStep = 0;
            this.evaluateClickable(this.initialStep);
            this.isLoading = false;
            this.scanList = [];
          },
        });
      } else if (ev === this.initialStep) {
        console.log("Don't go clicking yourself! How did you even do that");
        this.initialStep = ev;
      } else if (ev < this.initialStep) {
        this.initialStep = ev;
        throw new Error('Invalid Click');
      }
    },
    orderToStep: function (item) {
      switch (true) {
        case Number(item) === 0:
          new Error('Error A');
        case Number(item) === 1:
          return 'Sash Fab';
        case Number(item) === 2:
          return 'Sash Grind';
        case Number(item) === 3:
          return 'Sash Straight';
        case Number(item) === 4:
          return 'Jamb Fab';
        case Number(item) === 5:
          return 'Jamb Grind';
        case Number(item) === 6:
          return 'Jamb Straight';
        case Number(item) === 7:
          return 'Hang';
        case Number(item) > 8:
          return new Error('Error B');
        default:
          return new Error('Error C');
      }
    },
    stepToOrder: function (item) {
      switch (true) {
        case Number(item) < 6:
          return 0;
        case Number(item) === 6.0:
          return 1;
        case Number(item) === 7.0:
          return 2;
        case Number(item) === 8.0:
          return 3;
        case Number(item) === 9.0:
          return 4;
        case Number(item) === 10.0:
          return 5;
        case Number(item) === 11.0:
          return 6;
        case Number(item) === 12.0:
          return 7;
        case Number(item) > 12.0:
          return 100;
        default:
          return 0;
      }
    },
    evaluateClickable(currentStep) {
      if (currentStep >= 0 && currentStep <= 7) {
        this.activeStep = currentStep;
        if (currentStep === 0) {
          this.sfClick = true;
          this.sgClick = true;
          this.ssClick = true;
          this.jfClick = true;
          this.jgClick = true;
          this.jsClick = true;
          this.hangClick = true;
        } else if (currentStep === 1) {
          this.sfClick = false;
          this.sgClick = true;
          this.ssClick = true;
          this.jfClick = true;
          this.jgClick = true;
          this.jsClick = true;
          this.hangClick = true;
        } else if (currentStep === 2) {
          this.sfClick = false;
          this.sgClick = false;
          this.ssClick = true;
          this.jfClick = true;
          this.jgClick = true;
          this.jsClick = true;
          this.hangClick = true;
        } else if (currentStep === 3) {
          this.sfClick = false;
          this.sgClick = false;
          this.ssClick = false;
          this.jfClick = true;
          this.jgClick = true;
          this.jsClick = true;
          this.hangClick = true;
        } else if (currentStep === 4) {
          this.sfClick = false;
          this.sgClick = false;
          this.ssClick = false;
          this.jfClick = false;
          this.jgClick = true;
          this.jsClick = true;
          this.hangClick = true;
        } else if (currentStep === 5) {
          this.sfClick = false;
          this.sgClick = false;
          this.ssClick = false;
          this.jfClick = false;
          this.jgClick = false;
          this.jsClick = true;
          this.hangClick = true;
        } else if (currentStep === 6) {
          this.sfClick = false;
          this.sgClick = false;
          this.ssClick = false;
          this.jfClick = false;
          this.jgClick = false;
          this.jsClick = false;
          this.hangClick = true;
        } else if (currentStep === 7) {
          this.sfClick = false;
          this.sgClick = false;
          this.ssClick = false;
          this.jfClick = false;
          this.jgClick = false;
          this.jsClick = false;
          this.hangClick = false;
        }
      } else if (currentStep === 0) {
        this.activeStep = 0;
        this.sfClick = false;
        this.sgClick = false;
        this.ssClick = false;
        this.jfClick = false;
        this.jgClick = false;
        this.jsClick = false;
        this.hangClick = false;
      } else if (currentStep === 100) {
        this.activeStep = 7;
        this.sfClick = false;
        this.sgClick = false;
        this.ssClick = false;
        this.jfClick = false;
        this.jgClick = false;
        this.jsClick = false;
        this.hangClick = false;
      }
    },
    getInformation() {
      const myIndex = Math.max.apply(
        Math,
        this.scanData.scans.map(function (o) {
          return o.step.order;
        })
      );
      let obj;

      try {
        obj = this.scanData.scans.find(function (o) {
          return o.step.order == myIndex;
        });
      } catch (e) {
        obj.step.order = 0;
      }

      let currentStep = this.stepToOrder(obj.step.order);
      this.initialStep = this.stepToOrder(obj.step.order);

      if (currentStep >= 0 && currentStep <= 7) {
        this.activeStep = currentStep;
        if (currentStep === 0) {
          this.sfClick = true;
          this.sgClick = true;
          this.ssClick = true;
          this.jfClick = true;
          this.jgClick = true;
          this.jsClick = true;
          this.hangClick = true;
        } else if (currentStep === 1) {
          this.sfClick = false;
          this.sgClick = true;
          this.ssClick = true;
          this.jfClick = true;
          this.jgClick = true;
          this.jsClick = true;
          this.hangClick = true;
        } else if (currentStep === 2) {
          this.sfClick = false;
          this.sgClick = false;
          this.ssClick = true;
          this.jfClick = true;
          this.jgClick = true;
          this.jsClick = true;
          this.hangClick = true;
        } else if (currentStep === 3) {
          this.sfClick = false;
          this.sgClick = false;
          this.ssClick = false;
          this.jfClick = true;
          this.jgClick = true;
          this.jsClick = true;
          this.hangClick = true;
        } else if (currentStep === 4) {
          this.sfClick = false;
          this.sgClick = false;
          this.ssClick = false;
          this.jfClick = false;
          this.jgClick = true;
          this.jsClick = true;
          this.hangClick = true;
        } else if (currentStep === 5) {
          this.sfClick = false;
          this.sgClick = false;
          this.ssClick = false;
          this.jfClick = false;
          this.jgClick = false;
          this.jsClick = true;
          this.hangClick = true;
        } else if (currentStep === 6) {
          this.sfClick = false;
          this.sgClick = false;
          this.ssClick = false;
          this.jfClick = false;
          this.jgClick = false;
          this.jsClick = false;
          this.hangClick = true;
        } else if (currentStep === 7) {
          this.sfClick = false;
          this.sgClick = false;
          this.ssClick = false;
          this.jfClick = false;
          this.jgClick = false;
          this.jsClick = false;
          this.hangClick = false;
        }
      } else if (currentStep === 0) {
        this.activeStep = 0;
        this.sfClick = false;
        this.sgClick = false;
        this.ssClick = false;
        this.jfClick = false;
        this.jgClick = false;
        this.jsClick = false;
        this.hangClick = false;
      } else if (currentStep === 100) {
        this.activeStep = 7;
        this.sfClick = false;
        this.sgClick = false;
        this.ssClick = false;
        this.jfClick = false;
        this.jgClick = false;
        this.jsClick = false;
        this.hangClick = false;
      } else {
        console.log('what');
      }
    },
  },
  beforeMount() {
    const myIndex = Math.max.apply(
      Math,
      this.scanData.scans.map(function (o) {
        return o.step.order;
      })
    );
    let obj;

    try {
      obj = this.scanData.scans.find(function (o) {
        return o.step.order == myIndex;
      });
    } catch (e) {
      obj.step.order = 0;
    }

    const currentStep = this.stepToOrder(obj.step.order);
    this.initialStep = this.stepToOrder(obj.step.order);

    if (currentStep >= 0 && currentStep <= 7) {
      this.activeStep = currentStep;
      if (currentStep === 0) {
        this.sfClick = true;
        this.sgClick = true;
        this.ssClick = true;
        this.jfClick = true;
        this.jgClick = true;
        this.jsClick = true;
        this.hangClick = true;
      } else if (currentStep === 1) {
        this.sfClick = false;
        this.sgClick = true;
        this.ssClick = true;
        this.jfClick = true;
        this.jgClick = true;
        this.jsClick = true;
        this.hangClick = true;
      } else if (currentStep === 2) {
        this.sfClick = false;
        this.sgClick = false;
        this.ssClick = true;
        this.jfClick = true;
        this.jgClick = true;
        this.jsClick = true;
        this.hangClick = true;
      } else if (currentStep === 3) {
        this.sfClick = false;
        this.sgClick = false;
        this.ssClick = false;
        this.jfClick = true;
        this.jgClick = true;
        this.jsClick = true;
        this.hangClick = true;
      } else if (currentStep === 4) {
        this.sfClick = false;
        this.sgClick = false;
        this.ssClick = false;
        this.jfClick = false;
        this.jgClick = true;
        this.jsClick = true;
        this.hangClick = true;
      } else if (currentStep === 5) {
        this.sfClick = false;
        this.sgClick = false;
        this.ssClick = false;
        this.jfClick = false;
        this.jgClick = false;
        this.jsClick = true;
        this.hangClick = true;
      } else if (currentStep === 6) {
        this.sfClick = false;
        this.sgClick = false;
        this.ssClick = false;
        this.jfClick = false;
        this.jgClick = false;
        this.jsClick = false;
        this.hangClick = true;
      } else if (currentStep === 7) {
        this.sfClick = false;
        this.sgClick = false;
        this.ssClick = false;
        this.jfClick = false;
        this.jgClick = false;
        this.jsClick = false;
        this.hangClick = false;
      }
    } else if (currentStep === 0) {
      this.activeStep = 0;
      this.sfClick = false;
      this.sgClick = false;
      this.ssClick = false;
      this.jfClick = false;
      this.jgClick = false;
      this.jsClick = false;
      this.hangClick = false;
    } else if (currentStep === 100) {
      this.activeStep = 7;
      this.sfClick = false;
      this.sgClick = false;
      this.ssClick = false;
      this.jfClick = false;
      this.jgClick = false;
      this.jsClick = false;
      this.hangClick = false;
    }
  },
  // beforeUpdate(){
  //    const myIndex = Math.max.apply(Math, this.scanData.scans.map(function(o) { return o.step.order; }))
  //     let obj;

  //     try {
  //         obj = this.scanData.scans.find(function(o){ return o.step.order == myIndex; })
  //     } catch (e){
  //         obj.step.order = 0
  //     }

  //     const currentStep = this.stepToOrder(obj.step.order)
  //     this.initialStep = this.stepToOrder(obj.step.order)

  //     if(currentStep >= 0 &&  currentStep <= 7){
  //         this.activeStep = currentStep
  //         if (currentStep === 0){
  //             this.sfClick = true
  //             this.sgClick = true
  //             this.ssClick = true
  //             this.jfClick = true
  //             this.jgClick = true
  //             this.jsClick = true
  //             this.hangClick = true
  //         } else if (currentStep === 1){
  //             this.sfClick = false
  //             this.sgClick = true
  //             this.ssClick = true
  //             this.jfClick = true
  //             this.jgClick = true
  //             this.jsClick = true
  //             this.hangClick = true
  //         } else if (currentStep === 2){
  //             this.sfClick = false
  //             this.sgClick = false
  //             this.ssClick = true
  //             this.jfClick = true
  //             this.jgClick = true
  //             this.jsClick = true
  //             this.hangClick = true
  //         } else if (currentStep === 3){
  //             this.sfClick = false
  //             this.sgClick = false
  //             this.ssClick = false
  //             this.jfClick = true
  //             this.jgClick = true
  //             this.jsClick = true
  //             this.hangClick = true
  //         } else if (currentStep === 4){
  //             this.sfClick = false
  //             this.sgClick = false
  //             this.ssClick = false
  //             this.jfClick = false
  //             this.jgClick = true
  //             this.jsClick = true
  //             this.hangClick = true
  //         } else if (currentStep === 5){
  //             this.sfClick = false
  //             this.sgClick = false
  //             this.ssClick = false
  //             this.jfClick = false
  //             this.jgClick = false
  //             this.jsClick = true
  //             this.hangClick = true
  //         } else if (currentStep === 6){
  //             this.sfClick = false
  //             this.sgClick = false
  //             this.ssClick = false
  //             this.jfClick = false
  //             this.jgClick = false
  //             this.jsClick = false
  //             this.hangClick = true
  //         } else if (currentStep === 7){
  //             this.sfClick = false
  //             this.sgClick = false
  //             this.ssClick = false
  //             this.jfClick = false
  //             this.jgClick = false
  //             this.jsClick = false
  //             this.hangClick = false
  //         }
  //     }  else if (currentStep === 0) {
  //         this.activeStep = 0
  //         this.sfClick = false
  //         this.sgClick = false
  //         this.ssClick = false
  //         this.jfClick = false
  //         this.jgClick = false
  //         this.jsClick = false
  //         this.hangClick = false
  //     } else if (currentStep === 100) {
  //         this.activeStep = 7
  //         this.sfClick = false
  //         this.sgClick = false
  //         this.ssClick = false
  //         this.jfClick = false
  //         this.jgClick = false
  //         this.jsClick = false
  //         this.hangClick = false
  //     }
  // }
};
</script>

<style>
.no-dropshadow {
  background-color: #f5f3f3;
  box-shadow: 0px 0px !important;
}
</style>
