<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column">
          <p class="title has-text-centered">Final Assembly Factory Summary</p>
          <b-field label="Select a month">
            <b-datepicker
              v-model="thisDate"
              type="month"
              placeholder="Click to select..."
              icon="calendar-today"
              trap-focus
            >
            </b-datepicker>
          </b-field>
          <b-button @click="getSummary">Get Summary</b-button>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-table
            :data="summary"
            :paginated="true"
            :per-page="40"
            :loading="isLoading"
            :bordered="true"
          >
            <b-table-column field="date" label="Date" v-slot="props">
              {{ props.row.date }}
            </b-table-column>
            <b-table-column field="movesIn" label="Units In" v-slot="props">
              {{ props.row.movesIn }}
            </b-table-column>
            <b-table-column field="activity" label="Moves" v-slot="props">
              {{ props.row.activity }}
            </b-table-column>
            <b-table-column field="movesOut" label="Units Out" v-slot="props">
              {{ props.row.movesOut }}
            </b-table-column>
          </b-table>
        </div>
      </div>
      <div class="columns">
        <div class="column is-4">
          <p class="is-size-5 has-text-centered">MTD</p>
          <b-table :data="mtd" :loading="isLoading" :bordered="true">
            <b-table-column v-slot="props">
              {{ props.row.name }}
            </b-table-column>
            <b-table-column field="plan" label="Plan" v-slot="props">
              {{ props.row.plan }}
            </b-table-column>
            <b-table-column field="actual" label="Actual" v-slot="props">
              {{ props.row.actual }}
            </b-table-column>
            <b-table-column field="actual" label="% Plan" v-slot="props">
              {{ ((props.row.actual / props.row.plan) * 100).toFixed(2) }}%
            </b-table-column>
          </b-table>
        </div>
        <div class="column is-4">
          <p class="is-size-5 has-text-centered">DLY</p>
          <b-table :data="dly" :loading="isLoading" :bordered="true">
            <b-table-column v-slot="props">
              {{ props.row.name }}
            </b-table-column>
            <b-table-column field="plan" label="AVG" v-slot="props">
              {{ props.row.average }}
            </b-table-column>
            <b-table-column field="actual" label="Need" v-slot="props">
              {{ props.row.need }}
            </b-table-column>
          </b-table>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <p class="is-size-5 has-text-centered">MTD</p>
          <b-table :data="chunked" :loading="isLoading" :bordered="true">
            <b-table-column label="Week" v-slot="props">
              {{ props.row.aWeek }}
            </b-table-column>
            <b-table-column field="shipPlan" label="Ship Plan" v-slot="props">
              {{ props.row.shipPlan }}
            </b-table-column>
            <b-table-column
              field="actualShip"
              label="Actual Shipped"
              v-slot="props"
            >
              {{ props.row.actualShip }}
            </b-table-column>
            <b-table-column label="Ship Delta" v-slot="props">
              {{ props.row.shipPlan - props.row.actualShip }}
            </b-table-column>
            <b-table-column field="movePlan" label="Move Plan" v-slot="props">
              {{ props.row.movePlan }}
            </b-table-column>
            <b-table-column
              field="actualMove"
              label="Actual Move"
              v-slot="props"
            >
              {{ props.row.actualMove }}
            </b-table-column>
            <b-table-column label="Move Delta" v-slot="props">
              {{ props.row.movePlan - props.row.actualMove }}
            </b-table-column>
          </b-table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { scannerService } from '../services/ScannerService';
export default {
  data() {
    return {
      thisDate: null,
      isLoading: false,
      summary: [],
      chunked: [],
      mtd: [
        {
          name: 'Turns',
          plan: 1,
          actual: 1,
        },
        { name: 'Moves', plan: 645, actual: 0 },
        { name: 'Ships', plan: 129, actual: 0 },
      ],
      dly: [
        {
          name: 'Turns',
          average: 0,
          need: 0,
        },
        { name: 'Moves', average: 0, need: 21.5 },
        { name: 'Ships', average: 0, need: 4 },
      ],
    };
  },
  methods: {
    chunk(array, chunkSize) {
      var perChunk = chunkSize; // items per chunk
      var inputArray = array;
      var result = inputArray.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []);
      return result;
    },
    async getSummary() {
      this.isLoading = true;
      this.summary = await scannerService.getFactorySummary(this.thisDate);

      this.chunked = this.chunk(this.summary, 7);

      const totalMoves = this.summary.reduce(
        (total, next) => total + next.activity,
        0
      );
      const totalShips = this.summary.reduce(
        (total, next) => total + next.movesOut,
        0
      );

      this.mtd[1].actual = totalMoves;
      this.mtd[2].actual = totalShips;

      this.dly[1].average = (totalMoves / 30).toFixed(2);
      this.dly[2].average = (totalShips / 30).toFixed(2);

      const tempArray = [];

      for (let i = 0; i < this.chunked.length; i++) {
        const temp = {
          aWeek: i + 1,
          shipPlan: 32,
          actualShip: this.chunked[i].reduce(
            (total, next) => total + next.movesOut,
            0
          ),
          movePlan: 161,
          actualMove: this.chunked[i].reduce(
            (total, next) => total + next.activity,
            0
          ),
        };
        tempArray.push(temp);
      }

      this.chunked = tempArray;

      this.isLoading = false;
    },
  },
};
</script>

<style></style>
