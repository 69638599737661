<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered">Confirm Unit Move Out</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div class="columns is-centered">
          <div class="column is-12">
            <p>
              This action will move the unit out of the supervisor's department
              and forward, onto QC. Are you sure you want to move this unit?
            </p>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button
          class="is-success"
          type="is-large"
          @click="
            $emit('confirm');
            $emit('close');
          "
          >Yes</b-button
        >
        <b-button
          class="is-danger"
          type="is-large"
          @click="
            $emit('decline');
            $emit('close');
          "
          >No</b-button
        >
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  props: ['initUnitObj'],
  methods: {},
  created() {},
};
</script>

<style></style>
