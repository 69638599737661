<template>
  <div class="container">
    <section class="section">
      <p class="title has-text-centered">CAD Progress Report</p>
      <div class="columns is-centered">
        <div class="column">
          <b-field grouped>
            <b-field label="Select start date">
              <b-datepicker placeholder="From" v-model="startDate">
              </b-datepicker>
            </b-field>
            <b-field label="Select end date">
              <b-datepicker placeholder="From" v-model="endDate">
              </b-datepicker>
              <b-button
                class="is-primary"
                @click="getEmployeeScans()"
                :loading="isLoading"
                :disabled="isLoading"
                >Search</b-button
              >
            </b-field>
          </b-field>
          <b-table :data="displayData" :loading="isLoading">
            <b-table-column
              field="employee"
              label="Supervisor"
              v-slot="props"
              sortable
            >
              {{ props.row.employee }}
            </b-table-column>
            <b-table-column
              field="sqFtMod"
              label="Total Factored Sq. Feet"
              v-slot="props"
              sortable
            >
              {{ props.row.sqFtMod }}
            </b-table-column>
            <b-table-column
              field="totalPercent"
              label="Total Bonus %"
              v-slot="props"
              sortable
            >
              <p
                class="has-text-weight-bold"
                :class="{
                  'has-text-warning': props.row.totalPercent < 20,
                  'has-text-success': props.row.totalPercent >= 80,
                }"
              >
                {{ props.row.totalPercent }}
              </p>
            </b-table-column>
            <b-table-column
              field="fabPercent"
              label="Fabrication %"
              v-slot="props"
              sortable
            >
              {{ props.row.fabPercent }}
            </b-table-column>
          </b-table>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <apexchart
            type="bar"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { scannerService } from '../services/ScannerService';
export default {
  data() {
    return {
      employees: [
        'GONZALEZ-DAVID',
        'GAVINO-AURORA',
        'LOPEZ-CESAR',
        'SARAGOSA-JAIME',
        'BORNET-DANIEL',
      ],
      startDate: new Date(),
      endDate: new Date(),
      displayData: [],
      series: [],
      scanData: [],
      chartOptions: {},
      sqFtUnmodified: 0,
      sqFtModified: 0,
      isLoading: false,
    };
  },
  methods: {
    returnDate(date) {
      return new Date(date).toISOString().slice(0, 10);
    },
    getPValue(sqFt, unitCost, profile) {
      if (unitCost / sqFt / profile < 1) {
        return 1;
      } else {
        return unitCost / sqFt / profile;
      }
    },
    getModifiedProduction(obj) {
      const stepDone = obj.step.name.replace(' ', '_').toLowerCase();
      if (
        obj.barcode &&
        !isNaN(
          this.getPValue(
            obj.barcode.sqFt,
            obj.barcode.unitCost,
            obj.barcode.finish.profileCost
          ) *
            (obj.barcode.sqFt * obj.barcode.finish[stepDone])
        )
      ) {
        return (
          this.getPValue(
            obj.barcode.sqFt,
            obj.barcode.unitCost,
            obj.barcode.finish.profileCost
          ) *
          obj.barcode.sqFt *
          obj.barcode.finish[stepDone]
        );
      } else {
        console.log('hit else case');
        return 0;
      }
    },
    sortAlphabetical(objArray) {
      objArray.sort(function (a, b) {
        var textA = a.DepartmentName.toUpperCase();
        var textB = b.DepartmentName.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    },
    checkForZero(sqFtMod) {
      if (((sqFtMod / 1000) * 100).toFixed(2) < 0) {
        return 0;
      } else {
        return ((sqFtMod / 1000) * 100).toFixed(2);
      }
    },
    async doSomethingWithFile(file) {
      let unModFt = 0;
      let modFt = 0;

      const reducer = function (acc, obj) {
        if (obj.barcode) {
          return parseFloat(acc) + parseFloat(obj.barcode.sqFt);
        } else {
          console.log(obj);
        }
      };
      unModFt = file.reduce(reducer, 0);

      console.log(unModFt);

      for (let i = 0; i < file.length; i++) {
        modFt += this.getModifiedProduction(file[i]);
      }

      return {
        unModFt: unModFt,
        supervisor: file[0].supervisor.name,
        employee: file[0].employee.name,
        sqFtUnMod: unModFt,
        sqFtMod: modFt.toFixed(2),
        fabrication: modFt.toFixed(2),
        totalPercent: ((modFt / 1000) * 100).toFixed(2),
        fabPercent: this.checkForZero(modFt),
      };
    },
    async getEmployeeScans() {
      this.displayData = [];
      this.isLoading = true;
      this.displayData.splice(0, this.scanData.length);
      this.scanData.splice(0, this.scanData.length);
      this.sqFtModified = 0;
      this.sqFtUnmodified = 0;

      const holder = [];
      const seriesdata = [
        {
          data: [],
          xaxis: {
            type: 'category',
          },
        },
      ];
      for (let j = 0; j < this.employees.length; j++) {
        //Ensure these values are reset between counts
        this.sqFtModified = 0;
        this.sqFtUnmodified = 0;
        this.scanData.splice(0, this.scanData.length);

        //Call API and return the array of objects for calculation
        const files = await scannerService.filterEmployerReport(
          this.employees[j],
          this.returnDate(this.startDate),
          this.returnDate(this.endDate)
        );

        let modifiedFile = [];
        files.data = files.data.filter((item) => item.barcode != null);
        console.log(files.data);
        if (files.data != undefined && files.data.length != 0) {
          modifiedFile = await this.doSomethingWithFile(files.data);
        } else {
          modifiedFile = {
            unModFt: 0,
            employee: this.employees[j],
            supervisor: this.employees[j],
            sqFtUnMod: 0,
            sqFtMod: 0,
            fabrication: 0,
            totalPercent: 0,
            fabPercent: 0,
          };
        }
        holder.push(modifiedFile);
        seriesdata[0]['data'].push({
          x: modifiedFile.employee,
          y: modifiedFile.totalPercent,
        });
      }
      this.displayData = holder;
      this.series = seriesdata;
      this.chartOptions = {
        chart: {
          id: 'vuechart-example',
        },
      };
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
