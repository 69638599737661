<template>
  <div>
    <section class="section">
      <div class="columns">
        <div class="column is-4 is-offset-4">
          <p class="is-size-3 has-text-centered mt-4">
            <b-tooltip label="Hot Item" position="is-left">
              <b-icon
                pack="fas"
                icon="fas fa-fire"
                size="is-medium"
                type="is-danger"
              >
              </b-icon>
            </b-tooltip>
            Work Order ID: {{ $route.params.id }}
          </p>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <p class="is-small has-text-centered">
            Initiated By:
            <span v-if="workOrder.created_by">{{ workOrder.created_by }}</span
            ><span v-else class="has-text-danger">N/A</span>
          </p>
          <p class="is-small has-text-centered">
            Initiated On:
            <span v-if="workOrder.date_initiated">{{
              workOrder.date_initiated
            }}</span
            ><span v-else class="has-text-danger">N/A</span>
          </p>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="tile is-ancestor">
        <glass-status :workOrder="workOrder" />
        <div class="tile is-6 box has-background-darkish has-text-light mx-2">
          <div v-if="workOrder.sources.length > 0">
            <b-field v-for="(source, index) of workOrder.sources" :key="index">
              <b-tag rounded>{{ source.barcode }}</b-tag>
            </b-field>
          </div>
          <div v-else>Units: Error No Units Found</div>
        </div>
      </div>
      <glass-order-info-panel :initWorkOrderObject="workOrder" />
      <div class="columns my-4 file-container">
        <glass-order-file
          name="Packing List"
          field="packing_list"
          :orderId="workOrder.id"
          :orderSrc="workOrder.packing_list"
        ></glass-order-file>
        <glass-order-file
          name="Quote"
          field="quote"
          :orderId="workOrder.id"
          :orderSrc="workOrder.quote"
        ></glass-order-file>
        <glass-order-file
          name="Markups"
          field="markUps"
          :orderId="workOrder.id"
          :orderSrc="workOrder.markUps"
        ></glass-order-file>
        <glass-order-file
          name="Invoice"
          field="invoice"
          :orderId="workOrder.id"
          :orderSrc="workOrder.invoice"
        ></glass-order-file>
      </div>
      <glass-note :initWorkOrder="workOrder"></glass-note>
      <div class="tile is-ancestor my-4">
        <div class="tile box has-background-darkish">
          <div v-if="!!workOrder.photos && workOrder.photos.length === 0">
            <p class="has-text-centered has-text-light">No Photos</p>
          </div>
          <div v-else>
            <div v-for="(photo, index) of workOrder.photos" :key="index">
              <figure @click="showSingle(photo.src)">
                <p>
                  <img :src="photo.src" class="mr-2 image is-128x128" />
                </p>
              </figure>
            </div>
          </div>
        </div>
      </div>

      <vue-easy-lightbox
        :visible="visible"
        :imgs="imgs"
        :index="0"
        @hide="handleHide"
      >
      </vue-easy-lightbox>
    </section>
  </div>
</template>

<script>
import GlassOrderFile from './GlassOrderFile.vue';
import GlassOrderInfoPanel from './GlassOrderInfoPanel.vue';
import GlassNote from './GlassNote.vue';
import GlassStatus from './GlassStatus.vue';
import { glassOrderService } from '../services/GlassOrderService';
export default {
  components: { GlassOrderFile, GlassOrderInfoPanel, GlassNote, GlassStatus },
  data() {
    return {
      workOrder: null,
      apple: null,
      visible: false,
      imgs: '',
    };
  },
  methods: {
    showSingle(imgSrc) {
      this.imgs = imgSrc;
      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },
  created() {
    glassOrderService.getGlassOrderById(this.$route.params.id)
      .then((data) => {
        if (data) {
          this.workOrder = data;
        }
      });
  },
};
</script>

<style scoped>
.tile-equal-height {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.column:last-child {
  margin-bottom: 1.5rem;
}

.tile:last-child {
  margin-bottom: 1.5rem;
}

.info-container {
  position: relative;
}
#special-button {
  position: absolute;
  opacity: 0.5;
  margin-right: 1rem;
  margin-top: 1rem;
  top: 0;
  right: 0;
}
#special-button:hover {
  opacity: 1;
}
</style>
