<template>
  <div class="container">
    <section class="section">
      <p class="title has-text-centered">Paint and Powdercoat Report</p>
      <div class="columns is-centered">
        <div class="column">
          <b-table
            :data="returnData"
            :loading="loading"
            paginated
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
          >
            <b-table-column field="project.name" label="Project" v-slot="props">
              {{ props.row.project.name }}
            </b-table-column>
            <b-table-column field="barcode" label="Unit" v-slot="props">
              {{ props.row.barcode }}
            </b-table-column>
            <b-table-column
              field="finish.unitType"
              label="Finish"
              v-slot="props"
            >
              {{ props.row.finish.unitType }}
            </b-table-column>
            <b-table-column field="scans" label="Date In" v-slot="props">
              {{ new Date(props.row.scans[0].activityStart).toLocaleString() }}
            </b-table-column>

            <b-table-column field="finish.unitType" label="Hot">
              <b-checkbox true-value="HOT" false-value="STD"> </b-checkbox>
            </b-table-column>

            <b-table-column field="finish.unitType" label="CRD" v-slot="props">
              <div v-if="props.row.finish.value === 7">
                <span
                  >HOT:
                  {{
                    addDays(
                      new Date(props.row.scans[0].activityStart),
                      3
                    ).toLocaleString()
                  }}</span
                >
                <br />
                <span
                  >STD:
                  {{
                    addDays(
                      new Date(props.row.scans[0].activityStart),
                      6
                    ).toLocaleString()
                  }}</span
                >
              </div>
              <div v-if="props.row.finish.value === 8">
                <span
                  >HOT:
                  {{
                    addDays(
                      new Date(props.row.scans[0].activityStart),
                      5
                    ).toLocaleString()
                  }}</span
                >
                <br />
                <span
                  >STD:
                  {{
                    addDays(
                      new Date(props.row.scans[0].activityStart),
                      10
                    ).toLocaleString()
                  }}</span
                >
              </div>
            </b-table-column>

            <b-table-column field="finish.unitType" label="OPD">
              Input Required
            </b-table-column>
            <template #empty>
              <div class="has-text-centered">No records</div>
            </template>
          </b-table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import { sourceService } from '../services/SourceService';

export default {
  data() {
    return {
      loading: false,
      perPage: 20,
      returnData: [],
      checkboxCustom: false,
    };
  },
  methods: {
    async getInformation() {
      sourceService
        .getMatsonReport((data) => {
          this.returnData = data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
  },

  beforeMount() {
    this.getInformation();
  },
};
</script>

<style></style>
