<template>
  <b-navbar :fixed-top="true" type="is-darkish">
    <template #brand>
      <b-navbar-item tag="div">
        <img src="@/assets/RB_Logo.png" alt="Riviera Bronze Logo" class="" />
      </b-navbar-item>
    </template>
    <template #end>
      <b-navbar-item
        tag="router-link"
        :to="{ path: '/admin-dashboard/projects-table' }"
        class="is-hidden-desktop"
      >
        Projects
      </b-navbar-item>
      <b-navbar-item
        tag="router-link"
        :to="{ path: '/admin-dashboard/steps' }"
        class="is-hidden-desktop"
      >
        Scanner
      </b-navbar-item>
      <b-navbar-item tag="div">
        <!-- <div class="buttons">
                    <a class="button is-light">
                        Logout
                    </a>
                </div> -->
        <div class="buttons">
          <router-link
            to="/admin-dashboard/password-reset"
            tag="button"
            class="button is-light"
          >
            Account
          </router-link>
          <a class="button is-light" @click="logout()"> Logout </a>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    logout() {
      this.$store
        .dispatch('logout')
        .then(() => {
          this.$router.push({ path: '/' });
        })
        .catch((e) => {
          console.log(e);
          this.$router.push({ path: '/' });
        });
    },
  },
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
#app > div.no-pad-no-margin > div > nav > div.navbar-menu > div.navbar-end > a {
  color: whitesmoke;
}

#app
  > div.no-pad-no-margin
  > div
  > nav
  > div.navbar-menu
  > div.navbar-end
  > a:hover {
  color: #fca91e;
  transition: color 0.1s ease-out, background 0.1s ease-out,
    border 0.1s ease-out, height 0.35s ease, line-height 0.35s ease;
}

div.navbar-menu {
  background-color: #3d4045;
}

#app
  > div.no-pad-no-margin
  > div
  > nav
  > div.navbar-menu.is-active
  > div.navbar-end
  > a.navbar-item.is-hidden-desktop.router-link-exact-active.is-active {
  background-color: #4d5157;
}
nav {
  min-height: 55px;
}
</style>
