<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered">
          Edit: {{ unit.barcode }}
        </p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div class="columns is-centered">
          <div class="column is-8">
            <b-field label="Barcode">
              <b-input
                v-model="unit.barcode"
                placeholder="Internal barcode"
                disabled
              ></b-input>
            </b-field>
            <b-field label="Architect Unit #">
              <b-input
                v-model="unit.unit"
                placeholder="Enter the client's unit name"
              ></b-input>
            </b-field>
            <b-field label="Location">
              <b-input v-model="unit.location" placeholder="Location"></b-input>
            </b-field>
            <b-field label="Description">
              <b-input
                v-model="unit.description"
                placeholder="Location"
              ></b-input>
            </b-field>
            <b-field label="Type">
              <b-select placeholder="Unit type" v-model="unit.type">
                <option
                  v-for="option in typeList"
                  :value="option"
                  :key="option"
                >
                  {{ option }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Swing">
              <b-select placeholder="Swing Type" v-model="unit.swing">
                <option
                  v-for="(option, index) in swingList"
                  :value="option.value"
                  :key="index"
                >
                  {{ option.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Material and Finish">
              <b-select
                placeholder="Material and Finish"
                v-model="unit.finish.value"
              >
                <option
                  v-for="(option, index) in finishList"
                  :value="option.value"
                  :key="index"
                >
                  {{ option.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Sq. Ft">
              <b-numberinput
                min="0"
                v-model="unit.sqFt"
                controls-position="compact"
              >
              </b-numberinput>
            </b-field>
            <!-- <b-field label="Unit Cost">
                    <b-numberinput min="0" v-model="unit.unitCost" controls-position="compact">
                    </b-numberinput>
                </b-field> -->
            <b-field label="Operable">
              <b-checkbox v-model="unit.operability"> </b-checkbox>
            </b-field>
            <b-field label="Approved For Production">
              <b-checkbox v-model="unit.approved"> </b-checkbox>
            </b-field>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button class="is-primary" @click="editUnit()">Send</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { sourceService } from '../../services/SourceService';
export default {
  data() {
    return {
      unit: null,
      typeList: ['D', 'D2', 'W'],
      finishList: [
        {
          name: 'Steel Metalized/ Galv',
          value: 1,
        },
        {
          name: 'Steel Powdercoat/Paint',
          value: 2,
        },
        {
          name: 'Thermal Metalized',
          value: 3,
        },
        {
          name: 'Thermal Powdercoat/Paint',
          value: 4,
        },
      ],
      swingList: [
        {
          name: 'Fixed',
          value: 'F',
        },
        {
          name: 'Inswing',
          value: 'I',
        },
        {
          name: 'Outswing',
          value: 'O',
        },
        {
          name: 'Slider',
          value: 'S',
        },
        {
          name: 'Bifold',
          value: 'B',
        },
        {
          name: 'Pivot',
          value: 'P',
        },
      ],
    };
  },
  computed: {
    computedOperability: function () {
      return !!this.unit.operability;
    },
  },
  props: ['initUnitObj'],
  methods: {
    async editUnit() {
      console.log('creating unit...');
      await sourceService
        .editSourceUnit(this.unit.barcode, {
          barcode: this.unit.barcode,
          unit: this.unit.unit,
          location: this.unit.location,
          description: this.unit.description,
          sqFt: this.unit.sqFt,
          cost: this.unit.cost,
          operability: this.unit.operability,
          approved: this.unit.approved,
          finish: this.unit.finish.value,
          type: this.unit.type,
          swing: this.unit.swing,
        })
        .then((data) => {
          this.$emit('close');
          this.$emit('success');
        })
        .catch((e) => {
          this.$emit('failure', e);
        });
    },
  },
  created() {
    this.initUnitObj.operability = Boolean(this.initUnitObj.operability);
    this.initUnitObj.approved = Boolean(this.initUnitObj.approved);
    this.initUnitObj.sqFt = Number(this.initUnitObj.sqFt);
    this.initUnitObj.unitCost = Number(this.initUnitObj.unitCost);
    this.unit = this.initUnitObj;
  },
};
</script>

<style></style>
