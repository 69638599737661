<template>
  <div>
    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-2 is-offset-10">
            <b-field>
              <b-switch
                v-model="isSwitchedCustom"
                size="is-large"
                type="is-success"
              >
              </b-switch>
              <span
                v-if="isSwitchedCustom === false"
                class="has-text-weight-bold"
                >Unaddressed</span
              >
              <span v-else class="has-text-weight-bold">All</span>
            </b-field>
          </div>
        </div>
        <div class="columns is-desktop is-vcentered is-centered">
          <div class="column">
            <b-table
              :data="returnMessageArray()"
              :paginated="true"
              :per-page="20"
              current-page.sync="1"
              striped
              detailed
              detail-key="id"
              detail-transition="fade"
            >
              <b-table-column
                field="name"
                label="Client Name"
                v-slot="props"
                sortable
                searchable
                :bordered="true"
              >
                {{ props.row.user.name }}
              </b-table-column>
              <b-table-column field="email" label="Client Email" v-slot="props">
                {{ props.row.user.email }}
              </b-table-column>
              <b-table-column
                field="email"
                label="Date Submitted"
                v-slot="props"
              >
                {{ new Date(props.row.createdAt).toLocaleDateString() }}
              </b-table-column>
              <b-table-column field="addressed" label="Edit" v-slot="props">
                <b-checkbox
                  v-model="props.row.addressed"
                  class="card-footer-item"
                  size="is-small"
                  @input="markAsAddressed(message.id, $event)"
                  >Addressed
                </b-checkbox>
              </b-table-column>
              <template #detail="props">
                <article class="media">
                  <div class="media-content">
                    <div class="content">
                      <p class="p-2">
                        {{ props.row.content }}
                      </p>
                    </div>
                  </div>
                </article>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import ResponseModal from './ResponseModal.vue';

export default {
  data() {
    return {
      messages: [],
      unaddressedMessages: [],
      isSwitchedCustom: false,
    };
  },
  beforeMount() {
    axios
      .get('https://rb-portal-backend.herokuapp.com/message/')
      .then((res) => {
        this.messages = res.data;
        this.unaddressedMessages = res.data.filter(
          (message) => message.addressed === false
        );
      });
  },
  methods: {
    openResponseModal(messageObj) {
      this.$buefy.modal.open({
        parent: this,
        component: ResponseModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          messageObj: messageObj,
        },
        events: {},
      });
    },
    returnMessageArray() {
      if (this.isSwitchedCustom === false) {
        return this.unaddressedMessages;
      } else {
        return this.messages;
      }
    },
    markAsAddressed(id, e) {
      axios
        .patch('https://rb-portal-backend.herokuapp.com/message/' + id, {
          addressed: e,
        })
        .then((res) => {
          console.log(res);
        });
    },
  },
};
</script>

<style></style>
