<template>
  <div class="container">
    <section class="section">
      <p class="title has-text-centered">
        Individual Supervisor Progress Report
      </p>
    </section>
    <section class="section">
      <div class="columns is-centered">
        <div class="column">
          <b-field grouped>
            <b-field label="Supervisor Name">
              <b-select placeholder="Select a name" v-model="supervisor">
                <option value="ACOSTA-BRAU">ACOSTA-BRAU</option>
                <option value="CARON-JAMES">CARON-JAMES</option>
                <option value="DURAN-ALEJANDRO">DURAN-ALEJANDRO</option>
                <option value="RICO-ROGELIO">RICO-ROGELIO</option>
                <option value="GILL-DYLAN">GILL-DYLAN</option>
                <option value="VILLAFANA-EFREN">VILLAFANA-EFREN</option>
                <option value="NUNES-DAVID">NUNES-DAVID</option>
                <option value="LOPEZ-CESAR">LOPEZ-CESAR</option>
                <option value="MACIAS-DAVID">MACIAS-DAVID</option>
                <option value="CAUDILLO-DARIO">CAUDILLO-DARIO</option>
                <option value="MENDEZ-FERNANDO">MENDEZ-FERNANDO</option>
                <option value="NAVARRO-RAMON">NAVARRO-RAMON</option>
                <option value="GUZMAN-BLANCA">GUZMAN-BLANCA</option>
              </b-select>
            </b-field>
            <b-field label="Select start date">
              <b-datepicker placeholder="From" v-model="startDate">
              </b-datepicker>
            </b-field>
            <b-field label="Select end date">
              <b-datepicker placeholder="From" v-model="endDate">
              </b-datepicker>
              <b-button class="is-primary" @click="getSupervisorScans()"
                >Search</b-button
              >
            </b-field>
          </b-field>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="columns">
        <div class="column is-3 has-background-light">
          <p>
            Total Scans: <strong>{{ totalScans }}</strong>
          </p>
          <p>Factored SF: {{ sqFtModified.toFixed(2) }} ft.</p>
          <p>Fab: {{ (sqFtModified - hang).toFixed(2) }} ft.</p>
          <p>Hang Total Sq Ft.: {{ hang.toFixed(2) }} ft.</p>
          <div v-if="supervisor === 'MACIAS-DAVID'">
            QC Goal %: {{ (sqFtModified / 225) * 100 }}
          </div>
          <div v-else-if="supervisor === 'GUZMAN-BLANCA'">
            Finishing Goal %: {{ ((sqFtModified / 1300) * 100).toFixed(2) }}
          </div>
          <div v-else>
            <p>
              Total % of Goal:
              {{
                (((sqFtModified - hang) / 400 + hang / 225) * 100).toFixed(2)
              }}
              %
            </p>
            <p>
              Fab %: {{ (((sqFtModified - hang) / 400) * 100).toFixed(2) }}%
            </p>
            <p>Hang %: {{ ((hang / 225) * 100).toFixed(2) }}%</p>
          </div>
        </div>
        <div class="column">
          <b-table :data="scanData" :paginated="true" :per-page="300">
            <b-table-column
              field="barcode"
              label="Barcode"
              v-slot="props"
              searchable
              sortable
            >
              {{ props.row.barcode.barcode }}
            </b-table-column>
            <b-table-column field="sqFt" label="SF" v-slot="props">
              {{ props.row.barcode.sqFt }}
            </b-table-column>
            <b-table-column
              field="employee.name"
              label="Employee"
              v-slot="props"
              sortable
            >
              {{ props.row.employee.name }}
            </b-table-column>
            <b-table-column
              field="step.name"
              label="Step"
              v-slot="props"
              searchable
              sortable
            >
              {{ props.row.step.name }}
            </b-table-column>
            <b-table-column
              field="activityStop"
              label="Time Complete"
              v-slot="props"
              sortable
            >
              {{ new Date(props.row.activityStop) }}
            </b-table-column>
            <b-table-column field="Finish_Value" label="Finish" v-slot="props">
              {{ props.row.barcode.finish.unitType }}
            </b-table-column>
            <b-table-column field="Finish_Value" label="P-Value" v-slot="props">
              {{
                getPValue(
                  props.row.barcode.sqFt,
                  props.row.barcode.unitCost,
                  props.row.barcode.finish.profileCost
                )
              }}
            </b-table-column>
            <b-table-column
              field="Finish_Value"
              label="Factored SF Value"
              v-slot="props"
            >
              {{ getModifiedProduction(props.row) }}
            </b-table-column>
          </b-table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      supervisor: null,
      startDate: new Date(),
      endDate: new Date(),
      dates: [],
      scanData: [],
      totalScans: 0,
      sqFtUnmodified: 0,
      sqFtModified: 0,
      hang: 0,
      hangPercent: null,
      fabPercent: null,
    };
  },
  methods: {
    returnDate(date) {
      return new Date(date).toISOString().slice(0, 10);
    },
    getPValue(sqFt, unitCost, profile) {
      if ((unitCost / sqFt / profile).toFixed(2) < 1) {
        return 1;
      } else {
        return (unitCost / sqFt / profile).toFixed(2);
      }
    },
    getModifiedProduction(obj) {
      const stepDone = obj.step.name.replace(' ', '_').toLowerCase();
      if (this.supervisor === 'MENDEZ-FERNANDO' && obj.step.order < 4) {
        return 0;
      } else {
        if (
          !isNaN(
            (obj.barcode.unitCost /
              obj.barcode.sqFt /
              obj.barcode.finish.profileCost) *
              (obj.barcode.sqFt * obj.barcode.finish[stepDone])
          )
        ) {
          console.log(
            this.getPValue(
              obj.barcode.sqFt,
              obj.barcode.unitCost,
              obj.barcode.finish.profileCost
            )
          );
          return (
            this.getPValue(
              obj.barcode.sqFt,
              obj.barcode.unitCost,
              obj.barcode.finish.profileCost
            ) *
            (obj.barcode.sqFt * obj.barcode.finish[stepDone])
          );
        } else {
          return 0;
        }
      }
    },
    getHang(obj) {
      const stepDone = obj.step.name.replace(' ', '_').toLowerCase();
      if (
        (stepDone === 'hang' || obj.step.name === 'HANG') &&
        obj.barcode.swing != 'F'
      ) {
        return parseFloat(
          (obj.barcode.unitCost /
            obj.barcode.sqFt /
            obj.barcode.finish.profileCost) *
            obj.barcode.sqFt *
            obj.barcode.finish.hang
        );
      } else {
        return 0;
      }
    },
    getSupervisorScans() {
      this.hangPercent = null;
      this.fabPercent = null;

      axios
        .get(
          'https://rb-portal-backend.herokuapp.com/scanner/sqft-report?supervisor=' +
            this.supervisor +
            '&start_date=' +
            this.returnDate(this.startDate) +
            '&end_date=' +
            this.returnDate(this.endDate)
        )
        .then((res) => {
          //Ensure these values are reset between counts
          console.log(res);
          this.sqFtModified = 0;
          this.hang = 0;

          //Get the result of the axios call, save the number of results. Could be simplified to make total a into a computed property of data
          this.scanData = res.data;
          this.totalScans = res.data.length;

          //Get the total sq. feet produced by a supervisor
          const reducer = function (acc, obj) {
            return acc + obj.barcode.sqFt;
          };
          this.sqFtUnmodified = this.scanData.reduce(reducer, 0);

          for (let i = 0; i < this.scanData.length; i++) {
            this.sqFtModified += this.getModifiedProduction(this.scanData[i]);
            this.hang += this.getHang(this.scanData[i]);
          }

          this.hangPercent = (this.hang / 225) * 100;
          this.fabPercent = ((this.sqFtModified - this.hang) / 400) * 100;
        });
    },
  },
};
</script>

<style></style>
