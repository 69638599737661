import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import router from './router';
import store from './store';
import Buefy from 'buefy';
import VueApexCharts from 'vue-apexcharts';
import VueEasyLightbox from 'vue-easy-lightbox';

// Vue.prototype.$http = axios;
Vue.use(VueEasyLightbox);

Vue.use(VueApexCharts);
Vue.component('apex-chart', VueApexCharts);
Vue.config.productionTip = false;
Vue.use(Buefy);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
