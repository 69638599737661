import { _axios } from '../plugins/axios';

class RbUserService {
  constructor() {
    this.axios = _axios;
  }

  async addUser(userPayload) {
    return await this.axios
      .post(`/rbuser`, userPayload)
      .then((res) => res.data);
  }

  async addUnit(unitPayload) {
    return await this.axios
      .post(`/rbuser/addUnit`, unitPayload)
      .then((res) => res.data);
  }

  async unassignUnit(payload) {
    return await this.axios
      .patch(`/rbuser/addUnit?unassign=true`, payload)
      .then((res) => res.data);
  }

  async assignUnitToSupervisor(payload) {
    return await this.axios
      .patch(`/rbuser/assignUnits`, payload)
      .then((res) => res.data);
  }

  /**
   *
   * @param {*} supervisor
   * @returns
   */
  async getSupervisor(supervisor) {
    return await this.axios
      .get(`/rbuser/${supervisor}`)
      .then((res) => res.data);
  }

  /**
   *
   * @returns
   */
  async listSupervisors() {
    return await this.axios
      .get(`/rbuser/list-supervisors`)
      .then((res) => res.data);
  }

  async listGlazing() {
    return await this.axios.get(`/rbuser/list-glazing`).then((res) => res.data);
  }

  async listTigerTeamLeads() {
    return await this.axios
      .get(`/rbuser/list-tiger-team-leads`)
      .then((res) => res.data);
  }

  async listTeams() {
    return await this.axios.get(`/rbuser/teams`).then((res) => res.data);
  }

  async listUsers() {
    return await this.axios.get(`/rbuser`).then((res) => res.data);
  }

  async listQC() {
    return await this.axios.get(`/rbuser/list-qc`).then((res) => res.data);
  }

  async getTerm(userId) {
    return await this.axios
      .get(`/rbuser/?term=${userId}`)
      .then((res) => res.data);
  }

  async getForecast() {
    return await this.axios
      .get(`/rbuser/forecast`, {
        responseType: 'blob',
      })
      .then((res) => res.data);
  }
  async getFinalAssemblyForecast() {
    return await this.axios
      .get(`/rbuser/final-assembly-forecast`, {
        responseType: 'blob',
      })
      .then((res) => res.data);
  }
}

export const rbUserService = new RbUserService();
