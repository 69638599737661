<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered">Update Project</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <b-field label="Name" horizontal>
          <b-input v-model="project.name"></b-input>
        </b-field>
        <b-field label="Date Due" horizontal>
          <b-datepicker
            v-model="project.due_date"
            :show-week-number="true"
            placeholder="Select the due date"
            icon="calendar-today"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button class="is-primary" @click="updateProject()">Send</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { projectService } from '../services/ProjectService';

export default {
  data() {
    return {
      project: this.projectObj,
      isSending: false,
    };
  },
  props: ['projectObj'],
  methods: {
    async updateProject() {
      console.log(this.project);
      projectService
        .updateProject(this.project.key, {
          name: this.project.name,
          address: this.project.address,
          date_complete: this.project.date_complete,
          due_date: this.project.due_date,
          footage: this.project.footage,
          signed_for_production: this.project.signed_for_production,
          notes: this.project.notes,
        })
        .then(() => {
          console.log('Project updated');
          this.$emit('success-event');
          this.$emit('close');
        })
        .catch((e) => {
          console.log(e);
          this.$emit('failure-event', e);
        });
    },
  },
  computed: {},
};
</script>

<style>
.modal-card {
  width: 1000px;
  min-height: 750px;
}
</style>
