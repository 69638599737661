<template>
  <div class="columns">
    <div class="column pb-0">
      <div class="hero is-fullheight is-dark has-background">
        <img
          alt="Riviera Bronze Windows Image"
          class="hero-background is-transparent"
          src="@/assets/portal_pic.jpg"
        />
        <div class="columns mt-6">
          <div class="column is-top is-4 is-offset-half mt-6">
            <div class="box has-background-light severe-margin">
              <div class="columns">
                <div class="column is-6">
                  <img
                    src="@/assets/rb_transparent.png"
                    alt="Riviera Bronze Logo"
                    class=""
                  />
                </div>
                <div class="column is-2 is-offset-4">
                  <b-button @click="logout()"> Logout </b-button>
                </div>
              </div>
              <div class="columns mt-4">
                <div class="column">
                  <p
                    class="is-size-3 has-text-weight-bold has-text-riviera-bronze"
                  >
                    Admin Portal
                  </p>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <p>Welcome, {{ name }}</p>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <p>What would you like to do?</p>
                  <button class="button mt-2" @click="goToDash()">
                    View All Jobs
                  </button>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <a
                    href="./Riviera_Bronze_Warranty_2021.pdf"
                    class="has-text-twitter"
                    ><p>View our warranty policy</p></a
                  >
                  <a href="./RB_MAINTENANCE_GUIDE.pdf" class="has-text-twitter"
                    ><p>Finish care details</p></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      name: 'Admin',
    };
  },
  methods: {
    goToDash() {
      this.$router.push({ name: 'AdminDashboard' });
    },
    logout() {
      this.$store
        .dispatch('logout')
        .then(() => {
          this.$router.push({ path: '/' });
        })
        .catch((e) => {
          console.log(e);
          this.$router.push({ path: '/' });
        });
    },
  },
  mounted() {},
};
</script>

<style>
.is-top {
  z-index: 20;
  min-height: 500px;
}

.hero.has-background {
  position: relative;
  overflow: hidden;
}

.hero-background {
  position: absolute;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}

.hero-background.is-transparent {
  opacity: 0.3;
}
</style>
