<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered">Submit Work Order</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <b-field label="Unit Involved">
          <b-autocomplete
            v-model="barcode"
            placeholder="e.g. WOLFMILL-D1"
            :keep-first="false"
            :open-on-focus="true"
            :data="barcodeList"
            field="barcode"
            @select="(option) => (selectedCode = option.barcode)"
            :clearable="true"
          >
          </b-autocomplete>
        </b-field>
        <b-field label="Work Order Type">
          <b-select placeholder="REWORK" v-model="selectedType">
            <option v-for="option in types" :value="option.id" :key="option.id">
              {{ option.name }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Note">
          <b-input type="textarea" v-model="note"></b-input>
        </b-field>
        <b-field label="Issue to: ">
          <b-autocomplete
            v-model="user"
            placeholder="SMITH-JOHN"
            :keep-first="false"
            :open-on-focus="true"
            :data="users"
            field="name"
            @select="(option) => (selectedUser = option.key)"
            :clearable="true"
          >
          </b-autocomplete>
        </b-field>
        <b-field label="Image">
          <b-upload v-model="dropFile" expanded drag-drop>
            <section class="section">
              <div class="content has-text-centered">
                <p>
                  <b-icon icon="upload" size="is-large"> </b-icon>
                </p>
                <p>Drop your files here or click to upload</p>
              </div>
            </section>
          </b-upload>
        </b-field>
        <div class="tags">
          <span v-if="dropFile" class="tag is-primary">
            {{ dropFile.name }}
            <button
              class="delete is-small"
              type="button"
              @click="deleteDropFile()"
            ></button>
          </span>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button class="is-primary" @click="sendWorkOrder()">Submit</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { sourceService } from '../services/SourceService';
import { rbUserService } from '../services/RbUserService';
import { workOrderService } from '../services/WorkOrderService';
export default {
  data() {
    return {
      barcode: '',
      barcodeList: [],
      selectedCode: '',
      note: null,
      users: [],
      user: '',
      selectedUser: '',
      types: [],
      selectedType: null,
      dropFile: null,
    };
  },
  methods: {
    async getInfo() {
      await sourceService.getSourceTerm(this.barcode).then((data) => {
        this.barcodeList = data;
      });

      await rbUserService.getTerm(this.user).then((data) => {
        this.users = data;
      });
      await workOrderService.getWorkOrderTypes().then((data) => {
        this.types = data;
      });
    },
    async sendWorkOrder() {
      let formData = new FormData();
      formData.append('source', this.barcode);
      formData.append('note', this.note);
      formData.append('type', this.selectedType);
      formData.append('issued_by', this.$store.state.user.id);
      formData.append('issued_to', this.selectedUser);
      formData.append('file', this.dropFile);

      await workOrderService
        .addWorkOrder(formData)
        .then((data) => {
          console.log(data);
          this.$emit('success-event');
          this.$emit('close');
        })
        .catch((e) => {
          this.$emit('failure-event', e);
        });
    },
  },
  watch: {
    barcode: async function () {
      await sourceService.getSourceTerm(this.barcode).then((data) => {
        this.barcodeList = data;
      });
    },
    user: async function () {
      await rbUserService.getTerm(this.user).then((data) => {
        this.users = data;
      });
    },
  },
  created() {
    this.getInfo();
  },
};
</script>

<style></style>
