<template>
  <div>
    <div class="tile level mb-1 has-background-light">
      <div class="level-item has-text-centered">
        <div>
          <b-button class="is-success" @click="addWorkOrder">
            <i class="fas fa-plus-circle"></i>
            New Order
          </b-button>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <b-field label="Type">
          <b-select
            placeholder="Select a type"
            v-model="type"
            @input="getInformation"
          >
            <option value="">All</option>
            <option value="New-Project">New-Project</option>
            <option value="Warranty-Failure">Warranty-Failure</option>
            <option value="Replacement">Replacement</option>
          </b-select>
        </b-field>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <b-field label="Date Submitted Range">
            <b-datepicker
              placeholder="Click to select..."
              v-model="dates"
              range
              @input="getInformation"
            >
            </b-datepicker>
          </b-field>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <b-field label="Date Resolved Range">
          <b-datepicker
            placeholder="Click to select..."
            v-model="resolvedDates"
            range
            @input="getInformation"
          >
          </b-datepicker>
        </b-field>
      </div>
      <div class="level-item has-text-centered">
        <b-field label="Limit">
          <b-select
            placeholder="Select a type"
            v-model="limit"
            @input="getInformation"
          >
            <option value="100">100</option>
            <option value="500">500</option>
          </b-select>
        </b-field>
      </div>
    </div>

    <b-table
      :data="workOrders"
      :paginated="true"
      :per-page="20"
      current-page.sync="1"
      :bordered="true"
      striped
      :show-header="true"
      detailed
      detail-key="id"
      detail-transition="fade"
      :show-detail-icon="true"
    >
      <b-table-column
        field="source.project.name"
        label="Project"
        v-slot="props"
        searchable
        sortable
      >
        {{ props.row.source.project.name }}
      </b-table-column>
      <b-table-column
        field="source.barcode"
        label="Unit"
        v-slot="props"
        searchable
        sortable
      >
        {{ props.row.source.barcode }}
      </b-table-column>
      <b-table-column field="type" label="Type" v-slot="props">
        {{ props.row.type.name }}
      </b-table-column>
      <b-table-column
        field="date_submitted"
        label="Date Submitted"
        v-slot="props"
      >
        <span class="tag is-success">
          {{ new Date(props.row.date_submitted).toLocaleDateString() }}
        </span>
      </b-table-column>

      <b-table-column
        field="date_resolved"
        label="Date Resolved"
        v-slot="props"
      >
        <span
          v-if="typeof props.date_resolved === 'date'"
          class="tag is-success"
        >
          {{ new Date(props.row.date_submitted).toLocaleDateString() }}
        </span>
        <span v-else class="tag is-danger">UNRESOLVED</span>
      </b-table-column>
      <b-table-column field="issued_by" label="Issuer" v-slot="props">
        {{ props.row.issued_by.name }}
      </b-table-column>
      <b-table-column
        field="resolved"
        label="Resolution Status"
        centered
        searchable
      >
        <template slot="searchable" slot-scope="props">
          <b-select
            placeholder="All"
            v-model="props.filters[props.column.resolved]"
          >
            <option :value="true">Resolved</option>
            <option :value="false">Unresolved</option>
          </b-select>
        </template>

        <template slot-scope="props">
          <i
            class="is-size-5 has-text-centered"
            :class="
              props.row.resolved === true
                ? 'fas fa-check-square has-text-success'
                : 'fas fa-minus-circle has-text-danger'
            "
          ></i>
        </template>
      </b-table-column>
      <b-table-column field="issued_by.name" label="Issuer" searchable>
        <template v-slot="props">
          {{ props.row.issued_by.name }}
        </template>
      </b-table-column>
      <b-table-column
        field="issued_to"
        label="Issued To"
        v-slot="props"
        searchable
        sortable
      >
        {{ props.row.issued_to.name }}
      </b-table-column>
      <template #detail="props">
        <article class="media">
          <div v-if="!!props.row.src">
            <div
              v-if="props.row.src.substring(props.row.src.length - 3) === 'pdf'"
            >
              <p>
                <a :href="props.row.src" target="_blank">
                  <b-icon
                    pack="fas"
                    icon="fas fa-file-pdf fa-3x"
                    size="is-large"
                  ></b-icon>
                </a>
              </p>
            </div>
            <div v-else>
              <figure class="media-left" @click="showSingle(props.row.src)">
                <p class="image is-128x128">
                  <img :src="props.row.src" />
                </p>
              </figure>
            </div>
          </div>
          <div v-else>
            <p>No content</p>
          </div>
          <div class="media-content pl=2">
            <div class="content pl-4">
              <p>
                {{ props.row.note }}
              </p>
            </div>
          </div>
        </article>
      </template>
    </b-table>
    <vue-easy-lightbox
      :visible="visible"
      :imgs="imgs"
      :index="0"
      @hide="handleHide"
    >
    </vue-easy-lightbox>
  </div>
</template>

<script>
import axios from 'axios';
import { workOrderService } from '../services/WorkOrderService';
import WorkOrderFormVue from './WorkOrderForm.vue';

export default {
  data() {
    return {
      workOrders: [],
      type: '',
      dates: [],
      resolvedDates: [],
      radio: 'Flint',
      status: '',
      limit: 100,
      imgs: '',
      visible: false,
    };
  },
  methods: {
    addWorkOrder() {
      this.$buefy.modal.open({
        parent: this,
        component: WorkOrderFormVue,
        hasModalCard: true,
        trapFocus: true,
        props: {},
        events: {},
      });
    },
    showSingle(imgSrc) {
      this.imgs = imgSrc;
      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    async getInformation() {
      if (this.dates.length > 0 && this.resolvedDates.length > 0) {
        console.log('1');
        await workOrderService
          .filterWorkOrders(this.limit, this.type, this.dates[0], this.dates[1])
          .then((res) => {
            this.workOrders = res.data;
          });
      } else if (
        this.dates.length > 0 &&
        this.resolvedDates.length <= 0 &&
        !!this.type
      ) {
        console.log('t');
        await workOrderService
          .filterWorkOrders(this.limit, this.type, this.dates[0], this.dates[1])
          .then((res) => {
            this.workOrders = res.data;
          });
      } else if (this.resolvedDates.length > 0 && this.dates.length <= 0) {
        console.log('3');
        await workOrderService
          .filterWorkOrdersResolve(
            this.limit,
            this.type,
            this.resolvedDates[0],
            this.resolvedDates[1]
          )
          .then((data) => {
            this.workOrders = data;
          });
      } else {
        await workOrderService
          .getWorkOrderByType(this.limit, this.type)
          .then((data) => {
            this.workOrders = data;
          });
      }
    },
  },
  async created() {
    await workOrderService
      .getWorkOrderByType(this.limit, this.type)
      .then((res) => {
        this.workOrders = res.data;
      });
  },
};
</script>

<style></style>
