<template>
  <div class="container">
    <section class="section">
      <div class="columns mb-6">
        <div class="column">
          <h1 class="title has-text-centered">Tiger Teams Progress Report</h1>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column">
          <b-field grouped>
            <b-field label="Select start date">
              <b-datepicker placeholder="From" v-model="startDate">
              </b-datepicker>
            </b-field>
            <b-field label="Select end date">
              <b-datepicker placeholder="From" v-model="endDate">
              </b-datepicker>
              <b-button
                class="is-primary"
                @click="getSupervisorScans()"
                :loading="isLoading"
                :disabled="isLoading"
                >Search</b-button
              >
              <!-- <b-button
                @click="getCsvData()"
                :loading="isLoading"
                :disabled="isLoading"
                >CSV</b-button
              > -->
            </b-field>
          </b-field>
          <b-table :data="displayData" :loading="isLoading">
            <b-table-column
              field="supervisor"
              label="Supervisor"
              v-slot="props"
              sortable
            >
              {{ props.row.supervisor }}
            </b-table-column>
            <b-table-column
              field="sqFtMod"
              label="Factored SF"
              v-slot="props"
              sortable
            >
              {{ props.row.sqFtMod }}
            </b-table-column>
            <b-table-column
              field="fabrication"
              label="Factored Fabrication SF"
              v-slot="props"
              sortable
            >
              {{ Math.abs(props.row.fabrication) }}
            </b-table-column>
            <b-table-column
              field="hang"
              label="Factored Hang SF"
              v-slot="props"
              sortable
            >
              {{ props.row.hang }}
            </b-table-column>
            <b-table-column
              field="fabPercent"
              label="Fabrication %"
              v-slot="props"
              sortable
            >
              {{ props.row.fabPercent }}
            </b-table-column>
            <b-table-column
              field="hangPercent"
              label="Hang %"
              v-slot="props"
              sortable
            >
              {{ props.row.hangPercent }}
            </b-table-column>
            <b-table-column
              field="totalPercent"
              label="Total Bonus %"
              v-slot="props"
              sortable
            >
              <p
                class="has-text-weight-bold"
                :class="{
                  'has-text-warning': props.row.totalPercent < 20,
                  'has-text-success': props.row.totalPercent >= 80,
                }"
              >
                {{ props.row.totalPercent }}
              </p>
            </b-table-column>
          </b-table>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <apex-chart
            type="bar"
            :options="chartOptions"
            :series="series"
          ></apex-chart>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import { scannerService } from '../services/ScannerService';
export default {
  data() {
    return {
      supervisors: [
        'ACOSTA-BRAU',
        'SERRES-ANTHONY',
        'DURAN-ALEJANDRO',
        'RICO-ROGELIO',
        'CAUDILLO-DARIO',
        'VILLAFANA-EFREN',
        'GUZMAN-BLANCA',
      ],
      startDate: new Date(),
      endDate: new Date(),
      displayData: [],
      series: [],
      scanData: [],
      chartOptions: {},
      sqFtUnmodified: 0,
      sqFtModified: 0,
      hang: 0,
      isLoading: false,
    };
  },
  methods: {
    returnDate(date) {
      return new Date(date).toISOString().slice(0, 10);
    },
    getPValue(sqFt, unitCost, profile) {
      if (unitCost / sqFt / profile < 1) {
        return 1;
      } else {
        return unitCost / sqFt / profile;
      }
    },
    getModifiedProduction(obj) {
      const stepDone = obj.step.name.replace(' ', '_').toLowerCase();
      if (!!obj.barcode) {
        if (
          !isNaN(
            (obj.barcode.unitCost /
              obj.barcode.sqFt /
              obj.barcode.finish.profileCost) *
              (obj.barcode.sqFt * obj.barcode.finish[stepDone])
          )
        ) {
          return (
            this.getPValue(
              obj.barcode.sqFt,
              obj.barcode.unitCost,
              obj.barcode.finish.profileCost
            ) *
            (obj.barcode.sqFt * obj.barcode.finish[stepDone])
          );
        } else {
          return 0;
        }
      } else {
        console.log(obj);
        return this.getPValue(1, 1, 1);
      }
    },
    getHang(obj) {
      if (
        (obj.step.name === 'Hang' || obj.step.name === 'HANG') &&
        obj.barcode.swing != 'F'
      ) {
        return parseFloat(
          (obj.barcode.unitCost /
            obj.barcode.sqFt /
            obj.barcode.finish.profileCost) *
            obj.barcode.sqFt *
            obj.barcode.finish.hang
        );
      } else {
        return 0;
      }
    },
    sortAlphabetical(objArray) {
      objArray.sort(function (a, b) {
        var textA = a.DepartmentName.toUpperCase();
        var textB = b.DepartmentName.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    },

    checkForZero(sqFtMod, Hang) {
      if ((((sqFtMod - Hang) / 400) * 100).toFixed(2) < 0) {
        return 0;
      } else {
        return (((sqFtMod - Hang) / 400) * 100).toFixed(2);
      }
    },
    async doSomethingWithFile(file) {
      let unModFt = 0;
      let modFt = 0;
      let hang = 0;

      const reducer = function (acc, obj) {
        if (!!obj.barcode) {
          return parseFloat(acc) + parseFloat(obj.barcode.sqFt);
        } else {
          console.log(obj);
          return 1;
        }
      };
      unModFt = file.reduce(reducer, 0);

      for (let i = 0; i < file.length; i++) {
        modFt += this.getModifiedProduction(file[i]);
        hang += this.getHang(file[i]);
      }
      if (file[0].supervisor.name === 'GUZMAN-BLANCA') {
        return {
          unModFt: unModFt,
          supervisor: 'GLAZING',
          sqFtUnMod: unModFt.toFixed(2),
          sqFtMod: modFt.toFixed(2),
          fabrication: (modFt - hang).toFixed(2),
          hang: hang.toFixed(2),
          totalPercent: ((modFt / 1300) * 100).toFixed(2),
          fabPercent: 0,
          hangPercent: 0,
        };
      }

      return {
        unModFt: unModFt,
        supervisor: file[0].supervisor.name,
        sqFtUnMod: unModFt.toFixed(2),
        sqFtMod: modFt.toFixed(2),
        fabrication: (modFt - hang).toFixed(2),
        hang: hang.toFixed(2),
        totalPercent: (((modFt - hang) / 400 + hang / 225) * 100).toFixed(2),
        fabPercent: this.checkForZero(modFt, hang),
        hangPercent: ((hang / 225) * 100).toFixed(2),
      };
    },
    async getSupervisorScans() {
      this.displayData = [];
      this.isLoading = true;
      //Reset the total scans between calls to function
      this.displayData.splice(0, this.scanData.length);
      this.scanData.splice(0, this.scanData.length);
      this.sqFtModified = 0;
      this.sqFtUnmodified = 0;

      const holder = [];
      const seriesdata = [
        {
          data: [],
          xaxis: {
            type: 'category',
          },
        },
      ];
      for (let j = 0; j < this.supervisors.length; j++) {
        //Ensure these values are reset between counts
        this.sqFtModified = 0;
        this.sqFtUnmodified = 0;
        this.hang = 0;
        this.scanData.splice(0, this.scanData.length);

        //Call API and return the array of objects for calculation
        const files = await scannerService.getSupervisorScans(
          this.supervisors[j],
          this.returnDate(this.startDate),
          this.returnDate(this.endDate)
        );
        let modifiedFile = [];

        if (files != undefined && files.length != 0) {
          modifiedFile = await this.doSomethingWithFile(files);
        } else {
          modifiedFile = {
            unModFt: 0,
            supervisor: this.supervisors[j],
            sqFtUnMod: 0,
            sqFtMod: 0,
            fabrication: 0,
            hang: 0,
            totalPercent: 0,
            fabPercent: 0,
            hangPercent: 0,
          };
        }

        holder.push(modifiedFile);
        seriesdata[0]['data'].push({
          x: modifiedFile.supervisor,
          y: modifiedFile.totalPercent,
        });
      }
      this.displayData = holder;
      this.series = seriesdata;
      this.chartOptions = {
        chart: {
          id: 'vuechart-example',
        },
      };
      this.isLoading = false;
    },

    //csv function

    async getCsvData() {
      let csvArr = [];

      for (let j = 0; j < this.supervisors.length; j++) {
        //Call API and return the array of objects for calculation
        const files = await scannerService.getSupervisorScans(
          this.supervisors[j],
          this.returnDate(this.startDate),
          this.returnDate(this.endDate)
        );
        csvArr.push(files); //do the filtering in here
      }
      this.reduceCsv(csvArr);
    },
    // reduce array to csv specifications
    reduceCsv(csvArr) {
      let newArr = [];
      for (let i = 0; i < csvArr.length; i++) {
        for (let j = 0; j < csvArr[i].length; j++) {
          newArr.push({
            key: csvArr[i][j].key,
            supervisor: csvArr[i][j].supervisor.key,
            department: csvArr[i][j].department.key,
            step: csvArr[i][j].step.key,
            barcode: csvArr[i][j].barcode
              ? csvArr[i][j].barcode.barcode
              : 'NULL',
            status: csvArr[i][j].status,
            order: csvArr[i][j].step.order,
          });
        }
      }
      console.log(newArr);
      // const url = URL.createObjectURL(new Blob([JSON.stringify(newArr)]));
      // const link = document.createElement("a");
      // link.href = url;
      // let yourDate = new Date();
      // const offset = yourDate.getTimezoneOffset();
      // yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
      // const retdate = yourDate.toISOString().split("T")[0];
      // link.setAttribute("download", `Scans-${retdate}.csv`);
      // document.body.appendChild(link);
      // link.click();
    },
  },
};
</script>

<style></style>
