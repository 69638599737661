<template>
  <div
    class="tile is-6 box has-background-darkish has-text-light mx-2 status-container"
  >
    <i class="fas fa-edit special-button" @click="editable = !editable"></i>
    <div v-if="editable">
      <b-select placeholder="Select a Type" required v-model="status">
        <option value="Assigned">Assigned</option>
        <option value="Ordered">Ordered</option>
        <option value="Done">Done</option>
      </b-select>
      <b-button type="is-primary" expanded @click="submitChange"
        >Submit</b-button
      >
    </div>
    <div v-else>
      <p>Status: {{ workOrder.status }}</p>
    </div>
  </div>
</template>

<script>
import { glassOrderService } from '../services/GlassOrderService';

export default {
  data() {
    return {
      status: this.workOrder.status,
      editable: false,
      isLoading: false,
    };
  },
  props: ['workOrder'],
  methods: {
    submitChange() {
      this.isLoading = true;
      glassOrderService.update(this.$route.params.id, {
            status: this.status,
          })
        .then((res) => {
          console.log(res);
          this.$buefy.notification.open({
            message: 'Edit Successful',
            type: 'is-success',
            position: 'is-bottom',
            duration: 2000,
          });

          if (res.data && res.data.status) {
            this.workOrder = res.data;
          }
          this.isLoading = false;
          this.editable = false;
        })
        .catch((e) => {
          this.$buefy.notification.open({
            message: `${e}`,
            type: 'is-danger',
            position: 'is-bottom',
          });
          this.isLoading = false;
          this.editable = false;
        });
      this.isLoading = false;
      this.editable = false;
    },
  },
};
</script>

<style>
.status-container {
  position: relative;
}
.special-button {
  position: absolute;
  opacity: 0.5;
  margin-right: 1rem;
  margin-top: 1rem;
  top: 0;
  right: 0;
}
.special-button:hover {
  opacity: 1;
}
</style>
