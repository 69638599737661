<template>
  <div>
    <div v-if="editable">
      <div
        class="columns box has-background-darkish has-text-light info-container"
      >
        <div class="column has-text-left">
          <i
            class="fas fa-edit special-button"
            @click="editable = !editable"
          ></i>
          <ul class="mr-2">
            <li>Type: {{ initWorkOrderObject.type }}</li>
            <li>Date Ordered: {{ initWorkOrderObject.date_ordered }}</li>
            <li>Date To Action: {{ initWorkOrderObject.date_to_action }}</li>
          </ul>
        </div>
        <div class="column has-text-left">
          <ul class="mx-2">
            <li>Pane Count: {{ initWorkOrderObject.pane_count }}</li>
            <li>Vendor: {{ initWorkOrderObject.vendor }}</li>
            <li v-if="initWorkOrderObject.glass_makeup">
              Glass Makeup: {{ initWorkOrderObject.glass_makeup }}
            </li>
            <li v-else>
              Glass Makeup: <span class="has-text-danger">N/A</span>
            </li>
            <li>
              Delivery Location: {{ initWorkOrderObject.delivery_location }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="box has-background-darkish" v-else>
      <div class="columns info-container has-text-light">
        <i class="fas fa-edit special-button" @click="editable = !editable"></i>
        <div class="column has-text-left">
          <ul class="mx-2">
            <li>
              Type:
              <b-select
                placeholder="Select a Type"
                required
                v-model="initWorkOrderObject.type"
              >
                <option value="Warranty">Warranty</option>
                <option value="Replacement">Replacement</option>
              </b-select>
            </li>
            <li>
              Date Ordered:
              <b-datepicker
                v-model="initWorkOrderObject.date_ordered"
                :show-week-number="true"
                placeholder="Click to select..."
                icon="calendar-today"
                trap-focus
              >
              </b-datepicker>
            </li>
            <li>
              Date To Action:
              <b-datepicker
                v-model="initWorkOrderObject.date_to_action"
                :show-week-number="true"
                placeholder="Click to select..."
                icon="calendar-today"
                trap-focus
              >
              </b-datepicker>
            </li>
          </ul>
        </div>
        <div class="column has-text-left">
          <ul class="mx-2">
            <li>
              Pane Count:
              <b-numberinput
                type="is-success"
                v-model="initWorkOrderObject.pane_count"
              ></b-numberinput>
            </li>
            <li>
              Vendor: <b-input v-model="initWorkOrderObject.vendor"></b-input>
            </li>
            <li>
              Glass Makeup:
              <b-input v-model="initWorkOrderObject.glass_makeup"></b-input>
            </li>
            <li>
              Delivery Location:
              <b-input
                v-model="initWorkOrderObject.delivery_location"
              ></b-input>
            </li>
          </ul>
        </div>
      </div>
      <b-button
        class="is-primary"
        expanded
        @click="submitChange"
        :loading="isLoading"
        >Submit</b-button
      >
    </div>
  </div>
</template>

<script>
import { glassOrderService } from '../services/GlassOrderService';
export default {
  data() {
    return {
      isLoading: false,
      editable: true,
      workOrder: this.initWorkOrderObject,
    };
  },
  props: ['initWorkOrderObject'],
  methods: {
    submitChange() {
      this.isLoading = true;

      glassOrderService.update(this.$route.params.id, {
            type: this.initWorkOrderObject.type,
            pane_count: this.initWorkOrderObject.pane_count,
            vendor: this.initWorkOrderObject.vendor,
            glass_makeup: this.initWorkOrderObject.glass_makeup,
            delivery_location: this.initWorkOrderObject.delivery_location,
            date_to_action: this.initWorkOrderObject.date_to_action,
            date_ordered: this.initWorkOrderObject.date_ordered,
          })
        .then((res) => {
          console.log(res);
          this.$buefy.notification.open({
            message: 'Edit Successful',
            type: 'is-success',
            position: 'is-bottom',
            duration: 2000,
          });
          this.isLoading = false;
          this.editable = true;
        })
        .catch((e) => {
          this.$buefy.notification.open({
            message: `${e}`,
            type: 'is-danger',
            position: 'is-bottom',
          });
          this.isLoading = false;
          this.editable = true;
        });
      this.isLoading = false;
      this.editable = true;
    },
  },
  mounted() {
    if (!!this.initWorkOrderObject) {
      if (!!this.initWorkOrderObject.date_to_action) {
        this.workOrder.date_to_action = new Date(
          this.initWorkOrderObject.date_to_action
        );
      }
      if (!!this.initWorkOrderObject.date_ordered) {
        this.workOrder.date_ordered = new Date(
          this.initWorkOrderObject.date_ordered
        );
      }
    }
  },
};
</script>

<style>
.info-container {
  position: relative;
}
.special-button {
  position: absolute;
  opacity: 0.5;
  margin-right: 1rem;
  margin-top: 1rem;
  top: 0;
  right: 0;
}
.special-button:hover {
  opacity: 1;
}
</style>
