<template>
  <div class="container">
    <section class="section">
      <p class="title has-text-centered">Cycle Time By Product Type</p>
      <p class="subtitle has-text-centered has-text-weight-light">
        From Sash Fabrication to Glazing in Calendar Days
      </p>
      <div class="columns is-centered">
        <div class="column">
          <b-field grouped>
            <b-field label="Unit Type">
              <b-select
                placeholder="Select the type"
                v-model="unitType"
                required
              >
                <option value="Bifold">Bifold</option>
                <option value="Fixed">Fixed</option>
                <option value="Operable Window">Operable Window</option>
                <option value="Slider">Slider</option>
                <option value="Swing Door">Swing Door</option>
                <option value="Swing Door Pair">Swing Door Pair</option>
                <option value="Pivot Door">Pivot Door</option>
                <option value="Pivot Door Pair">Pivot Door Pair</option>
              </b-select>
            </b-field>
            <b-field label="Select start date">
              <b-datepicker placeholder="From" v-model="startDate">
              </b-datepicker>
            </b-field>
            <b-field label="Select end date">
              <b-datepicker placeholder="From" v-model="endDate">
              </b-datepicker>
              <b-button class="button is-primary" @click="getCycleTime()"
                >Search</b-button
              >
            </b-field>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column is-2 has-background-light">
          <b-switch
            v-model="isSwitchedCustom"
            true-value="bar"
            false-value="area"
            class="mb-4"
          >
            {{ isSwitchedCustom.toUpperCase() }}
          </b-switch>
          <p>
            x̅: <span class="has-text-weight-bold"> {{ average }} </span>
          </p>
          <p>
            N: <span class="has-text-weight-bold"> {{ scans }} </span>
          </p>
          <p>
            98th %:
            <span class="has-text-weight-bold">
              {{ ninetyeighth.Barcode }}
            </span>
          </p>
          <p>
            98th % Days:
            <span class="has-text-weight-bold">
              {{ ninetyeighth.Days_Expended }}
            </span>
          </p>
        </div>
        <div class="column">
          <apexchart
            :type="isSwitchedCustom"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </div>
    </section>
    <section class="section">
      <p class="is-size-1 has-text-centered">Scan Data</p>
      <div class="columns">
        <div class="column">
          <b-table :data="scanData" :paginated="true" :per-page="20">
            <b-table-column
              field="Barcode"
              label="Barcode"
              v-slot="props"
              searchable
            >
              {{ props.row.Barcode }}
            </b-table-column>
            <b-table-column
              field="Activity_Stop"
              label="Sash Start"
              v-slot="props"
              sortable
            >
              {{ new Date(props.row.Activity_Start) }}
            </b-table-column>
            <b-table-column
              field="Activity_Stop"
              label="Glaze End"
              v-slot="props"
              sortable
            >
              {{ new Date(props.row.Time_Glazed) }}
            </b-table-column>
            <b-table-column
              field="Finish_Value"
              label="Days Elapsed"
              v-slot="props"
            >
              {{ props.row.Days_Expended }}
            </b-table-column>
          </b-table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { scannerService } from '../services/ScannerService';
export default {
  data() {
    return {
      unitType: null,
      startDate: new Date(),
      endDate: new Date(),
      dates: [],
      chartType: 'bar',
      isSwitchedCustom: 'bar',
      scanData: [],
      chartOptions: {},
      series: [],
      average: 0,
      scans: 0,
      ninetyeighth: {},
    };
  },
  methods: {
    returnDate(date) {
      return new Date(date).toISOString().slice(0, 10);
    },
    findpercentile(arr) {
      if (arr.length === 1) {
        return 0;
      } else if (arr.length === 0) {
        throw new Error('Array cannot be empty');
      } else {
        const unit = Math.floor(arr.length * 0.98) - 1;
        return unit;
      }
    },
    changeChartType() {
      if (this.chartType === 'bar') {
        this.chartType = 'area';
      } else {
        this.chartType = 'bar';
      }
    },
    async getCycleTime() {
      await scannerService
        .filterCycleTime(
          this.unitType,
          this.returnDate(this.startDate),
          this.returnDate(this.endDate)
        )
        .then((data) => {
          this.scanData = data[0];
          console.log(data[1]);
          this.chartOptions = {
            stroke: {
              curve: 'smooth',
            },
            colors: ['#C9AE5D', '#E91E63'],
            chart: {
              id: 'vuechart-example',
            },
            xaxis: {
              type: 'numeric',
            },
          };
          this.series = [
            {
              data: data[1],
            },
          ];

          this.average = data[2];

          this.scans = data[0].length;

          const nthindex = this.findpercentile(data[0]);
          this.ninetyeighth = data[0][nthindex];
        });
    },
  },
};
</script>

<style></style>
