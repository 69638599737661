<template>
  <div class="columns box has-background-darkish note-container has-text-light">
    <i class="fas fa-edit special-button" @click="editable = !editable"></i>
    <div class="column">
      <div v-if="!editable">
        <div class="columns">
          <div class="column">
            <b-input
              type="textarea"
              class="my-textarea"
              v-model="newText"
            ></b-input>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-button
              type="is-primary"
              expanded
              :loading="isLoading"
              @click="submitChange"
              >Submit</b-button
            >
          </div>
        </div>
      </div>

      <div v-else>
        <div v-if="!!initWorkOrder.special_notes === false">
          <p class="has-text-centered has-text-light">No Notes</p>
        </div>
        <div v-else>
          {{ newText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { glassOrderService } from '../services/GlassOrderService';
export default {
  data() {
    return {
      editable: true,
      isLoading: false,
      newText: this.initWorkOrder.special_notes,
    };
  },
  props: ['initWorkOrder'],
  methods: {
    submitChange() {
      this.isLoading = true;
      
      glassOrderService.update(his.$route.params.id, {
            special_notes: this.newText,
          })
        .then((res) => {
          console.log(res);
          this.$buefy.notification.open({
            message: 'Edit Successful',
            type: 'is-success',
            position: 'is-bottom',
            duration: 2000,
          });

          if (!!res.data && !!res.data.special_notes) {
            this.initWorkOrder = res.data;
          }
          this.isLoading = false;
          this.editable = true;
        })
        .catch((e) => {
          this.$buefy.notification.open({
            message: `${e}`,
            type: 'is-danger',
            position: 'is-bottom',
          });
          this.isLoading = false;
          this.editable = true;
        });
      this.isLoading = false;
      this.editable = true;
    },
  },
};
</script>

<style>
.wrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
}

.my-textarea {
  flex: 1;
}

.note-container {
  position: relative;
}

.special-button {
  position: absolute;
  opacity: 0.5;
  margin-right: 1rem;
  margin-top: 1rem;
  top: 0;
  right: 0;
}
.special-button:hover {
  opacity: 1;
}
</style>
