<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Response to User</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <!-- <b-field label="Name" horizontal>
                    <p>{{user.name}}</p>
                </b-field> -->
      </section>
      <footer class="modal-card-foot">
        <b-button class="is-primary" @click="sendResponse()">Send</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      user: this.userObj,
      isSending: false,
    };
  },
  props: ['messageObj'],
  methods: {
    async sendResponse() {
      axios
        .patch('https://rb-portal-backend.herokuapp.com/user/' + this.user.id, {
          name: this.user.name,
          role: this.user.role,
        })
        .then(() => {
          this.$emit('success-event');
          this.$emit('close');
        })
        .catch((e) => {
          this.$emit('failure-event', e);
        });
    },
  },
};
</script>
