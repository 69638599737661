<template>
  <div class="column box mr-2 has-text-centered has-background-warning">
    <div class="columns">
      <div class="column is-4 is-offset-4">
        <p class="is-size-4">{{ name }}</p>
      </div>
      <div class="column">
        <button class="button" @click="editable = !editable">
          <i class="fas fa-edit"></i>
        </button>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="container" v-if="editable">
          <b-upload v-model="newFile" drag-drop>
            <section class="section">
              <div class="content has-text-centered">
                <p>
                  <b-icon icon="upload" size="is-large"> </b-icon>
                </p>
                <p>Drop your file</p>
              </div>
            </section>
          </b-upload>
          <p v-if="!!newFile">{{ newFile.name }}</p>
          <b-button expanded class="mt-2" @click="confirmUpdate()"
            >Submit</b-button
          >
        </div>
        <div v-else class="container">
          <section class="section">
            <div class="content has-text-centered">
              <div v-if="!!orderSrc">
                <p>
                  <a :href="orderSrc"
                    ><b-icon icon="link" size="is-large"> </b-icon
                  ></a>
                </p>
                <p>Click to view</p>
              </div>
              <div v-else>
                <p>
                  <b-icon icon="cancel" size="is-large" class="has-text-danger">
                  </b-icon>
                </p>
                <p>No Content</p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      editable: false,
      newFile: '',
    };
  },
  props: ['name', 'field', 'orderId', 'orderSrc'],
  methods: {
    confirmUpdate() {
      this.$buefy.dialog.confirm({
        title: 'Privacy Politics',
        message: `Are you sure you want to update this file? Doing so will remove any existing file(s) present.`,
        cancelText: 'Disagree',
        confirmText: 'Agree',
        type: 'is-success',
        onConfirm: () => {
          let formData = new FormData();
          formData.append(this.field, this.newFile);
          console.log(formData);
          this.$axios
            .put(
              'https://rb-portal-backend.herokuapp.com/glass-work-order/' +
                this.orderId +
                '/edit-files',
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
            )
            .then((res) => {
              this.$buefy.notification.open({
                message: 'Succesfully updated file',
                type: 'is-success',
                position: 'is-bottom',
                duration: 2000,
              });
            });
        },
      });
    },
    confirmRemove() {
      this.$buefy.dialog.confirm({
        title: 'Privacy Politics',
        message: `Are you sure you want to update this file? Doing so will remove any existing file(s) present.`,
        cancelText: 'Disagree',
        confirmText: 'Agree',
        type: 'is-success',
        onConfirm: () => console.log('hi'),
      });
    },
  },
};
</script>

<style></style>
