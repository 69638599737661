<template>
  <div>
    <section class="section">
      <div class="columns">
        <div class="column has-background-light">
          <div class="container">
            <h1 class="is-size-1 has-text-centered">Glass Work Order</h1>
            <b-field>
              <template #label
                >Unit Barcode(s)
                <b-tooltip type="is-info" label="This field is required">
                  <b-icon size="is-small" icon="help-circle-outline"></b-icon>
                </b-tooltip>
              </template>
              <b-taginput
                v-model="tags"
                :data="barcodeList"
                autocomplete
                :allow-new="false"
                :open-on-focus="true"
                field="barcode"
                icon="label"
                placeholder="Start typing a barcode"
                @typing="getFilteredBarcodes"
              >
              </b-taginput>
            </b-field>
            <b-field label="Priority">
              <b-select
                placeholder="Select the priority level"
                v-model="workOrder.priority"
              >
                <option value="Standard">Standard</option>
                <option value="Hot">Hot</option>
              </b-select>
            </b-field>
            <b-field label="Category">
              <b-select
                placeholder="Select a category"
                v-model="workOrder.category"
              >
                <option value="Warranty">Warranty</option>
                <option value="Replacement">Replacement</option>
              </b-select>
            </b-field>
            <b-field label="Panes">
              <b-numberinput
                controls-alignment="left"
                controls-position="compact"
                :min="0"
                :max="10000"
                v-model="workOrder.panes"
              ></b-numberinput>
            </b-field>
            <b-field label="Delivery Location">
              <b-input
                placeholder="Factory"
                v-model="workOrder.deliveryLocation"
              ></b-input>
            </b-field>
            <b-field grouped group-multiline>
              <b-field label="Quote">
                <b-field
                  class="file is-primary"
                  :class="{ 'has-name': !!workOrder.quote }"
                >
                  <b-upload
                    v-model="workOrder.quote"
                    class="file-label"
                    rounded
                  >
                    <span class="file-cta">
                      <b-icon class="file-icon" icon="upload"></b-icon>
                      <span class="file-label">Click to upload</span>
                    </span>
                    <span class="file-name" v-if="workOrder.quote">
                      {{ workOrder.quote.name }}
                    </span>
                  </b-upload>
                </b-field>
              </b-field>
              <b-field label="Packing List">
                <b-field
                  class="file is-primary"
                  :class="{ 'has-name': !!workOrder.packing_list }"
                >
                  <b-upload
                    v-model="workOrder.packing_list"
                    class="file-label"
                    rounded
                  >
                    <span class="file-cta">
                      <b-icon class="file-icon" icon="upload"></b-icon>
                      <span class="file-label">Click to upload</span>
                    </span>
                    <span class="file-name" v-if="workOrder.packing_list">
                      {{ workOrder.packing_list.name }}
                    </span>
                  </b-upload>
                </b-field>
              </b-field>
              <b-field label="Invoice">
                <b-field
                  class="file is-primary"
                  :class="{ 'has-name': !!workOrder.invoice }"
                >
                  <b-upload
                    v-model="workOrder.invoice"
                    class="file-label"
                    rounded
                  >
                    <span class="file-cta">
                      <b-icon class="file-icon" icon="upload"></b-icon>
                      <span class="file-label">Click to upload</span>
                    </span>
                    <span class="file-name" v-if="workOrder.invoice">
                      {{ workOrder.invoice.name }}
                    </span>
                  </b-upload>
                </b-field>
              </b-field>
              <b-field label="Delivery Note">
                <b-field
                  class="file is-primary"
                  :class="{ 'has-name': !!workOrder.markUps }"
                >
                  <b-upload
                    v-model="workOrder.delivery_note"
                    class="file-label"
                    rounded
                  >
                    <span class="file-cta">
                      <b-icon class="file-icon" icon="upload"></b-icon>
                      <span class="file-label">Click to upload</span>
                    </span>
                    <span class="file-name" v-if="workOrder.markUps">
                      {{ workOrder.markUps.name }}
                    </span>
                  </b-upload>
                </b-field>
              </b-field>
              <b-field label="Mark Ups">
                <b-field
                  class="file is-primary"
                  :class="{ 'has-name': !!workOrder.markUps }"
                >
                  <b-upload
                    v-model="workOrder.markUps"
                    class="file-label"
                    rounded
                  >
                    <span class="file-cta">
                      <b-icon class="file-icon" icon="upload"></b-icon>
                      <span class="file-label">Click to upload</span>
                    </span>
                    <span class="file-name" v-if="workOrder.markUps">
                      {{ workOrder.markUps.name }}
                    </span>
                  </b-upload>
                </b-field>
              </b-field>
            </b-field>
            <b-field label="Photos" class="my-5">
              <UploadImages
                :max="5"
                @changed="handleImages"
                maxError="Max files exceed"
                fileError="Only image files are accepted"
              />
            </b-field>

            <b-button
              expanded
              class="is-primary my-4 is-large"
              :disabled="canSubmit"
              @click="sendRequest()"
              >Submit</b-button
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import UploadImages from 'vue-upload-drop-images';
import { sourceService } from '../services/SourceService';
export default {
  components: {
    UploadImages,
  },
  data() {
    return {
      tags: [],
      barcodeList: [],
      photos: [],
      workOrder: {
        category: null,
        panes: 0,
        priority: null,
        deliveryLocation: null,
        quote: null,
        packing_list: null,
        invoice: null,
        delivery_note: null,
        markUps: null,
      },
    };
  },
  computed: {
    canSubmit() {
      return this.tags.length > 0 ? false : true;
    },
  },
  methods: {
    handleImages(files) {
      this.photos = files;
    },
    deleteDropFile(index) {
      this.photos.splice(index, 1);
    },
    sendRequest() {
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.workOrder)) {
        if (!!value || (key === 'panes' && value === 0)) {
          formData.append(key, this.workOrder[`${key}`]);
        }
      }

      for (var i = 0; i < this.tags.length; i++) {
        formData.append('arr[]', JSON.stringify(this.tags[i]));
      }
      for (var i = 0; i < this.photos.length; i++) {
        formData.append('photos', this.photos[i]);
      }

      this.$axios
        .post(
          'https://rb-portal-backend.herokuapp.com/glass-work-order',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        .then((res) => {
          if (this.photos.length > 0 && !!res.data && !!res.data.id) {
            let photoFormData = new FormData();
            for (var i = 0; i < this.photos.length; i++) {
              photoFormData.append('photos', this.photos[i]);
            }

            this.$axios
              .put(
                `https://rb-portal-backend.herokuapp.com/glass-work-order/${res.data.id}/add-photos`,
                photoFormData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                }
              )
              .then((res) => {
                this.$buefy.notification.open({
                  message: 'Succesfully created glass order',
                  type: 'is-success',
                  position: 'is-bottom',
                  duration: 2000,
                });

                this.$router.push({
                  name: 'GlassOrderTable',
                });
              });
          } else if (!!res.data && !!res.data.id) {
            this.$buefy.notification.open({
              message: 'Succesfully created glass order',
              type: 'is-success',
              position: 'is-bottom',
              duration: 2000,
            });

            this.$router.push({
              name: 'GlassOrderTable',
            });
          } else {
            this.$buefy.notification.open({
              message: 'Error, failed to create work order. Contact support.',
              type: 'is-danger',
              position: 'is-bottom',
              duration: 2000,
            });
          }
        });
    },
    getFilteredBarcodes(value) {
      console.log(value);
      sourceService.getSourceTerm(value).then((data) => {
        console.log(data);
        this.barcodeList = data;
      });
    },
  },
  created() {
    sourceService.getSourceTerm('').then((data) => {
      this.barcodeList = data;
    });
  },
};
</script>

<style scoped>
.is-top {
  min-height: 0px;
}
</style>
