<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered">Deactivate User</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body has-text-centered">
        <p class="is-size-5 mb-2">Are you sure?</p>
        <button class="button is-success is-medium mx-1" @click="updateUser()">
          Yes
        </button>
        <button class="button is-danger is-medium mx-1" @click="$emit('close')">
          No
        </button>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { authService } from '../services/AuthService';
export default {
  data() {
    return {
      user: this.userObj,
      isSending: false,
    };
  },
  props: ['userObj'],
  methods: {
    async updateUser() {
      this.isSending = true;
      try {
        const res = await authService.deactivate(this.userObj.id);
        console.log(res);
      } catch (e) {
        this.$emit('failure-event', e);
      }
      this.isSending = false;
    },
  },
};
</script>
