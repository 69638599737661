<template>
  <div>
    <section class="section">
      <div class="columns">
        <div class="column">
          <div class="container">
            <h2 class="title is-3">Add new user</h2>
            <form v-if="!isLoadingData" class="box">
              <div class="field">
                <label for="" class="label">Username</label>
                <div class="control has-icons-left">
                  <input
                    type="email"
                    class="input"
                    v-model="username"
                    minlength="2"
                    required
                  />
                  <span class="icon is-small is-left">
                    <i class="fa fa-envelope"></i>
                  </span>
                </div>
              </div>
              <div class="field">
                <label for="" class="label">Password</label>
                <div class="control has-icons-left">
                  <input
                    type="password"
                    class="input"
                    v-model="password"
                    minlength="6"
                    required
                  />
                  <span class="icon is-small is-left">
                    <i class="fa fa-lock"></i>
                  </span>
                </div>
              </div>

              <b-field v-if="selectedRole !== 'ADMIN'" label="Department">
                <b-select
                  placeholder="Select a department"
                  v-model="selectedDepartment"
                >
                  <option
                    v-for="(option, index) in departments"
                    :value="option.key"
                    :key="index"
                  >
                    {{ option.department }}
                  </option>
                </b-select>
              </b-field>

              <b-field v-if="selectedRole !== 'ADMIN'" label="Team">
                <b-select placeholder="Select a team" v-model="selectedTeam">
                  <option
                    v-for="(option, index) in teams"
                    :value="option.key"
                    :key="index"
                  >
                    {{ option.team }}
                  </option>
                </b-select>
              </b-field>

              <b-field v-if="selectedRole !== 'ADMIN'" label="Manager">
                <b-select
                  placeholder="Select a manager"
                  v-model="selectedManager"
                >
                  <option
                    v-for="(option, index) in managers"
                    :value="option.key"
                    :key="index"
                  >
                    {{ option.name }}
                  </option>
                </b-select>
              </b-field>

              <b-field
                v-if="selectedRole !== 'ADMIN' && selectedRole !== 'Supervisor'"
                grouped
                label="Supervisor"
              >
                <b-select placeholder="Select a name" v-model="selectedSuper">
                  <option
                    v-for="(option, index) in supervisors"
                    :value="option.key"
                    :key="index"
                  >
                    {{ option.name }}
                  </option>
                </b-select>
              </b-field>

              <b-field grouped label="Role">
                <b-select placeholder="Select a role" v-model="selectedRole">
                  <option
                    v-for="(option, index) in roles"
                    :value="option.key"
                    :key="index"
                  >
                    {{ option.name }}
                  </option>
                </b-select>
              </b-field>

              <div class="field">
                <button
                  class="button is-success"
                  :class="{ 'is-loading': isLoading }"
                  @click.prevent="addUser"
                  :disabled="isLoading"
                >
                  Add User
                </button>
              </div>
            </form>
            <div v-else>
              <p>Setting up form..</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { projectService } from '../../services/ProjectService';
import { rbUserService } from '../../services/RbUserService';

export default {
  name: 'AddUser',
  components: {},
  data() {
    return {
      supervisors: [],
      roles: [
        { key: 'CAD', name: 'Cad' },
        { key: 'ADMIN', name: 'Admin' },
        { key: 'Worker', name: 'Worker' },
        { key: 'Supervisor', name: 'Supervisor' },
      ],
      departments: [],
      teams: [],
      managers: [],

      username: '',
      password: '',
      selectedSuper: 'U0052',
      selectedRole: 'CAD',
      selectedDepartment: 'D00011',
      selectedTeam: 'CAD',
      selectedManager: 'U0052',

      error: null,
      isLoading: false,
      isLoadingData: true,
    };
  },
  methods: {
    async addUser() {
      this.isLoading = true;
      try {
        const response = await rbUserService.addUser({
          username: this.username,
          password: this.password,
          supervisor: this.selectedSuper,
          department: this.selectedDepartment,
          role: this.selectedRole,
          manager: this.selectedManager,
          team: this.selectedTeam,
        });

        this.$buefy.notification.open({
          message: 'Successfully added new user',
          type: 'is-success',
          position: 'is-bottom',
          duration: 2000,
        });

        // Reset form data
        this.username = '';
        this.password = '';
      } catch (error) {
        console.error(error);
        this.$buefy.notification.open({
          message: error.response.data.message || 'Failed to add user',
          type: 'is-danger',
          position: 'is-bottom',
          duration: 2000,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
  async beforeMount() {
    const departments = await projectService.listDepartments();
    // const supervisors = await rbUserService.listSupervisors();
    const teams = await rbUserService.listTeams();
    const users = await rbUserService.listUsers();

    // console.log(users);

    this.departments = departments;
    this.supervisors = users.filter(
      (user) => user.role !== 'Worker' && user.status === 'ACTIVE'
    );
    this.managers = this.supervisors;
    this.teams = teams;

    this.isLoadingData = false;
  },
};
</script>

<style></style>
