<template>
  <section class="section is-medium">
    <div class="tile box has-background-grey">
      <b-table
        :data="workOrders"
        :paginated="true"
        :per-page="20"
        current-page.sync="1"
        :bordered="false"
        striped
        :show-header="true"
        detailed
        detail-key="id"
        detail-transition="fade"
        :show-detail-icon="true"
      >
        <b-table-column
          field="source.project.name"
          label="Project"
          v-slot="props"
          searchable
          sortable
        >
          {{ props.row.source.project.name }}
        </b-table-column>
        <b-table-column
          field="source.barcode"
          label="Unit"
          v-slot="props"
          searchable
          sortable
        >
          {{ props.row.source.barcode }}
        </b-table-column>
        <b-table-column field="type" label="Type" v-slot="props">
          {{ props.row.type.name }}
        </b-table-column>
        <b-table-column
          field="date_submitted"
          label="Date Submitted"
          v-slot="props"
        >
          <span class="tag is-success">
            {{ new Date(props.row.date_submitted).toLocaleDateString() }}
          </span>
        </b-table-column>

        <b-table-column
          field="date_resolved"
          label="Date Resolved"
          v-slot="props"
        >
          <span
            v-if="typeof props.date_resolved === 'date'"
            class="tag is-success"
          >
            {{ new Date(props.row.date_submitted).toLocaleDateString() }}
          </span>
          <span v-else class="tag is-danger">UNRESOLVED</span>
        </b-table-column>
        <b-table-column field="issued_by" label="Issuer" v-slot="props">
          {{ props.row.issued_by.name }}
        </b-table-column>
        <b-table-column
          field="resolved"
          label="Resolution Status"
          centered
          searchable
        >
          <template slot="searchable" slot-scope="props">
            <b-select
              placeholder="All"
              v-model="props.filters[props.column.resolved]"
            >
              <option :value="true">Resolved</option>
              <option :value="false">Unresolved</option>
            </b-select>
          </template>

          <template slot-scope="props">
            <i
              class="is-size-5 has-text-centered"
              :class="
                props.row.resolved === true
                  ? 'fas fa-check-square has-text-success'
                  : 'fas fa-minus-circle has-text-danger'
              "
            ></i>
          </template>
        </b-table-column>
        <b-table-column field="issued_by.name" label="Issuer" searchable>
          <template v-slot="props">
            {{ props.row.issued_by.name }}
          </template>
        </b-table-column>
        <b-table-column
          field="issued_to"
          label="Issued To"
          v-slot="props"
          searchable
          sortable
        >
          {{ props.row.issued_to.name }}
        </b-table-column>
        <template #detail="props">
          <article class="media">
            <figure class="media-left" @click="showSingle(props.row.src)">
              <p class="image is-64x64">
                <img :src="props.row.src" />
              </p>
            </figure>
            <div class="media-content">
              <div class="content">
                <p>
                  {{ props.row.note }}
                </p>
              </div>
            </div>
          </article>
        </template>
      </b-table>
      <vue-easy-lightbox
        :visible="visible"
        :imgs="imgs"
        :index="0"
        @hide="handleHide"
      >
      </vue-easy-lightbox>
    </div>
  </section>
</template>

<script>
import { workOrderService } from '../services/WorkOrderService';
import WorkOrderFormVue from './WorkOrderForm.vue';

export default {
  data() {
    return {
      workOrders: [],
      type: '',
      dates: [],
      resolvedDates: [],
      radio: 'Flint',
      status: '',
      limit: 100,
      imgs: '',
      visible: false,
    };
  },
  methods: {
    addWorkOrder() {
      this.$buefy.modal.open({
        parent: this,
        component: WorkOrderFormVue,
        hasModalCard: true,
        trapFocus: true,
        props: {},
        events: {},
      });
    },
    showSingle(imgSrc) {
      this.imgs = imgSrc;
      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },
  async created() {
    await workOrderService
      .getWorkOrderTypes(this.limit, this.type)
      .then((data) => {
        this.workOrders = data;
      });
  },
};
</script>

<style></style>
