import { _axios } from '../plugins/axios';
class GlassOrderService {
  constructor() {
    this.axios = _axios;
  }

  async update(orderId, payload) {
    try {
      const response = await this.axios.put(`/glass-work-order/${orderId}`, payload);
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async getGlassOrderById(orderId) {
    const response = await this.axios.get(`/glass-work-order/${orderId}`);
    return response.data;
  }

  async getAllGlassOrders() {
    const response = await this.axios.get(`/glass-work-order`);
    return response.data;
  }
}

export const glassOrderService = new GlassOrderService();
