<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Update User</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <b-field label="Name" horizontal>
          <p>{{ user.name }}</p>
        </b-field>
        <b-field label="Email" horizontal>
          <p>{{ user.email }}</p>
        </b-field>
        <b-field label="Phone" horizontal>
          <p>{{ user.phone_number }}</p>
        </b-field>
        <b-field label="Address" horizontal>
          <p>{{ user.address }}</p>
        </b-field>
        <b-field label="Role" horizontal>
          <b-select
            placeholder="Select a Riviera Bronze Partner"
            v-model="user.role"
            expanded
            required
          >
            <option value="CLIENT">CLIENT</option>
            <option value="ADMIN">ADMIN</option>
          </b-select>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button class="is-primary" @click="updateUser()">Send</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      user: this.userObj,
      isSending: false,
    };
  },
  props: ['userObj'],
  methods: {
    async updateUser() {
      axios
        .patch('https://rb-portal-backend.herokuapp.com/user/' + this.user.id, {
          name: this.user.name,
          role: this.user.role,
        })
        .then(() => {
          this.$emit('success-event');
          this.$emit('close');
        })
        .catch((e) => {
          this.$emit('failure-event', e);
        });
    },
  },
};
</script>
