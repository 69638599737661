<template>
  <div class="container" style="position: relative">
    <div class="columns mb-0">
      <div class="column pb-0">
        <div class="columns mt-0">
          <div class="column pb-0">
            <div class="fancy">
              <section>
                <div class="box has-background-dark">
                  <b-field grouped label="Supervisor">
                    <b-select
                      placeholder="Select a name"
                      v-model="selectedSuper"
                      @input="getSupervisor"
                      message="Select a supervisor"
                    >
                      <option
                        v-for="(option, index) in supervisors"
                        @select="getSupervisor"
                        :value="option.key"
                        :key="index"
                      >
                        {{ option.name }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
              </section>
            </div>
          </div>
        </div>
        <section>
          <div v-if="isQC === false" style="position: relative">
            <b-loading :is-full-page="true" :can-cancel="true"></b-loading>
            <div v-for="(item, index) of paginatedItems" :key="index">
              <stepper
                :scanData="item"
                :supervisor="selectedSuper"
                department="D00001"
              />
            </div>
          </div>
          <div v-else style="position: relative">
            <b-loading :is-full-page="true" :can-cancel="true"></b-loading>
            <div v-for="(item, index) of paginatedItems" :key="index">
              <QCStepper
                :scanData="item"
                :supervisor="selectedSuper"
                department="D00002"
              >
              </QCStepper>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="columns">
      <div class="column pt-0 mb-2">
        <div class="fancy">
          <div class="box has-background-dark">
            <b-pagination
              :total="total"
              :current.sync="current"
              :per-page="perPage"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
    <b-loading
      :is-full-page="false"
      v-model="isLoading"
      :can-cancel="true"
    ></b-loading>
  </div>
</template>

<script>
import Stepper from '@/components/Stepper.vue';
import qcstepper from '@/components/QCStepper.vue';
import { rbUserService } from '../services/RbUserService';

export default {
  components: {
    Stepper,
    QCStepper: qcstepper,
  },
  data() {
    return {
      selectedSuper: 'U0002',
      storage: [],
      supervisors: [],
      isLoading: false,
      current: 1,
      perPage: 10,
      filterText: null,
      isQC: false,
    };
  },
  methods: {
    async getSupervisor(e) {
      console.log(e);
      try {
        await rbUserService.getSupervisor(this.selectedSuper).then((data) => {
          this.storage = data.sources;
        });
      } catch (e) {
        console.log(e);
      }

      if (this.selectedSuper === 'U0055') {
        this.isQC = true;
      } else {
        this.isQC = false;
      }
    },
  },
  async created() {
    this.isLoading = true;
    this.selectedSuper = 'U0002';
    try {
      await rbUserService.getSupervisor(this.selectedSuper).then((data) => {
        this.storage = data.sources;
      });

      await rbUserService.listSupervisors().then((data) => {
        this.supervisors = data;
      });
      this.isLoading = false;
    } catch (e) {
      console.log(e);
      this.isLoading = false;
    }
    this.isLoading = false;
  },
  computed: {
    filterUnitsByText() {
      return this.paginatedItems.filter((item) =>
        item.barcode.indexOf(this.filterText)
      );
    },
    total() {
      return this.storage.length;
    },
    paginatedItems() {
      let page_number = this.current - 1;
      return this.storage.slice(
        page_number * this.perPage,
        (page_number + 1) * this.perPage
      );
    },
  },
};
</script>

<style>
.has-background-dark .pagination-link {
  color: white;
}
.has-background-dark .label {
  color: white;
}
</style>
