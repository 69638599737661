<template>
  <div>
    <section class="is-tall">
      <p class="has-text-centered is-size-1 p-2">CAD Dashboard</p>
      <div class="container">
        <article class="panel panel-default is-info">
          <div class="panel-heading panel-relative">
            <p class="">Projects</p>
            <div class="buttons button-right">
              <button
                class="button is-success"
                @click="saveChanges()"
                :loading="isSending"
                :disabled="isSending"
              >
                <span class="icon">
                  <i class="far fa-save"></i>
                </span>
                <span>Save Changes </span>
              </button>
              <button
                class="button is-dark"
                @click="saveApprovalChanges()"
                :loading="isSending"
                :disabled="isSending"
              >
                <span class="icon">
                  <i class="fas fa-check-square"></i>
                </span>
                <span>Save Approvals </span>
              </button>
              <button
                class="button is-danger"
                @click="checkedRows = []"
                :loading="isSending"
                :disabled="isSending"
              >
                <span class="icon">
                  <i class="fas fa-undo"></i>
                </span>
                <span>Undo Selection</span>
              </button>
            </div>
          </div>
          <b-collapse
            class="card"
            animation="slide"
            v-for="(collapse, index) of myData"
            :key="index"
            :open="isOpen == index"
            @open="isOpen = index"
          >
            <template #trigger="props">
              <div class="card-header has-background-light" role="button">
                <p class="card-header-title">
                  {{ collapse.key }}
                </p>
                <a class="card-header-icon">
                  <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                  </b-icon>
                </a>
              </div>
            </template>
            <div class="card-content">
              <div class="content">
                <section>
                  <div class="container">
                    <p></p>
                    <b-table
                      :data="collapse.sources"
                      :checked-rows.sync="checkedRows"
                      :is-row-checkable="
                        (row) =>
                          row.scans.length === 0 ||
                          Number(row.scans[0].step.order) < 3
                      "
                      checkable
                      striped
                      checkbox-position="left"
                    >
                      <b-table-column
                        field="barcode"
                        label="Barcode"
                        v-slot="props"
                      >
                        {{ props.row.barcode }}
                      </b-table-column>
                      <b-table-column
                        field="location"
                        label="Location"
                        v-slot="props"
                      >
                        {{ props.row.location }}
                      </b-table-column>
                      <b-table-column
                        field="description"
                        label="Description"
                        v-slot="props"
                      >
                        {{ props.row.description }}
                      </b-table-column>
                      <b-table-column field="type" label="Type" v-slot="props">
                        {{ props.row.type }}
                      </b-table-column>
                      <b-table-column
                        field="swing"
                        label="Swing"
                        v-slot="props"
                      >
                        {{ props.row.swing }}
                      </b-table-column>
                      <b-table-column
                        field="finish.unitType"
                        label="Finish"
                        v-slot="props"
                      >
                        {{ props.row.finish.unitType }}
                      </b-table-column>
                      <b-table-column
                        field="sqFt"
                        label="Square Feet"
                        v-slot="props"
                      >
                        {{ props.row.sqFt }}
                      </b-table-column>
                      <b-table-column
                        field="approved"
                        label="Approved"
                        v-slot="props"
                      >
                        <b-checkbox v-model="props.row.approved"> </b-checkbox>
                      </b-table-column>
                      <b-table-column
                        field="scans"
                        label="Last Scan"
                        v-slot="props"
                      >
                        <b-tag
                          type="is-primary"
                          size="is-medium"
                          v-if="props.row.scans.length === 0"
                          >No Last Scan</b-tag
                        >
                        <b-tag
                          :type="
                            props.row.scans[0].step.name === 'Prelim'
                              ? 'is-success'
                              : props.row.scans[0].step.name === 'Revision'
                              ? 'is-warning'
                              : props.row.scans[0].step.name === 'Production'
                              ? 'is-danger'
                              : 'is-info'
                          "
                          size="is-medium"
                          v-else-if="props.row.scans[0]"
                          >{{ props.row.scans[0].step.name }}</b-tag
                        >
                        <b-tag v-else>Error</b-tag>
                      </b-table-column>
                      <!-- <template #bottom-left>
                                </template> -->
                      <template #empty>
                        <div class="has-text-centered">No records</div>
                      </template>
                    </b-table>
                  </div>
                </section>
              </div>
            </div>
          </b-collapse>
        </article>
      </div>
    </section>
  </div>
</template>

<script>
import { scannerService } from '../services/ScannerService';
import { sourceService } from '../services/SourceService';
import { projectService } from '../services/ProjectService';

export default {
  data() {
    return {
      myData: [],
      originalData: [],
      checkboxPosition: 'left',
      checkedRows: [],
      isOpen: 0,
      isSending: false,
    };
  },
  methods: {
    async saveChanges() {
      this.isSending = true;
      const loadingComponent = this.$buefy.loading.open({
        container: null,
      });
      let rArray = [];
      for (let i = 0; i < this.checkedRows.length; i++) {
        let rObj = {};
        if (typeof this.checkedRows[i] === 'object') {
          try {
            if (
              this.checkedRows[i].barcode === undefined ||
              this.checkedRows[i].barcode === null
            ) {
              throw new Error(
                "Checked row doesn't have a barcode. Please try again"
              );
            }

            rObj['supervisor'] = 'U0052';
            rObj['employee'] = this.$store.state.user.id;
            rObj['department'] = 'D00011';
            rObj['status'] = 'Complete';
            rObj['barcode'] = this.checkedRows[i].barcode;
            rObj['step'] = this.mapToNextStep(this.checkedRows[i].scans);
            rObj['stepBegin'] = new Date().toISOString();
            rObj['activityStart'] = new Date().toISOString();
            rObj['stepComplete'] = new Date().toISOString();
            rObj['activityStop'] = new Date().toISOString();
            rArray.push(rObj);

            await scannerService.addMultiScan({
              scanArray: rArray,
            });

            this.checkedRows = [];
            this.isSending = false;
            this.getData();
            loadingComponent.close();
          } catch (e) {
            console.log(e);
            Notification.open({
              duration: 3000,
              message:
                error.message ||
                error.response.data.error ||
                `Invalid credentials`,
              position: 'is-bottom',
              type: 'is-danger',
              hasIcon: true,
            });
          }
        }
      }
    },
    async saveApprovalChanges() {
      const loadingComponent = this.$buefy.loading.open({
        container: null,
      });
      const unitApprovalArray = [];
      for (let i = 0; i < this.myData.length; i++) {
        for (let j = 0; j < this.myData[i].sources.length; j++) {
          let rObj = {};
          if (typeof this.myData[i] === 'object') {
            try {
              rObj['barcode'] = this.myData[i].sources[j].barcode;
              rObj['approved'] = this.myData[i].sources[j].approved;
              unitApprovalArray.push(rObj);
            } catch (e) {
              console.log(e);
              if (e.name === 'TypeMapError') {
                console.log('Oops');
              }
            }
          }
        }
      }

      await sourceService.addSourceApprovals({
        unitApprovalArray: unitApprovalArray,
      });

      this.isSending = false;
      this.getData();
      loadingComponent.close();
    },
    //https://stackoverflow.com/questions/1433558/handling-specific-errors-in-javascript-think-exceptions
    mapToNextStep(scanArray) {
      if (typeof scanArray != 'object') {
        throw {
          name: 'TypeMapError',
          message: 'TypeMapError occurred!',
        };
      }

      let step;
      if (scanArray.length === 0) {
        step = null;
      } else {
        step = scanArray[0].step.name;
      }

      switch (true) {
        case step === 'Prelim':
          return 'Revision';
        case step === 'Revision':
          return 'Production';
        case step === 'Production':
          return new Error(
            'Cannot submit scans for steps outside of department scope'
          );
        case step === null:
          return 'Prelim';
        default:
          return new Error(
            'Cannot submit scans for steps outside of department scope'
          );
      }
    },
    getData() {
      projectService.getProjectByDepartment('D00011').then((data) => {
        this.myData = data;
        this.originalData = data;
      });
    },
    removeDuplicates(a, b) {
      for (var i = 0, len = a.length; i < len; i++) {
        for (var j = 0, len2 = b.length; j < len2; j++) {
          if (
            a[i].barcode === b[j].barcode &&
            a[i].approved === b[j].approved
          ) {
            b.splice(j, 1);
            len2 = b.length;
          }
        }
      }
      return b;
    },
  },
  async beforeCreate() {
    // console.log(this.$store.state);

    const responseData = await projectService.getProjectByDepartment('D00011');
    this.myData = responseData;
    this.originalData = responseData;
  },
};
</script>

<style>
.padding-top {
  padding-top: 4rem;
}

.panel-relative {
  position: relative;
}
.button-right {
  position: absolute;
  right: 15px;
  top: 10px;
}
</style>
