<template>
  <div class="columns">
    <div class="column pb-0">
      <div class="hero is-fullheight is-dark has-background">
        <img
          alt="Riviera Bronze Windows Image"
          class="hero-background is-transparent"
          src="@/assets/portal_pic.jpg"
        />
        <div class="columns mt-6">
          <div class="column is-top is-4 is-offset-half mt-6">
            <div class="box has-background-light severe-margin">
              <div class="columns">
                <div class="column is-6">
                  <img
                    src="@/assets/rb_transparent.png"
                    alt="Riviera Bronze Logo"
                    class=""
                  />
                </div>
                <div class="column is-2 is-offset-2">
                  <b-button
                    tag="router-link"
                    to="/reset-password"
                    type="is-info"
                  >
                    Reset Password
                  </b-button>
                  <b-button @click="logout()"> Logout </b-button>
                </div>
              </div>
              <div class="columns mt-4">
                <div class="column">
                  <p
                    class="is-size-3 has-text-weight-bold has-text-riviera-bronze"
                  >
                    Customer Portal
                  </p>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <p>Welcome, {{ name }}</p>
                  <p>What would you like to do?</p>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="View My Project(s)">
                    <ul>
                      <li
                        v-for="(project, index) in returnData.partner.projects"
                        :key="index"
                        class="is-size-5"
                      >
                        <router-link
                          :to="{ path: '/guest-project/' + project.id }"
                        >
                          {{ project.name }} X</router-link
                        >
                      </li>
                    </ul>
                  </b-field>
                  <div class="mt-5">
                    <b-field label="Ask a question">
                      <b-input type="textarea" v-model="userMessage"></b-input>
                    </b-field>
                    <b-button
                      :disabled="isSubmittingMessage"
                      :loading="isSubmittingMessage"
                      @click="submitMessage()"
                    >
                      Submit Question
                    </b-button>
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <a
                    href="./Riviera_Bronze_Warranty_2021.pdf"
                    class="has-text-twitter"
                    ><p>View our warranty policy</p></a
                  >
                  <a href="./RB_MAINTENANCE_GUIDE.pdf" class="has-text-twitter"
                    ><p>Finish care details</p></a
                  >
                  <b-notification
                    type="is-success"
                    has-icon
                    aria-close-label="Close notification"
                    @close="onSuccessMessageClose()"
                    :active.sync="successMessageActive"
                    role="alert"
                  >
                    {{ successMessage }}
                  </b-notification>
                  <b-notification
                    type="is-danger"
                    has-icon
                    aria-close-label="Close notification"
                    @close="onErrorMessageClose()"
                    :active.sync="errorMessageActive"
                    role="alert"
                  >
                    {{ errorMessage }}
                  </b-notification>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import store from '../store/index';
export default {
  data() {
    return {
      name: store.state.user.username,
      userId: store.state.user.id,
      returnData: null,
      userMessage: null,
      isSubmittingMessage: false,
      successMessageActive: false,
      errorMessageActive: false,
      successMessage: null,
      errorMessage: null,
    };
  },
  methods: {
    submitMessage() {
      this.isSubmittingMessage = true;
      axios
        .post('https://rb-portal-backend.herokuapp.com/message/', {
          content: this.userMessage,
          user: this.userId,
        })
        .then((res) => {
          console.log(res.data);
          this.successMessage =
            'Message delivered, Riviera Bronze will contact you within one (1) buisiness day.';
          this.successMessageActive = true;
          const newMessageId = res.data.id;
          axios.post(
            'https://rb-portal-backend.herokuapp.com/message/' +
              newMessageId +
              '/sendToRB',
            {
              messageId: newMessageId,
              content: this.userMessage,
            }
          );
          this.userMessage = null;
          this.isSubmittingMessage = false;
        });
    },
    onSuccessMessageClose() {
      this.successMessage = null;
      this.successMessageActive = false;
    },
    logout() {
      this.$store
        .dispatch('logout')
        .then(() => {
          this.$router.push({ path: '/' });
        })
        .catch((e) => {
          console.log(e);
          this.$router.push({ path: '/' });
        });
    },
  },
  mounted() {
    axios
      .get('https://rb-portal-backend.herokuapp.com/user/' + this.userId)
      .then((res) => {
        this.returnData = res.data;
      });
  },
};
</script>

<style>
.severe-margin {
  margin-top: 10rem;
}

.is-top {
  z-index: 20;
  min-height: 500px;
}
.hero.has-background {
  position: relative;
  overflow: hidden;
}
.hero-background {
  position: absolute;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}
.hero-background.is-transparent {
  opacity: 0.3;
}
</style>
