<template>
  <div>
    <section class="is-tall">
      <p class="has-text-centered is-size-1 p-2">Material Prep Scanner</p>
      <div class="container">
        <article class="panel panel-default is-info">
          <div class="panel-heading panel-relative">
            <p class="">Projects</p>
            <div class="buttons button-right">
              <button
                class="button is-success"
                @click="saveChanges()"
                :loading="isSending"
                :disabled="isSending"
              >
                <span class="icon">
                  <i class="far fa-save"></i>
                </span>
                <span>Save Changes </span>
              </button>
              <button
                class="button is-danger"
                @click="checkedRows = []"
                :loading="isSending"
                :disabled="isSending"
              >
                <span class="icon">
                  <i class="fas fa-undo"></i>
                </span>
                <span>Undo Selection</span>
              </button>
            </div>
          </div>
          <b-collapse
            class="card"
            animation="slide"
            v-for="(collapse, index) of myData"
            :key="index"
            :open="isOpen == index"
            @open="isOpen = index"
          >
            <template #trigger="props">
              <div class="card-header has-background-light" role="button">
                <p class="card-header-title">
                  {{ collapse.key }}
                </p>
                <a class="card-header-icon">
                  <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                  </b-icon>
                </a>
              </div>
            </template>
            <div class="card-content">
              <div class="content">
                <section>
                  <div class="container">
                    <b-table
                      :data="collapse.sources"
                      :checked-rows.sync="checkedRows"
                      :is-row-checkable="
                        (row) =>
                          row.scans.length === 0 ||
                          Number(row.scans[0].step.order) === 3.0 ||
                          Number(row.scans[0].step.order) === 4
                      "
                      checkable
                      striped
                      checkbox-position="left"
                    >
                      <b-table-column
                        field="barcode"
                        label="Barcode"
                        v-slot="props"
                      >
                        {{ props.row.barcode }}
                      </b-table-column>
                      <b-table-column
                        field="location"
                        label="Location"
                        v-slot="props"
                      >
                        {{ props.row.location }}
                      </b-table-column>
                      <b-table-column
                        field="description"
                        label="Description"
                        v-slot="props"
                      >
                        {{ props.row.description }}
                      </b-table-column>
                      <b-table-column field="type" label="Type" v-slot="props">
                        {{ props.row.type }}
                      </b-table-column>
                      <b-table-column
                        field="swing"
                        label="Swing"
                        v-slot="props"
                      >
                        {{ props.row.swing }}
                      </b-table-column>
                      <b-table-column
                        field="finish.unitType"
                        label="Finish"
                        v-slot="props"
                      >
                        {{ props.row.finish.unitType }}
                      </b-table-column>
                      <b-table-column
                        field="sqFt"
                        label="Square Feet"
                        v-slot="props"
                      >
                        {{ props.row.sqFt }}
                      </b-table-column>
                      <!-- <b-table-column field="approved" label="Approved" v-slot="props">
                                    <b-checkbox v-model="props.row.approved">
                                    </b-checkbox>
                                </b-table-column> -->
                      <b-table-column
                        field="scans"
                        label="Last Scan"
                        v-slot="props"
                      >
                        <b-tag
                          type="is-primary"
                          size="is-medium"
                          v-if="props.row.scans.length === 0"
                          >No Last Scan</b-tag
                        >
                        <b-tag
                          :type="
                            props.row.scans[0].step.name === 'Cut'
                              ? 'is-success'
                              : props.row.scans[0].step.name === 'Machine'
                              ? 'is-warning'
                              : 'is-info'
                          "
                          size="is-medium"
                          v-else-if="props.row.scans[0]"
                          >{{ props.row.scans[0].step.name }}</b-tag
                        >
                        <b-tag v-else>Error</b-tag>
                      </b-table-column>
                      <template #empty>
                        <div class="has-text-centered">No records</div>
                      </template>
                    </b-table>
                  </div>
                </section>
              </div>
            </div>
          </b-collapse>
        </article>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      myData: [],
      originalData: [],
      checkboxPosition: 'left',
      checkedRows: [],
      isOpen: 0,
      isSending: false,
    };
  },
  methods: {
    async saveChanges() {
      this.isSending = true;
      const loadingComponent = this.$buefy.loading.open({
        container: null,
      });
      const rArray = [];
      for (let i = 0; i < this.checkedRows.length; i++) {
        let rObj = {};
        if (typeof this.checkedRows[i] === 'object') {
          //explicit call to a name user. This isn't great but it'll do
          try {
            rObj['supervisor'] = 'U0120';
            rObj['employee'] = 'U0120';
            rObj['department'] = 'D00003';
            rObj['status'] = 'Complete';
            rObj['barcode'] = this.checkedRows[i].barcode;
            rObj['step'] = this.mapToNextStep(this.checkedRows[i].scans);
            rObj['stepBegin'] = new Date().toISOString();
            rObj['activityStart'] = new Date().toISOString();
            rObj['stepComplete'] = new Date().toISOString();
            rObj['activityStop'] = new Date().toISOString();
            console.log(rObj);
            rArray.push(rObj);
          } catch (e) {
            console.log(e);
            if (e.name === 'TypeMapError') {
              console.log('Oops');
            }
          }
        }
      }
      await axios.post(
        'https://rb-portal-backend.herokuapp.com/scanner/multiscan',
        {
          scanArray: rArray,
        }
      );

      this.isSending = false;
      this.getData();
      loadingComponent.close();
    },
    //https://stackoverflow.com/questions/1433558/handling-specific-errors-in-javascript-think-exceptions
    mapToNextStep(scanArray) {
      if (typeof scanArray != 'object') {
        throw {
          name: 'TypeMapError',
          message: 'TypeMapError occurred!',
        };
      }

      let step;
      if (scanArray.length === 0) {
        step = null;
      } else {
        step = scanArray[0].step.name;
      }

      switch (true) {
        case step === 'Production':
          return 'Cut';
        case step === 'Cut':
          return 'Machining';
        case step === 'Machining':
          return new Error(
            'Cannot submit scans for steps outside of department scope'
          );
        case step === null:
          return 'Cut';
        default:
          return new Error(
            'Cannot submit scans for steps outside of department scope'
          );
      }
    },
    getData() {
      axios
        .get(
          'https://rb-portal-backend.herokuapp.com/rb2project/projects-by-dept/D00003'
        )
        .then((res) => {
          this.myData = res.data;
          this.originalData = res.data;
        });
    },
  },
  beforeCreate() {
    console.log(this.$store.state);
    axios
      .get(
        'https://rb-portal-backend.herokuapp.com/rb2project/projects-by-dept/D00003'
      )
      .then((res) => {
        this.myData = res.data;
        this.originalData = res.data;
      });
  },
};
</script>

<style>
.padding-top {
  padding-top: 4rem;
}

.panel-relative {
  position: relative;
}
.button-right {
  position: absolute;
  right: 15px;
  top: 10px;
}
</style>
