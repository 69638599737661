<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">New project</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <b-field label="Name" horizontal>
          <b-input v-model="name"></b-input>
        </b-field>
        <b-field label="Date Due" horizontal>
          <b-datepicker
            v-model="dueDate"
            :show-week-number="true"
            placeholder="Select the due date"
            icon="calendar-today"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button class="is-primary is-medium" @click="createProject()"
          >Send</b-button
        >
      </footer>
    </div>
  </div>
</template>

<script>
import { projectService } from '../services/ProjectService';
export default {
  data() {
    return {
      user: this.userObj,
      isSending: false,
      name: null,
      address: null,
      sqFootage: null,
      dueDate: new Date(),
      signedForProd: null,
      notes: null,
    };
  },
  methods: {
    async createProject() {
      projectService
        .createProject({
          name: this.name,
          address: this.address,
          footage: this.sqFootage,
          due_date: this.dueDate,
          signed_for_production: this.signedForProd,
          notes: this.notes,
        })
        .then(() => {
          this.$emit('success-event');
          this.$emit('close');
        })
        .catch((e) => {
          this.$emit('failure-event', e);
        });
    },
  },
};
</script>

<style></style>
