<template>
  <div class="container">
    <section class="section">
      <button
        class="button is-success my-2 is-medium"
        @click="openAddProjectModal()"
      >
        Add New Project
      </button>
      <b-table
        :data="projects"
        :paginated="true"
        :per-page="20"
        current-page.sync="1"
        striped
        :bordered="true"
      >
        <b-table-column
          field="name"
          label="Name"
          v-slot="props"
          sortable
          searchable
        >
          <router-link
            :to="{ path: 'projects-table/project/' + props.row.key }"
            class="nav-link"
          >
            <p>{{ props.row.name }}</p></router-link
          >
        </b-table-column>
        <b-table-column
          field="dueDate"
          label="Due Date"
          v-slot="props"
          sortable
        >
          <p v-if="props.row.dueDate != null">{{ props.row.dueDate }}</p>
          <p v-else>No Date Known, Consult Elvis</p>
        </b-table-column>
        <b-table-column field="id" label="Edit" v-slot="props" :centered="true">
          <b-button
            class="is-warning"
            @click="openProjectUpdateModal(props.row)"
          >
            <span
              class="iconify"
              data-icon="mdi:circle-edit-outline"
              data-inline="false"
            ></span>
          </b-button>
        </b-table-column>
      </b-table>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import { projectService } from '../services/ProjectService';
import AddProjectModal from './AddProjectModal.vue';
import UpdateProjectModal from './UpdateProjectModal.vue';

export default {
  data() {
    return {
      projects: [],
      isLoading: false,
    };
  },
  methods: {
    openAddProjectModal() {
      this.$buefy.modal.open({
        parent: this,
        component: AddProjectModal,
        hasModalCard: true,
        trapFocus: true,
        width: 3000,
        props: {},
        events: {
          'success-event': () => {
            this.$buefy.notification.open({
              message: 'Succesfully added new project',
              type: 'is-success',
              position: 'is-bottom',
              duration: 2000,
            });
          },
          'failure-event': (error) => {
            this.$buefy.notification.open({
              message: error,
              type: 'is-danger',
              position: 'is-bottom',
              duration: 2000,
            });
          },
        },
      });
    },
    openProjectUpdateModal(projectObj) {
      this.$buefy.modal.open({
        parent: this,
        component: UpdateProjectModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          projectObj: projectObj,
        },
        events: {
          'success-event': async () => {
            console.log('sucess');
            this.$buefy.notification.open({
              message: 'Succesfully updated',
              type: 'is-success',
              position: 'is-bottom',
              duration: 2000,
            });
            this.isLoading = true;
            const returnData = await projectService.getAllProjects();

            this.projects = returnData;
            this.isLoading = false;
          },
          'failure-event': (error) => {
            this.$buefy.notification.open({
              message: error,
              type: 'is-danger',
              position: 'is-bottom',
              duration: 2000,
            });
          },
        },
      });
    },
    openProjectDeleteModal(projectObj) {
      this.$buefy.modal.open({
        parent: this,
        component: AddProjectModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          projectObj: projectObj,
        },
        events: {},
      });
    },
  },
  async beforeCreate() {
    this.isLoading = true;
    const returnData = await projectService.getAllProjects();
    this.projects = returnData;
    this.isLoading = false;
  },
};
</script>

<style></style>
