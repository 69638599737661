<template>
  <div class="column is-narrow pb-2">
    <section class="sidebar-layout">
      <b-sidebar
        position="static"
        :fullheight="true"
        type="is-grey"
        open
        :reduce="reduced"
      >
        <div class="p-1">
          <div class="block" @click="reduced = !reduced">
            <img src="@/assets/rb_transparent.png" alt="Riviera Bronze" />
          </div>
          <b-menu class="is-custom-mobile">
            <b-menu-list label="Main Menu">
              <b-menu-item
                label="Scan"
                tag="router-link"
                to="/cad-dashboard/cad-scanner"
              />
              <b-menu-item
                label="MP Scan"
                tag="router-link"
                to="/cad-dashboard/matprep-scanner"
              />
              <b-menu-item
                label="View All Projects"
                tag="router-link"
                to="/cad-dashboard/projects-table"
              />
              <b-menu-item
                label="Department Projects"
                tag="router-link"
                to="/cad-dashboard/project-department"
              />
            </b-menu-list>
            <b-menu-list label="Reports">
              <b-menu-item
                label="CAD Report"
                tag="router-link"
                to="/cad-dashboard/cad-report"
              />
            </b-menu-list>
            <b-menu-list label="Imports">
              <b-menu-item
                label="CAD Monthly Import"
                tag="router-link"
                to="/cad-dashboard/cad-import"
              />
            </b-menu-list>
            <b-menu-list label="Actions">
              <b-menu-item label="Logout" @click="logout()"></b-menu-item>
              <b-menu-item
                label="Change Password"
                tag="router-link"
                to="/cad-dashboard/password-reset"
              />
            </b-menu-list>
          </b-menu>
        </div>
      </b-sidebar>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      reduced: false,
    };
  },
  methods: {
    logout() {
      this.$store
        .dispatch('logout')
        .then(() => {
          this.$router.push({ path: '/employee-login' });
        })
        .catch((e) => {
          console.log(e);
          this.$router.push({ path: '/employee-login' });
        });
    },
  },
};
</script>

<style></style>
