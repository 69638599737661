<template>
  <div class="container">
    <button class="button is-success" @click="openAddPartnerModal()">
      Add New Partner
    </button>
    <b-table
      :data="partners"
      :paginated="true"
      :per-page="20"
      current-page.sync="1"
      striped
    >
      <b-table-column
        field="name"
        label="Name"
        v-slot="props"
        sortable
        searchable
        :bordered="true"
      >
        {{ props.row.name }}
      </b-table-column>
      <b-table-column field="address" label="Address" v-slot="props">
        {{ props.row.address }}
      </b-table-column>
      <b-table-column field="phone_number" label="Phone Number" v-slot="props">
        {{ props.row.phone_number }}
      </b-table-column>
      <b-table-column field="partner_type" label="Type" v-slot="props">
        <span v-if="props.row.partner_type">{{
          props.row.partner_type.name
        }}</span>
      </b-table-column>
      <b-table-column field="phone_number" label="Phone Number" v-slot="props">
        <router-link :to="{ path: '/partner/' + props.row.id }"
          >Partner Overview</router-link
        >
      </b-table-column>
      <b-table-column field="" label="Update" v-slot="props">
        <b-button class="is-primary" @click="openPartnerUpdateModal(props.row)">
          <span
            class="iconify"
            data-icon="mdi:circle-edit-outline"
            data-inline="false"
          ></span>
        </b-button>
      </b-table-column>
      <b-table-column field="" label="Delete" v-slot="props">
        <b-button class="is-danger" @click="openPartnerDeleteModal(props.row)">
          <span
            class="iconify"
            data-icon="mdi:delete"
            data-inline="false"
          ></span>
        </b-button>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import axios from 'axios';
import AddPartnerModal from './AddPartnerModal.vue';
import UpdatePartnerModal from './UpdatePartnerModal.vue';

export default {
  data() {
    return {
      partners: [],
      isLoading: false,
    };
  },
  methods: {
    openAddPartnerModal() {
      this.$buefy.modal.open({
        parent: this,
        component: AddPartnerModal,
        hasModalCard: true,
        trapFocus: true,
        props: {},
        events: {
          'success-event': () => {
            this.$buefy.notification.open({
              message: 'Succesfully added new partner',
              type: 'is-success',
              position: 'is-bottom',
              duration: 2000,
            });
          },
          'failure-event': (error) => {
            this.$buefy.notification.open({
              message: error,
              type: 'is-danger',
              position: 'is-bottom',
              duration: 2000,
            });
          },
        },
      });
    },
    openPartnerUpdateModal(partnerObj) {
      this.$buefy.modal.open({
        parent: this,
        component: UpdatePartnerModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          partnerObj: partnerObj,
        },
        events: {},
      });
    },
    openPartnerDeleteModal(partnerObj) {
      this.$buefy.modal.open({
        parent: this,
        component: AddPartnerModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          partnerObj: partnerObj,
        },
        events: {},
      });
    },
  },
  async beforeCreate() {
    this.isLoading = true;
    const returnData = await axios.get(
      'https://rb-portal-backend.herokuapp.com/partner'
    );
    this.partners = returnData.data;
    this.isLoading = false;
  },
};
</script>

<style></style>
