<template>
  <div>
    <div class="columns">
      <div class="column is-three-fifths py-0 my-0">
        <section>
          <div class="hero is-fullheight has-background is-dark">
            <img
              alt="Home with Riviera Bronze windows and doors"
              class="hero-background"
              src="../assets/Picture1.png"
            />
            <div class="hero-body">
              <div class="container"></div>
            </div>
          </div>
        </section>
      </div>
      <div class="column py-0 my-0">
        <div class="columns mt-6">
          <div class="column is-half">
            <img
              src="@/assets/rb_transparent.png"
              alt="Riviera Bronze Logo"
              class=""
            />
          </div>
        </div>
        <div class="columns mt-4">
          <div class="column is-two-thirds">
            <p class="is-size-1 has-text-weight-bold has-text-riviera-bronze">
              Portal
            </p>
          </div>
        </div>
        <div class="columns mt-4">
          <div class="column is-two-thirds">
            <div class="field">
              <label class="label">Email</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  placeholder="Enter Username"
                  v-model="username"
                />
              </div>
            </div>
            <div class="field">
              <label class="label">Password</label>
              <div class="control">
                <input
                  class="input"
                  type="password"
                  placeholder="Enter password"
                  v-model="password"
                  @keydown.enter="login()"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <button class="button mt-2" @click="login()">Login</button>
            <div class="mt-2">
              <router-link to="/reset-request">
                Forgot your password?
              </router-link>
            </div>

            <b-notification
              v-if="this.error != null"
              type="is-danger"
              aria-close-label="Close notification"
              class="mt-2"
              role="alert"
            >
              {{ error }}
            </b-notification>
          </div>
        </div>
        <div class="columns mt-4">
          <div class="column is-two-thirds">
            <p class="is-size-6 has-text-weight-light is-italic">
              Access to and use of these services are subject to the following
              terms and conditions
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      username: null,
      password: null,
      error: null,
    };
  },
  methods: {
    login() {
      this.$store
        .dispatch('rblogin', {
          name: this.username,
          password: this.password,
        })
        .then(() => {
          if (this.$store.state.user.role === 'ADMIN') {
            this.$router.push({ path: '/admin-dashboard/' });
          } else if (this.$store.state.user.role === 'CAD') {
            this.$router.push({ name: 'CADDashboard' });
          } else {
            this.$router.push({ name: 'Guest' });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
.hero.has-background {
  position: relative;
  overflow: hidden;
}
.hero-background {
  position: absolute;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}
.hero-background.is-transparent {
  opacity: 0.3;
}
</style>
