<template>
  <div>
    <div class="centerall">
      <div class="columns">
        <div class="column mt-4 fills-space">
          <div class="container p-4">
            <div class="box has-background-light no-shadow">
              <p class="is-size-3 has-text-centered">
                Enter your email to receive a reset link
              </p>
              <b-field label="Email">
                <b-input v-model="email" type="text"> </b-input>
              </b-field>
              <div class="has-text-centered mt-5">
                <b-button class="is-primary" @click="resetPassword()"
                  >Make Request</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: null,
    };
  },
};
</script>

<style scoped>
.fills-space {
  height: 80vh;
}
.box {
  box-shadow: 0 0 0 0 !important;
}
</style>
