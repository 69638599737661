<template>
  <div class="column is-narrow pb-0 pr-0">
    <section class="sidebar-layout">
      <b-sidebar
        position="static"
        :fullheight="true"
        type="is-grey"
        open
        :reduce="reduced"
        mobile="hide"
      >
        <div class="p-1">
          <b-menu class="px-2 pt-4">
            <!-- <b-menu-item
              label="Home"
              tag="router-link"
              to="/admin-dashboard/my-home"
            /> -->
            <b-menu-list label="Main Menu">
              <b-menu-item
                label="View All Projects"
                tag="router-link"
                to="/admin-dashboard/projects-table"
              />

              <!-- TODO: Remove entirely -->
              <!-- <b-menu-item
                label="Invitation Center"
                tag="router-link"
                to="/admin-dashboard/invitation-center"
              /> -->
              <b-menu-item
                label="Hold Center"
                tag="router-link"
                to="/admin-dashboard/hold-center"
              />
              <b-menu-item
                label="Dispatch"
                tag="router-link"
                to="/admin-dashboard/dispatch"
              />
              <b-menu-item
                label="Add new user"
                tag="router-link"
                to="/admin-dashboard/add-user"
              />
            </b-menu-list>
            <b-menu-list label="Reports">
              <b-menu-item
                label="Cycle Time By Product"
                tag="router-link"
                to="/admin-dashboard/cycle-time"
              />
              <b-menu-item
                label="Individual Performance"
                tag="router-link"
                to="/admin-dashboard/individual-performance"
              />
              <b-menu-item
                label="Tiger Team Sq. Footage"
                tag="router-link"
                to="/admin-dashboard/tiger-teams"
              />
              <b-menu-item
                label="WIP Profile"
                tag="router-link"
                to="/admin-dashboard/wip-report"
              />
              <b-menu-item
                label="Final Assembly FS"
                tag="router-link"
                to="/admin-dashboard/fa-constraint"
              />
              <b-menu-item
                label="Days Late Report"
                tag="router-link"
                to="/admin-dashboard/days-late-report"
              />
              <b-menu-item
                label="CAD Report"
                tag="router-link"
                to="/admin-dashboard/cad-report"
              />
              <b-menu-item
                label="Paint and Powdercoat Report"
                tag="router-link"
                to="/admin-dashboard/matson-report"
              />
              <b-menu-item
                label="Work Order"
                tag="router-link"
                to="/admin-dashboard/work-order"
              />
              <b-menu-item
                label="Glass Work Order"
                tag="router-link"
                to="/admin-dashboard/glass-work-order-table"
              />
            </b-menu-list>
            <b-menu-list label="Imports">
              <b-menu-item
                label="CAD Monthly Import"
                tag="router-link"
                to="/admin-dashboard/cad-import"
              />
            </b-menu-list>
          </b-menu>
        </div>
      </b-sidebar>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      reduced: false,
    };
  },
  methods: {},
};
</script>

<style></style>
