<template>
  <div class="is-tall columns is-desktop is-vcentered has-text-centered">
    <div class="column">
      <p class="is-size-1">404 Error. Resource not found.</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
