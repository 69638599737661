<template>
  <div>
    <section class="section">
      <div class="container">
        <p class="is-size-3 has-text-centered mb-4">CAD Project/Unit Imports</p>
        <div class="columns is-desktop is-vcentered is-centered">
          <div class="column has-background-light is-half box is-big">
            <section>
              <b-field label="Select A CAD Engineer">
                <b-select placeholder="Select a name" v-model="employee">
                  <option value="U0034">GAVINO-AURORA</option>
                  <option value="U0038">GONZALEZ-MARK</option>
                  <option value="U0102">WARD-BEN</option>
                  <option value="U0120">NAVARRO-RAMON</option>
                  <option value="U0052">LOPEZ-CESAR</option>
                  <option value="U0103">LINDSEY-JACOB</option>
                  <option value="U0014">MENDEZ-FERNANDO</option>
                </b-select>
              </b-field>
              <b-field>
                <b-upload v-model="file" drag-drop expanded>
                  <section class="section">
                    <div class="content has-text-centered">
                      <p>
                        <b-icon icon="upload" size="is-large"></b-icon>
                      </p>
                      <p>Drop your files here or click to upload</p>
                    </div>
                  </section>
                </b-upload>
              </b-field>

              <div class="tags">
                <span v-if="file != null" class="tag is-primary">
                  {{ file.name }}
                  <button
                    class="delete is-small"
                    type="button"
                    @click="deleteFile()"
                  ></button>
                </span>
              </div>
              <div class="has-text-centered">
                <b-button class="is-primary" rounded @click="sendToServer()"
                  >Upload</b-button
                >
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      file: null,
      employee: null,
    };
  },
  methods: {
    deleteFile() {
      this.file = null;
    },
    sendToServer() {
      this.isLoading = true;
      let formData = new FormData();
      formData.set('file', this.file);
      formData.set('employee', this.employee);

      if (this.employee === null || this.file === null) {
        this.$buefy.notification.open({
          message: `You are missing required information. Please select a CAD engineer and upload a file to continue`,
          position: 'is-bottom',
          type: 'is-danger',
          hasIcon: true,
        });
        return;
      } else {
        axios({
          method: 'post',
          url: 'https://rb-portal-backend.herokuapp.com/scanner/cad-import',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then((res) => {
            console.log(res);
            this.$buefy.notification.open({
              message: `Import successful`,
              position: 'is-bottom',
              type: 'is-success',
              hasIcon: true,
            });
          })
          .catch((err) => {
            console.log(err);
            this.$buefy.notification.open({
              message: `${err}`,
              position: 'is-bottom',
              type: 'is-danger',
              hasIcon: true,
            });
          });
      }
      this.isLoading = false;
    },
  },
  beforeCreate() {
    //   axios.get('https://rb-portal-backend.herokuapp.com/R')
  },
};
</script>

<style lang="scss" scoped></style>
