<template>
  <div>
    <div class="centerall">
      <div class="columns">
        <div class="column fills-space">
          <div class="container p-4">
            <div class="box">
              <b-field label="Old Password">
                <b-input
                  v-model="oldPassword"
                  type="password"
                  password-reveal
                  required
                >
                </b-input>
              </b-field>
              <b-field label="New Password">
                <b-input
                  v-model="newPassword"
                  type="password"
                  password-reveal
                  required
                >
                </b-input>
              </b-field>
              <div class="has-text-centered mt-5">
                <b-button class="is-primary" @click="resetPassword()"
                  >Change Password</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      oldPassword: null,
      newPassword: null,
    };
  },
  methods: {
    logout() {
      this.$store
        .dispatch('logout')
        .then(() => {
          this.$router.push({ path: '/employee-login' });
        })
        .catch((e) => {
          console.log(e);
          this.$router.push({ path: '/' });
        });
    },
    resetPassword() {
      if (this.oldPassword === this.newPassword) {
        throw Error('Password cannot be the same as the old password');
      }
      axios
        .post('https://rb-portal-backend.herokuapp.com/auth/change-password', {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        })
        .then(() => {
          this.logout();
          this.$buefy.notification.open({
            message: `Password Change Successful`,
            position: 'is-bottom',
            type: 'is-success',
            hasIcon: true,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style>
.fills-space {
  height: 80vh;
}
</style>
