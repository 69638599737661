<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add new partner</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <b-field label="Name" horizontal>
          <b-input v-model="name"></b-input>
        </b-field>
        <b-field label="Address" horizontal>
          <b-input v-model="address"></b-input>
        </b-field>
        <b-field label="Phone" horizontal>
          <b-input v-model="phone_number"></b-input>
        </b-field>
        <b-field label="Type" horizontal>
          <b-select
            placeholder="Select a partner type"
            expanded
            required
            v-model="type_id"
          >
            <option
              v-for="option in partnerTypes"
              :value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </b-select>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button class="is-primary" @click="createPartner()">Send</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      user: this.userObj,
      isSending: false,
      name: null,
      address: null,
      phone_number: null,
      type_id: null,
      partnerTypes: [],
    };
  },
  props: ['userObj'],
  methods: {
    async createPartner() {
      axios
        .post('https://rb-portal-backend.herokuapp.com/partner/', {
          name: this.name,
          address: this.address,
          phone_number: this.phone_number,
          partner_type: this.type_id,
        })
        .then(() => {
          this.$emit('success-event');
          this.$emit('close');
        })
        .catch((e) => {
          this.$emit('failure-event', e);
        });
    },
  },
  beforeMount() {
    axios
      .get('https://rb-portal-backend.herokuapp.com/partnertype/')
      .then((res) => {
        this.partnerTypes = res.data;
      });
  },
};
</script>
