<template>
  <div>
    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column">
            <b-table
              :data="holder"
              current-page.sync="1"
              striped
              :bordered="true"
              :loading="isLoading"
            >
              <b-table-column
                field="Barcode"
                label="Barcode"
                v-slot="props"
                sortable
                searchable
              >
                {{ props.row.Barcode }}
              </b-table-column>
              <b-table-column
                field="Step"
                label="Last Step"
                v-slot="props"
                sortable
                searchable
              >
                {{ props.row.Step }}
              </b-table-column>
              <b-table-column
                field="Project"
                label="Project"
                v-slot="props"
                sortable
                searchable
              >
                {{ props.row.Project }}
              </b-table-column>
              <b-table-column
                field="Due_Date"
                label="Due Date"
                v-slot="props"
                sortable
                searchable
              >
                {{ props.row.Due_Date.split('T')[0] }}
              </b-table-column>
              <b-table-column
                label="Days Late"
                field="daysLate"
                v-slot="props"
                sortable
              >
                {{ Number(props.row.daysLate) }}
              </b-table-column>
              <b-table-column
                label="Static Days"
                field="staticDays"
                v-slot="props"
                sortable
              >
                {{ props.row.staticDays }}
              </b-table-column>
              <!-- <b-table-column label="Static Days Late">
                        N/A
                    </b-table-column>         -->
              <b-table-column label="Area" v-slot="props">
                {{ props.row.Department }}
              </b-table-column>
            </b-table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import { scannerService } from '../services/ScannerService';
export default {
  data() {
    return {
      holder: [],
      isLoading: false,
    };
  },
  methods: {
    getDateDiff(date2) {
      const newDate1 = new Date();
      const newDate2 = new Date(date2);
      const Difference_In_Time = newDate1.getTime() - newDate2.getTime();
      const Difference_In_Days = (
        Difference_In_Time /
        (1000 * 3600 * 24)
      ).toFixed(0);
      return Number(Difference_In_Days);
    },
  },
  async beforeCreate() {
    this.isLoading = true;
    this.holder = await scannerService.getLateUnits();
    this.isLoading = false;
  },
};
</script>

<style></style>
