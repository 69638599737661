import { _axios } from '../plugins/axios';

class WorkOrderService {
  constructor() {
    this.axios = _axios;
  }

  async getWorkOrders() {
    try {
      const response = await this.axios.get('/work-order');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async getWorkOrderTypes() {
    try {
      const response = await this.axios.get('/work-order-type');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async addWorkOrder(payload) {
    try {
      const response = await this.axios.post('/work-order', payload);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
  async filterWorkOrders(limit, type, startDate, endDate) {
    try {
      const response = await this.axios.get(
        `/work-order?limit=${limit}&type=${type}&submitted_start=${new Date(
          startDate
        )
          .toISOString()
          .replace('T', ' ')
          .slice(0, 19)}&submitted_end=${new Date(endDate)
          .toISOString()
          .replace('T', ' ')
          .slice(0, 19)}`
      );

      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
  async filterWorkOrdersResolve(limit, type, startDate, endDate) {
    try {
      const response = await this.axios.get(
        `/work-order?limit=${limit}&type=${type}&resolve_start=${new Date(
          startDate
        )
          .toISOString()
          .replace('T', ' ')
          .slice(0, 19)}&resolve_end=${new Date(endDate)
          .toISOString()
          .replace('T', ' ')
          .slice(0, 19)}`
      );

      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
  async getWorkOrders() {
    try {
      const response = await this.axios.get('/work-order');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
  async getWorkOrderByType(limit, type) {
    try {
      const response = await this.axios.get(
        `/work-order?limit=${limit}&type=${type}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
}

export const workOrderService = new WorkOrderService();
