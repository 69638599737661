/* eslint-disable */

import Vue from 'vue';
import store from '../store/index';
import VueRouter from 'vue-router';

import Home from '@/views/Home';
// Admin Views
import Admin from '@/views/admin/Admin';
import AdminDashboard from '@/views/admin/AdminDashboard';
import AddUser from '@/views/admin/AddUser';

import NotFound from '@/components/NotFound';
import PasswordReset from '@/components/PasswordReset';
import RBPasswordReset from '@/components/RBPasswordReset';
import ResetRequest from '@/views/ResetRequest';

import Guest from '@/views/Guest';
import Project from '@/views/Project';
import Partner from '@/views/Partner.vue';
import ProjectTable from '../components/ProjectTable.vue';
import MessageCenter from '../components/MessageCenter.vue';
import PartnersTable from '../components/PartnersTable.vue';
import UsersTable from '../components/UsersTable.vue';
import IndividualFootage from '../components/IndividualFootage';
import FabricationFootage from '../components/FabricationFootage';
import Hold from '../components/Hold';
import WIPReport from '../components/WIPReport';
import CycleTimeByProduct from '../components/CycleTimeByProduct';
import FAConstraint from '../components/FAConstraint';
import DaysLateReport from '../components/DaysLateReport';
import DeliveryPerformance from '../components/DeliveryPerformance';
import Dispatch from '../components/Dispatch';
import FabricationScanner from '../components/FabricationScanner';
import MatsonReport from '../components/MatsonReport';
import EmployeeLogin from '../views/EmployeeLogin';

import ProjectDepartment from '../components/ProjectDepartment';
import MaterialPrepScanner from '../components/MaterialPrepScanner';
import UnitView from '../components/UnitView';
import WorkOrders from '../views/WorkOrders';
import HomeDashboard from '../components/HomeDashboard';
import GlassWorkOrderForm from '../components/GlassOrderForm';
import GlassOrderTable from '../components/GlassOrderTable';
import GlassOrderDetail from '../components/GlassOrderDetail';

// const CADDashboard = () =>
//   import(/* webpackChunkName: "group-cad" */ '@/views/CADDashboard');
// const CADScanner = () =>
//   import(/* webpackChunkName: "group-cad" */ '@/components/CADScanner');
// const CADFootage = () =>
//   import(/* webpackChunkName: "group-cad" */ '@/components/CADFootage');
// const ImportFromCAD = () =>
//   import(/* webpackChunkName: "group-cad" */ '@/components/ImportFromCAD');

import ImportFromCAD from '../components/ImportFromCAD';
import CADFootage from '../components/CADFootage';
import CADDashboard from '../views/CADDashboard';
import CADScanner from '../components/CADScanner';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/employee-login',
    name: 'EmployeeLogin',
    component: EmployeeLogin,
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: '/service',
    name: 'Guest',
    component: Guest,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin-dashboard',
    name: 'AdminDashboard',
    component: AdminDashboard,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      title: 'Admin Dashboard',
    },
    children: [
      {
        path: 'my-home',
        component: HomeDashboard,
        meta: {
          title: 'Admin Home',
        },
      },
      {
        path: 'add-user',
        component: AddUser,
        meta: {
          title: 'Add new user',
        },
      },
      {
        path: 'glass-work-order',
        name: 'GlassOrderForm',
        component: GlassWorkOrderForm,
        meta: {
          title: 'Glass Work Order',
        },
      },
      {
        path: 'glass-work-order-table',
        name: 'GlassOrderTable',
        component: GlassOrderTable,
        meta: {
          title: 'Admin Home',
        },
      },
      {
        path: 'glass-work-order/:id',
        name: 'GlassOrderDetail',
        component: GlassOrderDetail,
        meta: {
          title: 'Glass Order Details',
        },
      },
      {
        path: 'work-order',
        component: WorkOrders,
        meta: {
          title: 'Work Order',
        },
      },
      {
        path: 'projects-table',
        component: ProjectTable,
        meta: {
          title: 'Projects Table',
        },
      },
      {
        path: 'projects-table/project/:key',
        name: 'admin-project',
        component: Project,
        props: true,
        meta: {
          requiresAuth: true,
          title: 'Projects',
        },
      },
      {
        path: 'projects-table/project/:key/:barcode',
        name: 'UnitView',
        component: UnitView,
        props: {
          key: 'Alma Real',
          barcode: 'ALMAREAL-DC1A',
        },
        meta: {
          requiresAuth: true,
          title: 'X',
        },
      },
      // TODO: Remove this entirely or redesign later, not working
      // {
      //   path: "invitation-center",
      //   component: InvitationCenter,
      //   meta: {
      //     requiresAuth: true,
      //     title: "Invitation Center",
      //   },
      // },
      {
        path: 'message-center',
        component: MessageCenter,
        meta: {
          requiresAuth: true,
          title: 'Admin Message Center',
        },
      },
      {
        path: 'partners-table',
        component: PartnersTable,
        meta: {
          requiresAuth: true,
          title: 'Partners',
        },
      },
      {
        path: 'users-table',
        component: UsersTable,
        meta: {
          requiresAuth: true,
          title: 'Users',
        },
      },
      {
        path: 'password-reset',
        component: PasswordReset,
        meta: {
          requiresAuth: true,
          title: 'Password Reset',
        },
      },
      {
        path: 'individual-performance',
        component: IndividualFootage,
        meta: {
          requiresAuth: true,
          title: 'Individual Progress',
        },
      },
      {
        path: 'steps',
        component: FabricationScanner,
        meta: {
          requiresAuth: true,
          title: 'Steps Progress',
        },
      },
      {
        path: 'tiger-teams',
        component: FabricationFootage,
        meta: {
          requiresAuth: true,
          title: 'Tiger Team Progress',
        },
      },
      {
        path: 'cad-report',
        component: CADFootage,
        meta: {
          requiresAuth: true,
          title: 'CAD Progress',
        },
      },
      {
        path: 'hold-center',
        component: Hold,
        meta: {
          requiresAuth: true,
          title: 'Hold Center',
        },
      },
      {
        path: 'wip-report',
        component: WIPReport,
        meta: {
          requiresAuth: true,
          title: 'WIP Report',
        },
      },
      {
        path: 'cycle-time',
        component: CycleTimeByProduct,
        meta: {
          requiresAuth: true,
          title: 'Cycle Time',
        },
      },
      {
        path: 'fa-constraint',
        component: FAConstraint,
        meta: {
          requiresAuth: true,
          title: 'FA Constraint',
        },
      },
      {
        path: 'days-late-report',
        component: DaysLateReport,
        meta: {
          requiresAuth: true,
          title: 'Days Late Report',
        },
      },
      {
        path: 'delivery-performance',
        component: DeliveryPerformance,
        meta: {
          requiresAuth: true,
          title: 'Delivery Performance',
        },
      },
      {
        path: 'dispatch',
        component: Dispatch,
        meta: {
          requiresAuth: true,
          title: 'Dispatch Toolkit',
        },
      },
      {
        path: 'cad-import',
        component: ImportFromCAD,
        meta: {
          requiresAuth: true,
          title: 'CAD Import',
        },
      },
      {
        path: 'matson-report',
        component: MatsonReport,
        meta: {
          requiresAuth: true,
          title: 'Matson Report',
        },
      },
      {
        path: '',
        component: ProjectTable,
      },
    ],
  },
  {
    path: '/project/:key',
    name: 'project',
    component: Project,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/partner/:id',
    name: 'partner',
    component: Partner,
    props: true,
    meta: { requiresAdmin: true },
  },
  {
    path: '/reset-password',
    name: 'PasswordReset',
    component: PasswordReset,
    meta: {},
  },
  {
    path: '/reset-request',
    name: 'ResetRequest',
    component: ResetRequest,
    meta: {},
  },
  {
    path: '/cad-dashboard',
    name: 'CADDashboard',
    component: CADDashboard,
    meta: {
      title: 'CAD Dashboard',
    },
    children: [
      {
        path: 'projects-table',
        component: ProjectTable,
        meta: {
          title: 'Projects Table',
        },
      },
      {
        path: 'projects-table/project/:key',
        name: 'cad-project',
        component: Project,
        props: true,
        meta: {
          requiresAuth: true,
          title: 'Projects',
        },
      },
      {
        path: 'password-reset',
        name: 'password-reset',
        component: RBPasswordReset,
        props: true,
        meta: {
          requiresAuth: true,
          title: 'Reset Request',
        },
      },
      {
        path: 'cad-report',
        component: CADFootage,
        meta: {
          requiresAuth: true,
          title: 'CAD Progress',
        },
      },
      {
        path: 'cad-import',
        component: ImportFromCAD,
        meta: {
          requiresAuth: true,
          title: 'CAD Import',
        },
      },
      {
        path: 'project-department',
        component: ProjectDepartment,
        meta: {
          requiresAuth: true,
          title: 'Project/Department Assignment',
        },
      },
      {
        path: 'cad-scanner',
        component: CADScanner,
        meta: {
          requiresAuth: true,
          title: 'CAD Scanner',
        },
      },
      {
        path: 'matprep-scanner',
        component: MaterialPrepScanner,
        meta: {
          requiresAuth: true,
          title: 'Material Prep Scanner',
        },
      },
      {
        path: '',
        component: CADScanner,
      },
    ],
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'is-active',
  routes,
});

// router.afterEach(() => {
//   document.querySelectorAll('.dialog.modal').forEach((modal) => {
//     console.log(modal)
//     // modal.__vue__?.$vnode?.context?.close();
//   });
// });

router.beforeEach((to, from, next) => {
  // if (to.fullPath === from.fullPath) next(false);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({
        path: '/',
      });
    } else {
      next();
    }
  }

  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    // this route requires auth, check if logged in
    // if not, redirect to home page.
    if (!store.getters.isAdmin) {
      next({
        path: '/',
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

const DEFAULT_TITLE = 'Riviera Bronze Portal';
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
