<template>
  <div class="container">
    <div class="columns">
      <div class="column has-background-light is-tall">
        <section class="section">
          <p class="title">{{ partner.name }}</p>
          <p v-if="partner.address" class="title">
            Name: {{ partner.address }}
          </p>
          <p class="title">Address: {{ partner.phone_number }}</p>
          <p class="title">Type: {{ partner.partner_type.name }}</p>
        </section>
        <section class="section">
          <p class="title">Projects</p>
          <ul>
            <li
              v-for="project in partner.projects"
              :key="project.id"
              class="subtitle"
            >
              {{ project.name }}
            </li>
            <li
              class="subtitle has-pointer"
              @click="isEditingProjects = !isEditingProjects"
            >
              <span
                class="iconify"
                data-icon="mdi:plus"
                data-inline="false"
              ></span
              >Add New
            </li>
          </ul>
          <div v-if="isEditingProjects">
            <b-field label="Add a new project">
              <b-taginput
                v-model="partnerProjects"
                :data="filteredProjects"
                autocomplete
                :allow-new="false"
                :open-on-focus="true"
                field="name"
                icon="label"
                placeholder="Add a project"
                @typing="getFilteredProjects"
              >
              </b-taginput>
              <p class="control">
                <b-button class="button is-primary" @click="editPartnerProjects"
                  >Adjust Projects</b-button
                >
              </p>
            </b-field>
          </div>
        </section>
        <section class="section">
          <p class="title">Users</p>
          <ul>
            <li v-for="user in partner.users" :key="user.id" class="subtitle">
              {{ user.name }}
            </li>
          </ul>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      partner: null,
      partnerProjects: null,
      isEditingProjects: false,
      projects: [],
      filteredProjects: [],
    };
  },
  methods: {
    editPartnerProjects() {
      axios
        .patch(
          'https://rb-portal-backend.herokuapp.com/partner/' +
            this.$route.params.id +
            '/projects',
          {
            projects: this.partnerProjects,
          }
        )
        .then((res) => {
          console.log(res);
          this.fetchPartnerData();
        });
    },
    getFilteredProjects(text) {
      this.filteredProjects = this.projects.filter((project) => {
        return (
          project.name.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0
        );
      });
    },
    fetchPartnerData() {
      axios
        .get(
          'https://rb-portal-backend.herokuapp.com/partner/' +
            this.$route.params.id
        )
        .then((res) => {
          this.partner = res.data;
          this.partnerProjects = res.data.projects;
        });
    },
  },
  beforeCreate() {
    axios
      .get(
        'https://rb-portal-backend.herokuapp.com/partner/' +
          this.$route.params.id
      )
      .then((res) => {
        this.partner = res.data;
        this.partnerProjects = res.data.projects;
      });
    axios
      .get('https://rb-portal-backend.herokuapp.com/project/')
      .then((res) => {
        this.projects = res.data;
      });
  },
};
</script>

<style>
.has-pointer {
  cursor: pointer;
}

.has-pointer:hover {
  color: #fca91e;
}
</style>
