<template>
  <div
    class="unit-container"
    :class="
      isHeld
        ? 'has-background-warning'
        : isQC
        ? 'has-background-greyish has-text-weight-bold'
        : ''
    "
  >
    <div class="unit-body">
      <p :class="isBifoldSlider ? 'is-underlined has-text-danger' : ''">
        {{ item.barcode }}
      </p>
      <!-- <b-button size="is-small"  class="is-danger front-element-two" icon-left="delete" @click="openDialog" @removed="loggg()"></b-button> -->
      <b-button
        size="is-small"
        class="is-info front-element"
        icon-left="arrow-right-bold"
        @click="openDialog"
        @removed="loggg()"
      ></b-button>
    </div>
  </div>
</template>
<script>
import ConfirmMoveModal from '../components/modals/ConfirmMoveModal.vue';

export default {
  name: 'Unit',
  props: {
    item: Object,
    supervisorIndex: Number,
  },
  methods: {
    async openDialog() {
      this.$buefy.modal.open({
        parent: this,
        component: ConfirmMoveModal,
        hasModalCard: true,
        events: {
          confirm: async () => {
            this.$emit(
              'secondConfirm',
              this.item.barcode,
              this.supervisorIndex
            );
          },
          decline: async () => {},
        },
      });
    },
  },
  computed: {
    isQC: function () {
      if (this.item.type === 'W' && this.item.swing === 'F') {
        return this.item.scans.some(function callbackFn1(element) {
          if (element.step.name === 'Jamb Straight') {
            return true;
          }
        });
      } else {
        return this.item.scans.some(function callbackFn(element) {
          if (element.step.order > 11.0) {
            return true;
          }
        });
      }
    },
    isBifoldSlider: function () {
      if (this.item.swing === 'B' || this.item.swing === 'S') {
        return true;
      } else {
        return false;
      }
    },
    isHeld: function () {
      if (this.item.code) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
.unit-container {
  background: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
  cursor: pointer;
  margin-bottom: 2rem;
  position: relative;
}

.unit-body {
  position: relative;
  padding: 2rem 2rem;
}

p {
  font-size: 0.8rem;
}

.front-element {
  position: absolute !important;
  top: 3px !important;
  right: 3px !important;
}

.front-element-two {
  position: absolute !important;
  top: 3px !important;
  right: 30px !important;
}

.button {
  padding: 0.7em 0.7em;
  /* background-color: #E43D5F; */
}
</style>
