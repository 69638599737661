<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered">
          Add a unit to project: {{ projectId }}
        </p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div class="columns is-centered">
          <div class="column is-8">
            <b-field label="Barcode">
              <b-input
                v-model="barcode"
                placeholder="Internal barcode"
              ></b-input>
            </b-field>
            <b-field label="Architect Unit #">
              <b-input
                v-model="unit"
                placeholder="Enter the client's unit name"
              ></b-input>
            </b-field>
            <b-field label="Location">
              <b-input v-model="location" placeholder="Location"></b-input>
            </b-field>
            <b-field label="Description">
              <b-input v-model="description" placeholder="Location"></b-input>
            </b-field>
            <b-field label="Type">
              <b-select placeholder="Unit type" v-model="unitType">
                <option
                  v-for="option in typeList"
                  :value="option"
                  :key="option"
                >
                  {{ option }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Swing">
              <b-select placeholder="Swing Type" v-model="swing">
                <option
                  v-for="(option, index) in swingList"
                  :value="option.value"
                  :key="index"
                >
                  {{ option.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Material and Finish">
              <b-select placeholder="Swing Type" v-model="finish">
                <option
                  v-for="(option, index) in finishList"
                  :value="option.value"
                  :key="index"
                >
                  {{ option.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Sq. Ft">
              <b-numberinput min="0" v-model="sqFt" controls-position="compact">
              </b-numberinput>
            </b-field>
            <b-field label="Unit Cost">
              <b-numberinput min="0" v-model="cost" controls-position="compact">
              </b-numberinput>
            </b-field>
            <b-field label="Operable">
              <b-checkbox v-model="operability"> </b-checkbox>
            </b-field>
            <b-field label="Approved For Production">
              <b-checkbox v-model="approved"> </b-checkbox>
            </b-field>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button class="is-primary" @click="createUnit()">Send</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { sourceService } from '../../services/SourceService';
export default {
  data() {
    return {
      projectId: this.initProjectId,
      barcode: null,
      unit: null,
      location: null,
      description: null,
      sqFt: 0,
      cost: 0,
      operability: false,
      approved: false,
      finish: null,
      swing: null,
      unitType: null,
      finishList: [
        {
          name: 'Steel Metalized/ Galv',
          value: 1,
        },
        {
          name: 'Steel Powdercoat/Paint',
          value: 2,
        },
        {
          name: 'Thermal Metalized',
          value: 3,
        },
        {
          name: 'Thermal Powdercoat/Paint',
          value: 4,
        },
      ],
      typeList: ['D', 'D2', 'W'],
      swingList: [
        {
          name: 'Fixed',
          value: 'F',
        },
        {
          name: 'Inswing',
          value: 'I',
        },
        {
          name: 'Outswing',
          value: 'O',
        },
        {
          name: 'Slider',
          value: 'S',
        },
        {
          name: 'Bifold',
          value: 'B',
        },
        {
          name: 'Pivot',
          value: 'P',
        },
      ],
    };
  },
  props: ['initProjectId'],
  methods: {
    async createUnit() {
      console.log('creating unit...');
      await sourceService
        .addSourceUnit({
          barcode: this.barcode,
          unit: this.unit,
          location: this.location,
          description: this.description,
          sqFt: this.sqFt,
          cost: this.cost,
          operability: this.operability,
          approved: this.approved,
          finish: this.finish,
          type: this.unitType,
          swing: this.swing,
          project: this.projectId,
        })
        .then((data) => {
          this.$emit('close');
          this.$emit('success');
        })
        .catch((e) => {
          this.$emit('failure', e);
        });
    },
  },
};
</script>

<style></style>
