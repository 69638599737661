<template>
  <div>
    <section class="section">
      <div class="columns">
        <div class="column">
          <div class="container">
            <work-order-table />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import WorkOrderTable from '../components/WorkOrderTable.vue';
export default {
  components: {
    WorkOrderTable,
  },
  methods: {},
};
</script>

<style></style>
