import { _axios } from '../plugins/axios';
class AuthService {
  constructor() {
    this.axios = _axios;
  }

  async register(payload) {
    return await this.axios.post(`/user`, payload).then((res) => res.data);
  }

  async login(credentials) {
    try {
      const response = await this.axios.post(`/auth/login`, credentials);
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async rblogin(credentials) {
    try {
      const response = await this.axios.post(`/rbauth/login`, credentials);
      return response;
    } catch (error) {
      return error.response;
    }
  }
  async deactivate(user_id) {
    return await this.axios
      .post(`/user/${user_id}/deactive`, {})
      .then((res) => res.data);
  }
}

const authService = new AuthService();

export { authService };
