import { _axios } from '../plugins/axios';
class ScannerService {
  constructor() {
    this.axios = _axios;
  }

  async addNewScanner(payload) {
    try {
      const response = await this.axios.post(`/scanner/new`, payload);
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async addMultiScan(payload) {
    try {
      const response = await this.axios.post(`/scanner/multiscan`, payload);
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async filterEmployerReport(employees, startDate, endDate) {
    try {
      const response = await this.axios.get(
        `/scanner/employee-report?employee=${employees}&start_date=${startDate}&end_date=${endDate}`
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async filterCycleTime(unitType, startDate, endDate) {
    const response = await this.axios.get(
      `/scanner/cycle-time?unitType=${unitType}&start_date=${startDate}&end_date=${endDate}`
    );
    return response.data;
  }

  async getSupervisorScans(supervisor, startDate, endDate) {
    const response = await this.axios.get(
      `/scanner/sqft-report?supervisor=${supervisor}&start_date=${startDate}&end_date=${endDate}`
    );
    return response.data;
  }

  async getFactorySummary(startMonth) {
    const response = await this.axios.get(
      `/scanner/factory-summary?start_month=${startMonth}`
    );
    return response.data;
  }

  async getLateUnits() {
    const response = await this.axios.get(`/scanner/late-units`);
    return response.data;
  }
}

export const scannerService = new ScannerService();
